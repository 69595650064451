import React, {Component} from "react";
import SvgFile from "../icons/SvgFile";
import SvgClose from "../icons/SvgClose";

class DropboxFile extends Component {

    deleteFile = (e) => {
        e.stopPropagation();
        this.props.deleteFile(this.props.file);
    }

    render() {
        return (
            <div className={`uploaded-file base-black-70 ${this.props.className}`}
                 onClick={this.deleteFile}
                 style={{maxWidth: 32}}>
                <SvgFile style={{width: 32, height: 32}}/>
                <p className=' mb-0 body4 ellipsis' style={{marginTop: -6}}>{this.props.file.name}</p>
                <div className='absolute base-black-100 body4-bold cover-parent col-ac-jc'>
                    <SvgClose/>
                </div>
            </div>
        )
    }
}

export default DropboxFile
