import React, {Component} from "react";
import {FAQS} from "../utils/dummy";
import FAQItem from "./FAQItem";

class FAQSection extends Component {

    render() {
        return (
            <div className='page-h-padding '>
                <h1 className={`heading1 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                >
                    FAQ
                </h1>
                <div className='pv-40'>
                    {FAQS.map((item, index) =>
                        <FAQItem item={item} index={index} darkBody={this.props.darkBody} mobile={this.props.mobile} tablet={this.props.tablet}/>
                    )}
                </div>
            </div>
        )
    }
}

export default FAQSection
