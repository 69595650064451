import React, {Component} from "react";
import CapabilitySectionTopics from "./CapabilitySectionTopics";
import CollapseComponent from "./CollapseComponent";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import SvgClose from "../icons/SvgClose";

class CapabilitySectionBlock extends Component {
    state = {
        hovering: false,
    }
    onMouseEnter = () => {
        this.props.selectIndex(this.props.i)
        this.setState({hovering: true})
    }

    onMouseLeave = () => {
        this.props.deselectIndex()
        this.setState({hovering: false})
    }

    render() {
        return (
            <div onMouseEnter={this.onMouseEnter}
                 onMouseLeave={this.onMouseLeave}
                 style={{
                     flex: 1,
                     borderTop: `1px solid #${this.props.darkBody ? 'ffffff' : '000000'}20`,
                     // borderBottom: this.props.i === this.props.length - 2 && '1px solid black',
                     borderRight: this.props.i % 2 === 0 && `1px solid #${this.props.darkBody ? 'ffffff' : '000000'}20`,
                     boxSizing: "border-box",
                     padding: this.props.mobile ? 12 : 20,
                     paddingBottom: this.props.mobile ? 22 : 20,
                     paddingLeft: this.props.mobile ? 24 : ((!this.props.mobile && (this.props.i % 2 === 0)) ? '15vw' : 40),
                     paddingRight: this.props.mobile ? 24 : ((!this.props.mobile && (this.props.i % 2 === 1)) ? '15vw' : 40),
                 }}
                 className='col-jc relative'
            >
                <div className='absolute cover-parent hero-cover-2'
                     style={{
                         opacity: (this.props.selectedIndex === this.props.i) ? .2 : 0,
                         transition: "300ms ease-in-out",
                         zIndex: -1
                     }}/>
                <FadeInOnScroll delay={!this.props.mobile && (this.props.i * 100)}>
                    <div className='row-ac-jb'>
                        <div className={`row-ac ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`} style={{
                            transform: (!this.props.mobile && this.state.hovering) && 'scale(1.03)',
                            transition: "transform 300ms ease-in-out",
                            marginBottom: -12
                        }}>
                            <div style={{
                                width: this.props.mobile ? 48 : 68,
                                transform: this.props.mobile && 'scale(.8)'
                            }} className='col-ac-jc'>
                                {this.props.group.icon}
                            </div>
                            <p className={`${this.props.mobile ? 'title2' : 'title1'} ml-8 mt-0 mb-0`}>
                                {/*<span className={`mr-16 ${this.props.darkBody ? 'base-white-30' : 'base-black-30'}`}*/}
                                {/*      style={{width: 20}}>*/}
                                {/*    0{this.props.i + 1}*/}
                                {/*</span>*/}
                                {this.props.group.title}
                            </p>
                        </div>
                        <div className='relative'>
                            <div style={{
                                transform: (this.props.selectedIndex === this.props.i) ? 'rotate(45deg)' : 'rotate(0deg)',
                                opacity: (this.props.selectedIndex === this.props.i) ? 1 : 0,
                                transition: '300ms ease-in-out',
                                marginTop: this.props.mobile ? 16 : 4,
                            }}>
                                <SvgClose fill={'black'} width={this.props.mobile ? 24 : this.props.tablet ? 24 : 36}
                                          height={this.props.mobile ? 24 : this.props.tablet ? 24 : 36}/>
                            </div>
                        </div>
                    </div>
                </FadeInOnScroll>
                <CollapseComponent isOpen={this.props.selectedIndex === this.props.i}>
                    <div className='mt-8'
                         style={{
                             opacity: (this.props.selectedIndex === this.props.i) ? 1 : 0,
                             transition: 'opacity 300ms ease-in-out',
                             marginLeft:this.props.mobile ? 58 : 74
                         }}>
                        {this.props.group.topics.map((topic, i) =>
                            <CapabilitySectionTopics
                                key={topic.topic}
                                darkBody={this.props.darkBody}
                                hovering={this.props.selectedIndex === this.props.i}
                                topic={topic}
                                i={i}
                            />
                        )}
                    </div>
                </CollapseComponent>
            </div>
        )
    }
}

export default CapabilitySectionBlock
