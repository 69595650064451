import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width={100}
        height={100}
        {...props}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="M29.92 13.945a.5.5 0 0 0-.367.848L38.95 24.5H10.236a.5.5 0 0 0 0 1h28.715l-9.4 9.707a.5.5 0 1 0 .72.695l10.221-10.554a.5.5 0 0 0 0-.696l-10.22-10.554a.498.498 0 0 0-.352-.153z"/>
    </svg>
)

export default SvgComponent
