import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            d="M27.594 79.006c.682-24.395 26.303-36.363 48.275-35.682 21.974-.681 47.595 11.287 48.276 35.682-.682 24.395-26.301 36.363-48.276 35.682-21.972.681-47.594-11.287-48.275-35.682Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M90.296 44.765a45.668 45.668 0 0 1 14.522 18.87 40.21 40.21 0 0 1 3.036 15.374c0 .593-.003 1.168-.032 1.742a40.315 40.315 0 0 1-3.003 13.612 45.725 45.725 0 0 1-14.523 18.89M61.449 44.747a45.93 45.93 0 0 0-14.523 18.889 40.885 40.885 0 0 0-3.004 13.612c-.03.574-.033 1.168-.032 1.76a40.202 40.202 0 0 0 3.035 15.355 45.89 45.89 0 0 0 14.524 18.907"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M34.15 60.399a114.624 114.624 0 0 0 12.776 3.237 156.892 156.892 0 0 0 57.892 0 113.035 113.035 0 0 0 12.776-3.238M117.572 97.597a113.556 113.556 0 0 0-12.753-3.234 157.73 157.73 0 0 0-57.894 0 114.836 114.836 0 0 0-12.777 3.236M124.06 81.07a1.505 1.505 0 0 1-.314.016c-3.674-.058-3.676-4.122-7.36-4.07-3.653.048-3.654 3.96-7.36 3.918a3.807 3.807 0 0 1-1.204-.183c-2.496-.749-2.916-3.638-6.155-3.611-3.66.03-3.66 3.72-7.36 3.696-3.66-.021-3.663-3.641-7.36-3.627-3.663.012-3.665 3.591-7.36 3.586-3.667-.004-3.667-3.569-7.359-3.572-3.67-.006-3.671 3.573-7.36 3.585-3.672.015-3.673-3.604-7.36-3.626-3.675-.024-3.676 3.664-7.359 3.695-3.264.028-3.636-2.936-6.227-3.63a4.205 4.205 0 0 0-1.133-.162c-3.705-.042-3.707 3.868-7.36 3.917-3.707.051-3.708-4.011-7.36-4.069a2.241 2.241 0 0 0-.386.016"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
