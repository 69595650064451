import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={48}
        viewBox="0 96 960 960"
        width={48}
        {...props}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="M319 806h322v-60H319v60Zm0-170h322v-60H319v60ZM160 976V176h421l219 219v581H160Zm391-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z"/>
    </svg>
)

export default SvgComponent
