import React, {Component} from "react";
import ArrowRight from "../icons/custom/ArrowRight";


class CaseStudyStat extends Component {

    switchValue = () => {
        switch (this.props.label) {
            case 'Environment':
                return (
                    <div className='row-ac mb-40'>
                        {this.props.value.map((item, i) =>
                            <div className={`${i !== this.props.value.length - 1 && 'mr-16'} row-ac`}>
                                {(item === 'React' || item === 'React Native') &&
                                    <img src={require('../img/logos/react-logo.png')} style={{width: 24}}
                                         className='mr-4'/>
                                }
                                <p className='body1-bold base-black-100 mt-0 mb-0'>
                                    {item}
                                </p>
                            </div>
                        )}
                    </div>
                )
            case 'Live':
                return (
                    <a href={this.props.link} target={'_blank'} rel={'noreferrer'} className='row-ac mb-40'>
                        <p className='body1-bold base-black-100 mt-0 mb-0'>
                            {this.props.value.text}
                        </p>
                        <ArrowRight width={24} height={24} strokeWidth={2} stroke={'black'} className={'ml-4'}
                                    style={{transform: 'rotate(-45deg)'}}/>
                    </a>
                )
            default:
                return (
                    <p className='body1-bold base-black-100 mt-0 mb-40'>
                        {this.props.value}
                    </p>
                )
        }
    }

    render() {
        return (
            <div className={`mb-16 ${this.props.className}`}>
                <p className='body4-bold uppercase base-black-30 mb-20 mt-0'>
                    {this.props.label}
                </p>
                {this.switchValue()}
            </div>
        )
    }
}

export default CaseStudyStat
