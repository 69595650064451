import React, {Component} from "react";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import ContactUsComponent from "../components/ContactUsComponent";
import {GLOSSI_CASE_STUDY} from "../utils/dummy";
import CaseStudyStat from "../components/CaseStudyStat";
import 'react-before-after-slider-component/dist/build.css';
import CaseHeaderInner from "../components/cases/CaseHeaderInner";
import TiltEffect from "../components/wrappers/TiltEffect";
import ParallaxImage from "../components/ParallaxImage";
import video1 from '../img/cases/glossi/glossi studio.mp4'
import fullwidth1 from '../img/cases/glossi/glossifullwidth1.png'
import asset1 from '../img/cases/glossi/glossi screens 1.png'
import asset2 from '../img/cases/glossi/asset2 (1).png'
import video2 from '../img/cases/glossi/glossi cover.mp4'
import SvgLogoIcon from "../img/cases/glossi/SvgLogoIcon";
import QuickLink from "../components/QuickLink";
import MoreCases from "../components/cases/MoreCases";

class GlossiCaseStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollPosition = this.sectionRef.getBoundingClientRect().top;
        this.setState({
            scrollPosition: scrollPosition
        });
    }


    render() {
        const {scrollPosition} = this.state
        const translateAmounts = [
            scrollPosition * 0.08 * .4,
            scrollPosition * -0.16 * .4,
            scrollPosition * 0.20 * .4,
        ];
        const delimiterIconStyles = {
            width: this.props.mobile ? '24px' : '50px',
            height: this.props.mobile ? '24px' : '50px',
            borderRadius: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${require('../img/arrows.png')})`,
        }
        const object = GLOSSI_CASE_STUDY
        return (
            <>
                <div className='case-hero-section col-jb'
                     style={{
                         overflow: "hidden",
                         background: `linear-gradient(-120.9deg, ${object.colors[0]} 8.2%, ${object.colors[1]} 90.7%)`,
                         height:this.props.mobile && 400
                     }}
                     ref={(ref) => {
                         this.sectionRef = ref;
                     }}
                >
                    <CaseHeaderInner
                        mobile={this.props.mobile}
                        case={object}
                    />
                    <div className='row-plain relative' style={{padding: '0 5vw', marginTop: '0vh'}}>
                        <div style={{
                            flex: 1,
                            transform: `translateY(${translateAmounts[0] * 8}px) scale(${1 - translateAmounts[1] * .005}, ${1 - translateAmounts[1] * .005})`
                        }}>
                            <FadeInOnScroll triggerImmediately delay={300}>
                                <TiltEffect
                                    scale={{
                                        x: .02,
                                        y: .01,
                                    }}
                                    style={{
                                        borderRadius: 20,
                                        boxShadow: '0px -4px 20px 20px #00000010'
                                    }}
                                >
                                    <video autoPlay loop muted className="splash-video"
                                           playsInline
                                           style={{
                                               objectFit: 'cover',
                                               width: "100%",
                                               height: '100%',
                                               marginBottom: -6,
                                               // position: 'absolute',
                                               top: 0,
                                               left: 0,
                                               right: 0,
                                               bottom: 0,
                                               borderRadius: 20
                                           }}>
                                        <source src={require('../videos/GlossiNoGutter.mp4')}
                                                type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </TiltEffect>
                            </FadeInOnScroll>
                        </div>
                        <div className={'absolute'} style={{
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 300,
                            background: 'linear-gradient(to bottom, #00000000, #00000040)',
                            zIndex: 999
                        }}/>
                    </div>
                </div>
                <div className='section row-plain'
                     style={{
                         paddingLeft: this.props.mobile && 20,
                         paddingRight: this.props.mobile && 20,
                         paddingTop: this.props.mobile && '15vh',
                         paddingBottom: this.props.mobile && '5vh'
                     }}>
                    {!this.props.mobile &&
                        <div style={{flex: .7}}>
                            <CaseStudyStat label={'Brand'} value={object.brand}/>
                            <CaseStudyStat label={'Industry'} value={object.industry}/>
                            <CaseStudyStat label={'Location'} value={object.location}/>
                            <CaseStudyStat label={'Environment'} value={object.environment}/>
                            <CaseStudyStat label={'Release'} value={object.release} className={'mr-32'}/>
                            <CaseStudyStat label={'Live'} value={object.live}
                                           link={'https://www.glossi.io/'}/>
                        </div>
                    }
                    <div style={{flex: 1}}>
                        {object.summary.map((block, i) =>
                            <div className={i !== object.summary.length - 1 && 'mb-60'}>
                                <FadeInOnScroll>
                                    <p className='body4-bold uppercase base-black-30 mb-16 mt-0'>
                                        {block.label}
                                    </p>
                                </FadeInOnScroll>
                                <div className='m-0'>
                                    <FadeInOnScroll delay={150}>
                                        <>
                                            <p className='body1 base-black-80 mt-0'>
                                                {block.text}
                                            </p>
                                            {block.text2 &&
                                                <p className='body1 base-black-80 mt-4'>
                                                    {block.text2}
                                                </p>
                                            }
                                        </>
                                    </FadeInOnScroll>
                                    {block.list &&
                                        <ul className='ml-16'>
                                            {block.list.map((item, i) =>
                                                <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                    <li className={`body3 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                    </li>
                                                </FadeInOnScroll>
                                            )}
                                        </ul>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100p mv-100' style={{borderTop: "1px solid #00000020"}}/>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <video autoPlay
                           playsInline
                           loop muted className="splash-video"
                           style={{
                               objectFit: 'cover',
                               width: "100%",
                               height: '100%',
                               marginBottom: -6,
                               // position: 'absolute',
                               top: 0,
                               left: 0,
                               right: 0,
                               bottom: 0,
                               borderRadius: 12
                           }}>
                        <source src={video1}
                                type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <ParallaxImage
                    src={fullwidth1}
                    speed={0.1} // Adjust the speed as needed
                    translateY={100} // Adjust the translation amount as needed
                    style={{
                        objectFit: "cover",
                        width: this.props.mobile ? '100vw' : '100%',
                        maxHeight: '80vh'
                    }}
                />
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <img alt={''} src={asset2} className={this.props.mobile ? 'w-100p' : 'w-70p'}/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac-je'}>
                    <img alt={''} src={asset1} className={this.props.mobile ? 'w-100p' : 'w-70p'}/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}}>
                        <video autoPlay
                               playsInline
                               loop muted className="splash-video"
                               style={{
                                   objectFit: 'cover',
                                   width: "100%",
                                   height: '100%',
                                   marginBottom: -6,
                                   // position: 'absolute',
                                   top: 0,
                                   left: 0,
                                   right: 0,
                                   bottom: 0,
                                   borderRadius: 12
                               }}>
                            <source src={video2}
                                    type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className={!this.props.mobile ? 'flex-1 ml-80 col-ac' : 'col-ac mt-100'}>
                        <SvgLogoIcon width={200} height={200} loading="true" fill={'#F04E23'}/>
                    </div>
                </div>
                <div className='col-ac-jc' style={{padding: '10vh 0'}}>
                    <QuickLink
                        title={'View next case'}
                        sm
                        toRight
                        onClick={() => this.props.history.push('/work/showopen')}
                        mobile={this.props.mobile}
                    />
                </div>
                <MoreCases history={this.props.history} case={object}/>
                <div style={{paddingTop: '15vh', marginTop: '10vh', borderTop: '1px solid #00000033'}}>
                    <ContactUsComponent mobile={this.props.mobile}/>
                </div>
            </>
        )
    }
}

export default GlossiCaseStudy
