import React, {Component} from "react";
import ModalComponent from "./ModalComponent";
import {Link} from "react-router-dom";
import Smile from "../icons/pack2/Smile";


class FormSubmittedModal extends Component {

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={'md'}
                toggle={this.props.closeModal}
                // headerToggle
            >
                <div style={{marginLeft: -32}}>
                    <Smile className={'base-white-100 w-50p'}/>
                </div>
                <h1 className={`${this.props.mobile ? 'title1' : 'title1'} base-white-100 mb-0 mt-0`}>
                    Thanks for reaching out!
                </h1>
                <p className='body2 base-white-80 mb-40'>
                    Your message has been sent. We'll get back to you as soon as we can!
                </p>
                <div className='row-ac-jc'>
                    <Link to={'/'} className='mr-20 ml-12'>
                        <button className='secondary-button body3-bold uppercase'>
                            Back Home
                        </button>
                    </Link>
                    <button onClick={this.props.closeModal} className='secondary-button body3-bold uppercase'>
                        Close
                    </button>
                </div>
            </ModalComponent>
        )
    }

}


export default FormSubmittedModal;
