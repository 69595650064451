import React, {Component} from "react";
import AnimatedInput from "./AnimatedInput";
import {wait} from "../utils/timestamp";
import TaskChecked from "../icons/TaskChecked";
import Spinner from "./Spinner";

class TheLabWidget extends Component {
    state = {
        email: '',
        hasSubmit: false,
        processing: false,
    }

    onChangeEmail = (email) => {
        this.setState({email})
    }


    trySubmit = () => {
        this.setState({hasSubmit: true, processing: true});
        wait(600).then(() =>
            this.setState({success: true, processing: false, email: ''})
        )
    }

    render() {
        const disabled = (this.state.email === '')
        return (
            <div className='pb-100 page-h-padding'>
                <div className={`bg-base-black-4 ${this.props.mobile ? 'col-plain' : 'row-ac-jb'} p-32 lab-widget`}
                     style={{}}>
                    <div className={this.props.mobile && 'row-je'}>
                        <img src={require('../img/Holographic 3D shape 43.png')}
                             style={{
                                 width: '25vw',
                                 marginTop: this.props.mobile ? 0 : -100,
                                 marginBottom: this.props.mobile ? -80 : 0,
                                 zIndex: 2,
                                 position: 'relative'
                             }}
                             className='rotate-rev-slow'
                        />
                    </div>
                    <div className={`col-je flex-1 ${!this.props.mobile && 'ml-40'}`}>
                        <div className='row-ac'>
                            <div>
                                <p className='heading3 mt-0 mb-0 base-black-100 mb-4' style={{fontWeight: 800}}>
                                    Stay up to date!
                                </p>
                                <p className='title1 mt-0 mb-0 base-black-100' style={{fontWeight: 400}}>
                                    Drop your email to hear from us with news and exciting opportunities.
                                </p>
                                <div className='row-ac mt-36'>
                                    <div className='' style={{maxWidth: 400,}}>
                                        <AnimatedInput
                                            name="email"
                                            onChange={this.onChangeEmail}
                                            value={this.state.email}
                                            type="text"
                                            placeholder="Your email"
                                            isRequired
                                        />
                                    </div>
                                    <div className='col-plain ml-12'>
                                        <button className='primary-button body2-bold' disabled={disabled}
                                                onClick={this.trySubmit}
                                                style={{opacity: disabled ? .2 : 1, height: 48,}}>
                                            {this.state.success ? <TaskChecked width={24} heigh={24}
                                                                               className={'base-white-100'}/> : this.state.processing ?
                                                <Spinner/> : ' Sign up'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<h1 className={`heading1 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}*/}
                {/*    style={{fontSize: 100}}*/}
                {/*>*/}
                {/*    FAQ*/}
                {/*</h1>*/}
            </div>
        )
    }
}

export default TheLabWidget
