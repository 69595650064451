import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={48}
        viewBox="0 96 960 960"
        width={48}
        {...props}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="m566 936-43-43 162-162H200V256h60v415h426L524 509l43-43 233 233-234 237Z" />
    </svg>
)

export default SvgComponent
