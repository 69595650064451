import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";


class TeamCard extends Component {
    state = {
        hovering: false,
        hoveringContainer: false
    }

    onMouseEnter = (i) => {
        this.props.selectIndex(i)
        this.setState({hovering: true})
        this.props.toggleDarkNavbar(true)
        document.body.style.backgroundColor = 'black';
    }

    onMouseLeave = (i) => {
        this.props.selectIndex(null)
        this.setState({hovering: false})
        this.props.toggleDarkNavbar(false)
        document.body.style.backgroundColor = 'white';
    }

    onMouseEnterContainer = () => {
        this.setState({hoveringContainer: true})
    }

    onMouseLeaveContainer = () => {
        this.setState({hoveringContainer: false})
    }

    render() {
        return (
            <div style={{overflowX: "hidden"}}
                 onMouseEnter={this.onMouseEnterContainer}
                 onMouseLeave={this.onMouseLeaveContainer}
                 className={`relative ${this.props.mobile ? 'col-plain mb-20' : 'row-ac'} pv-8`}
            >
                <div className='relative' style={{paddingLeft: 6}}>
                    <FadeInOnScroll delay={this.props.i * 100}>
                        <div
                            className={`bg-base-black-100 ${this.props.mobile && 'w-60p'}`}
                            style={{alignSelf: "flex-start"}}
                        >
                            <img
                                onMouseEnter={() => this.onMouseEnter(this.props.i)}
                                onMouseLeave={() => this.onMouseLeave(this.props.i)}
                                src={this.props.member.img}
                                alt={this.props.member.name}
                                className={'team-pic'}
                                style={{
                                    objectFit: "cover",
                                    width: this.props.mobile ? '100%' : '20vw',
                                    aspectRatio: 1,
                                    backgroundColor: 'black',
                                    outline: '2px solid white',
                                    outlineOffset: this.props.selectedIndex === this.props.i ? 4 : 0,
                                    transition: "300ms ease-in-out",
                                    marginBottom: -1.5,
                                    opacity: this.props.selectedIndex !== null ? (this.props.selectedIndex === this.props.i ? 1 : .2) : 1
                                }}/>
                        </div>
                    </FadeInOnScroll>
                    {this.props.member.extraImgs &&
                        <div className='absolute col-jc' style={{
                            top: this.props.mobile ? '55vw' : 0,
                            bottom: !this.props.mobile && 0,
                            left:this.props.mobile ? 0 : 'calc(20vw + 60px)',
                        }}>
                            <p className='base-white-100 body1 m-0 mb-8 pr-24'
                               style={{opacity: this.state.hovering ? 1 : 0, transition: "opacity 300ms ease-in-out", paddingTop:this.props.mobile && 12}}>
                                {this.props.member.bio2}
                            </p>
                            <div className='row-ac'>
                                {this.props.member.extraImgs.map((image, i) =>
                                    <div
                                        className='mr-8'
                                        style={{
                                            opacity: this.state.hovering ? 1 : 0,
                                            transform: `translateX(${this.state.hovering ? '0px' : `-${16 * (i + 1)}vw`} )`,
                                            transition: `${(i + 1) * 300}ms ease-in-out`,
                                            zIndex: -(i + 1),
                                        }}>
                                        <img src={image} alt={this.props.member.name + 'extra' + i}
                                             style={{width: this.props.mobile ? '21vw' : "11.7vw", height: this.props.mobile ? '21vw' : '11.7vw', objectFit: "cover"}}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                <div style={{flex: 1, overflowY: "hidden", paddingLeft: this.props.mobile && 6}}
                     className={`${!this.props.mobile && 'ml-40'} relative`}>
                    <div style={{
                        opacity: this.props.selectedIndex === null ? 1 : 0,
                        transition: "opacity 300ms ease-in-out"
                    }}>
                        <p className='heading3 base-black-80 mb-0 mt-8'>
                            {this.props.member.name}
                        </p>
                        <p className='body1-bold base-black-30 uppercase mt-0'>
                            {this.props.member.title}
                        </p>
                        <div className={`relative`}>
                            <p className='body1 base-black-70 mt-0'>
                                {this.props.member.bio}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamCard
