import React, {Component} from 'react';
import AnimatedInput from "./AnimatedInput";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import ContactUsLink from "./ContactUsLink";
import Tiles from "./Tiles";
import Dropzone from "./DropZone";
import {createRequest} from "../api/contact";
import {wait} from "../utils/timestamp";
import Spinner from "./Spinner";
import FormSubmittedModal from "./FormSubmittedModal";
import uuid from 'react-uuid';
import TaskChecked from "../icons/TaskChecked";
import {uploadDoc} from "../api/firebase";
import {BUTTON_TEXT_ZERO_TO_ONE} from "../utils/dummy";

class ContactForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            lastName: '',
            email: '',
            hasSubmit: false,
            processing: false,
            content: '',
            projName: '',
            currentURL: '',
            rangeValues: [2],
            files: [],
            successModalVisible: false,
            success: false
        }
    }

    onChangeName = (name) => {
        this.setState({name})
    }

    onChangeEmail = (email) => {
        this.setState({email})
    }

    onChangeProjName = (projName) => {
        this.setState({projName})
    }

    onChangeCurrentURL = (currentURL) => {
        this.setState({currentURL})
    }

    onChangeContent = (content) => {
        this.setState({content})
    }

    addFile = async (files) => {
        const file = files[0]
        const id = uuid();
        const url = await uploadDoc("uploads", id, file);
        file["url"] = url;
        this.setState({files: [...this.state.files, file]})
    }

    deleteFile = (file) => {
        this.setState({files: this.state.files.filter(f => f.path !== file.path)})
    }

    trySubmit = () => {
        this.setState({hasSubmit: true, processing: true});
        createRequest(this.state.name, this.state.email, this.state.content, this.state.currentURL, this.state.projName, this.state.rangeValues[0], this.state.files.map(file => file.url))
            .then(() => {
                wait(1000).then(() => {
                    this.openSuccessModal()
                    this.setState({processing: false, success: true})
                })
                wait(1500).then(() => {
                    this.setState({
                        success: false,
                    })
                    this.resetState()
                })
            })
    }

    openSuccessModal = () => {
        this.setState({successModalVisible: true})
    }

    closeSuccessModal = () => {
        this.setState({successModalVisible: false})
    }

    resetState = () => {
        this.setState({
            name: '',
            lastName: '',
            email: '',
            hasSubmit: false,
            processing: false,
            content: '',
            projName: '',
            currentURL: '',
            rangeValues: [2],
            files: [],
            success: false
        })
    }

    render() {
        const step = 1
        const min = 0
        const max = 13
        const disabled = (this.state.content === '' || this.state.email === '')
        return (
            <section className={`${this.props.mobile ? 'col-plain' : 'row-plain'} relative`}
                     style={{
                         minHeight: !this.props.mobile && 'calc(100vh)',
                         alignItems: "stretch",
                         overflow: "hidden"
                     }}>
                {!this.props.mobile &&
                    <div className='absolute cover-parent col-je' style={{bottom: -192, left: -8}}>
                        <Tiles columns={12} rows={8}/>
                    </div>
                }
                <div className='col-plain'
                     style={{padding: this.props.mobile ? '15vh 20px 20px 20px' : '28vh 0 15vh 8vw', flex: 1}}>
                    <div className={this.props.mobile ? 'relative w-100p' : 'relative w-60p'}>
                        <FadeInOnScroll triggerImmediately delay={10} loading={this.props.loading}>
                            <h1 style={{fontSize: !this.props.mobile && '5vw'}}
                                className={`heading1 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                                Get In Touch
                            </h1>
                        </FadeInOnScroll>
                        <FadeInOnScroll triggerImmediately delay={300} loading={this.props.loading}>
                            <p className='title1 base-black-70'>
                                Thanks for your interest in working with us! Tell us about your idea or fill us in on
                                your existing business or problem.
                            </p>
                        </FadeInOnScroll>
                        <FadeInOnScroll triggerImmediately delay={400} loading={this.props.loading}>
                            <ContactUsLink mobile={this.props.mobile}/>
                        </FadeInOnScroll>
                        <img src={require('../img/Holographic 3D shape 43.png')}
                             className='absolute rotate-rev-slow'
                             style={{
                                 width: '36vw',
                                 top: this.props.mobile ? '10%' : '-8vw',
                                 right: this.props.mobile ? 0 : '-160%',
                                 opacity: .5,
                                 zIndex: -1
                             }}/>
                    </div>
                </div>

                <div className='col-jc'
                     style={{
                         flex: .92,
                         padding: this.props.mobile ? '32px 20px 10vh 20px' : '100px 15vw 40px 40px',
                         backgroundColor: !this.props.mobile && '#FFFFFF80',
                         backdropFilter: !this.props.mobile && 'blur(24px)'
                     }}>
                    <FadeInOnScroll triggerImmediately delay={10} loading={this.props.loading}>
                        <div className='row-ac-jb' style={{gap: this.props.mobile ? 24 : 20}}>
                            <div>
                                <div
                                    className={`body4-bold uppercase base-black-70`}
                                    style={{gap: 4}}>
                                    Zero to One
                                </div>
                                <div className='title2 m-0'>
                                    Let's build something new:
                                </div>
                            </div>
                            <a href={'https://hello.scopelabs.com/'} target={'_blank'}
                               className='primary-button body2-bold'
                            >
                                {BUTTON_TEXT_ZERO_TO_ONE}
                            </a>
                        </div>
                    </FadeInOnScroll>
                    <FadeInOnScroll triggerImmediately delay={30} loading={this.props.loading}>
                        <div className='mv-8 row-ac-jb'>
                            <div className='bg-base-black-20 flex-1' style={{height: 1}}/>
                            <p className='body2-bold base-black-100 mh-12'>
                                OR
                            </p>
                            <div className='bg-base-black-20 flex-1' style={{height: 1}}/>
                        </div>
                    </FadeInOnScroll>
                    <FadeInOnScroll triggerImmediately delay={80} loading={this.props.loading}>
                        <div
                            className={`body4-bold uppercase base-black-70`}
                            style={{gap: 4}}>
                            Scope Studio
                        </div>
                        <div className='title2 mb-20'>
                            Tell us about your existing product:
                        </div>
                    </FadeInOnScroll>
                    <div className='row-ac mt-0'>
                        <div className='flex-1 mr-16'>
                            <FadeInOnScroll triggerImmediately delay={100} loading={this.props.loading}>
                                <AnimatedInput
                                    name="name"
                                    onChange={this.onChangeName}
                                    value={this.state.name}
                                    type="text"
                                    placeholder="Your name"
                                    isRequired
                                />
                            </FadeInOnScroll>
                        </div>
                        <div className='flex-1'>
                            <FadeInOnScroll triggerImmediately delay={200} loading={this.props.loading}>
                                <AnimatedInput
                                    name="email"
                                    onChange={this.onChangeEmail}
                                    value={this.state.email}
                                    type="text"
                                    placeholder="Your email or phone"
                                    isRequired
                                />
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <div className='row-ac mt-40'>
                        <div className='flex-1 mr-8'>
                            <FadeInOnScroll triggerImmediately delay={300} loading={this.props.loading}>
                                <AnimatedInput
                                    name="projName"
                                    onChange={this.onChangeProjName}
                                    value={this.state.projName}
                                    type="text"
                                    placeholder="Business/project name"
                                    isRequired
                                />
                            </FadeInOnScroll>
                        </div>
                        <div className='flex-1 ml-8'>
                            <FadeInOnScroll triggerImmediately delay={400} loading={this.props.loading}>
                                <AnimatedInput
                                    name="currentURL"
                                    onChange={this.onChangeCurrentURL}
                                    value={this.state.currentURL}
                                    type="text"
                                    placeholder="Current URL"
                                />
                            </FadeInOnScroll>
                        </div>
                    </div>

                    <div className={`mt-40 row-ac ${this.props.mobile ? 'body2' : 'body1'}`}>
                        <div className='flex-1'>
                            <FadeInOnScroll triggerImmediately delay={500} loading={this.props.loading}>
                                <AnimatedInput
                                    name="content"
                                    onChange={this.onChangeContent}
                                    value={this.state.content}
                                    multiline
                                    height={this.props.mobile ? 80 : 100}
                                    placeholder="Description of project"
                                    isRequired
                                />
                            </FadeInOnScroll>
                        </div>
                    </div>
                    {/*<FadeInOnScroll triggerImmediately delay={600} loading={this.props.loading}>*/}
                    {/*    <div className='col-plain' style={{flexWrap: 'wrap'}}>*/}
                    {/*        <p className='mt-16 body3-bold base-black-50'>*/}
                    {/*            Your Timeline*/}
                    {/*        </p>*/}
                    {/*        <Range*/}
                    {/*            step={step}*/}
                    {/*            min={min}*/}
                    {/*            max={max}*/}
                    {/*            // rtl={true}*/}
                    {/*            values={this.state.rangeValues}*/}
                    {/*            onChange={(values) => this.setState({rangeValues: values})}*/}
                    {/*            renderTrack={({props, children}) => (*/}
                    {/*                <div*/}
                    {/*                    onMouseDown={props.onMouseDown}*/}
                    {/*                    onTouchStart={props.onTouchStart}*/}
                    {/*                    style={{*/}
                    {/*                        ...props.style,*/}
                    {/*                        height: '36px',*/}
                    {/*                        display: 'flex',*/}
                    {/*                        width: '100%'*/}
                    {/*                    }}*/}
                    {/*                >*/}
                    {/*                    <div*/}
                    {/*                        ref={props.ref}*/}
                    {/*                        style={{*/}
                    {/*                            height: '5px',*/}
                    {/*                            width: '100%',*/}
                    {/*                            background: getTrackBackground({*/}
                    {/*                                values: this.state.rangeValues,*/}
                    {/*                                colors: ['#000000', '#00000000'],*/}
                    {/*                                min: min,*/}
                    {/*                                max: max,*/}
                    {/*                                rtl: false*/}
                    {/*                            }),*/}
                    {/*                            alignSelf: 'center'*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        {children}*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            )}*/}
                    {/*            renderMark={({props, index}) => (*/}
                    {/*                <div*/}
                    {/*                    {...props}*/}
                    {/*                    style={{*/}
                    {/*                        ...props.style,*/}
                    {/*                        height: '16px',*/}
                    {/*                        width: '2px',*/}
                    {/*                        backgroundColor: (index * step <= this.state.rangeValues[1]) ? '#000000' : '#00000020'*/}
                    {/*                    }}*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*            renderThumb={({props, value, index, isDragged}) => (*/}
                    {/*                <div*/}
                    {/*                    {...props}*/}
                    {/*                    style={{*/}
                    {/*                        ...props.style,*/}
                    {/*                        height: '36px',*/}
                    {/*                        width: '36px',*/}
                    {/*                        backgroundColor: '#00000020',*/}
                    {/*                        backdropFilter: 'blur(40px)',*/}
                    {/*                        display: 'flex',*/}
                    {/*                        justifyContent: 'center',*/}
                    {/*                        alignItems: 'center',*/}
                    {/*                        outline: 'none'*/}
                    {/*                        // boxShadow: '0px 2px 6px #AAA'*/}
                    {/*                    }}*/}
                    {/*                >*/}
                    {/*                    <div*/}
                    {/*                        style={{*/}
                    {/*                            position: 'absolute',*/}
                    {/*                            right: value < (max / 2) ? '-48px' : '42px',*/}
                    {/*                            color: '#fff',*/}
                    {/*                            fontWeight: 'bold',*/}
                    {/*                            fontSize: '14px',*/}
                    {/*                            // fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',*/}
                    {/*                            padding: '4px',*/}
                    {/*                            backgroundColor: '#000000',*/}
                    {/*                            width: 36,*/}
                    {/*                            textAlign: "center",*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        {this.state.rangeValues[0] === 0 ? '2wks' :*/}
                    {/*                            this.state.rangeValues[0] === 13 ? '1y+' :*/}
                    {/*                                this.state.rangeValues[0] + 'mo'}*/}
                    {/*                    </div>*/}
                    {/*                    <div*/}
                    {/*                        style={{*/}
                    {/*                            height: '16px',*/}
                    {/*                            width: '5px',*/}
                    {/*                            backgroundColor: isDragged ? '#ffffff' : '#000000'*/}
                    {/*                        }}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            )}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</FadeInOnScroll>*/}
                    <FadeInOnScroll triggerImmediately delay={700} loading={this.props.loading}>
                        <div className='mt-20'>
                            <Dropzone
                                addedFiles={this.state.files}
                                onDrop={this.addFile}
                                deleteFile={this.deleteFile}
                            />
                        </div>
                    </FadeInOnScroll>
                    <FadeInOnScroll triggerImmediately delay={800} loading={this.props.loading}>
                        <div className='col-ac-jc'>
                            <button className='primary-button body2-bold mt-20' disabled={disabled}
                                    onClick={this.trySubmit}
                                    style={{opacity: disabled ? .2 : 1}}>
                                {this.state.success ? <TaskChecked width={24} heigh={24}
                                                                   className={'base-white-100'}/> : this.state.processing ?
                                    <Spinner/> : ' Submit inquiry'}
                            </button>
                        </div>
                    </FadeInOnScroll>
                </div>
                <FormSubmittedModal
                    isOpen={this.state.successModalVisible}
                    closeModal={this.closeSuccessModal}
                />
            </section>
        );
    }
}

export default ContactForm;
