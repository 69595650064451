import React, {Component} from "react";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import ContactUsComponent from "../components/ContactUsComponent";
import {CASE_STUDY, REA_CASE_STUDY, RECRUIT_CASE_STUDY} from "../utils/dummy";
import CaseStudyStat from "../components/CaseStudyStat";
import CaseStudyContentBlock from "../components/CaseStudyContentBlock";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import TiltEffect from "../components/wrappers/TiltEffect";
import TiltingImage from "../components/TiltingImage";
import CaseHeaderInner from "../components/cases/CaseHeaderInner";
import QuickLink from "../components/QuickLink";
import {WORK} from "../utils/gallerydummy2";
import SvgArrowRight from "../icons/SvgArrowRight";
import MoreCases from "../components/cases/MoreCases";


class RecruitCaseStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollPosition = this.sectionRef.getBoundingClientRect().top;
        this.setState({
            scrollPosition: scrollPosition
        });
    }


    render() {
        const {scrollPosition} = this.state
        const translateAmounts = [
            scrollPosition * 0.08 * .4,
            scrollPosition * -0.16 * .4,
            scrollPosition * 0.20 * .4,
        ];
        const delimiterIconStyles = {
            width: this.props.mobile ? '24px' : '50px',
            height: this.props.mobile ? '24px' : '50px',
            borderRadius: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${require('../img/arrows.png')})`,
        }
        const object = RECRUIT_CASE_STUDY
        return (
            <>
                <div className='case-hero-section col-jb'
                     style={{
                         overflow: "hidden",
                         background: `linear-gradient(83.9deg, ${object.colors[0]} 8.2%, ${object.colors[1]} 90.7%)`,
                         height: this.props.mobile && 500
                     }}
                     ref={(ref) => {
                         this.sectionRef = ref;
                     }}
                >
                    <CaseHeaderInner
                        mobile={this.props.mobile}
                        case={object}
                    />
                    <div className='row-plain relative' style={{padding: '0 2vw', marginTop: '2vh'}}>
                        {!this.props.tablet &&
                            <div style={{
                                flex: 1,
                                transform: `translateY(${translateAmounts[0] * (this.props.mobile ? 3 : 8)}px) scale(${1 - translateAmounts[1] * .005}, ${1 - translateAmounts[1] * .005})`
                            }}>
                                <FadeInOnScroll triggerImmediately delay={300}>
                                    <TiltEffect
                                        scale={{
                                            x: .02,
                                            y: .01,
                                        }}
                                        style={{
                                            borderRadius: 20,
                                            boxShadow: '0px -4px 20px 20px #00000010'
                                        }}
                                    >
                                        <video autoPlay loop muted className="splash-video"
                                               playsInline
                                               style={{
                                                   objectFit: 'cover',
                                                   width: "100%",
                                                   height: '100%',
                                                   marginBottom: -6,
                                                   // position: 'absolute',
                                                   top: 0,
                                                   left: 0,
                                                   right: 0,
                                                   bottom: 0,
                                                   borderRadius: this.props.mobile ? 8 : 20
                                               }}>
                                            <source src={require('../videos/New RecordingNoGutter.mp4')}
                                                    type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </TiltEffect>
                                </FadeInOnScroll>
                            </div>
                        }
                        {/*<div style={{*/}
                        {/*    flex: this.props.mobile ? 1 : .4,*/}
                        {/*    transform: `translateY(${translateAmounts[1]}px)`*/}
                        {/*}} className={this.props.mobile ? 'ml-16' : 'mh-16'}>*/}
                        {/*    <FadeInOnScroll triggerImmediately delay={600}>*/}
                        {/*        <img alt={''} src={require('../img/cases/starfish/starfishhero1.png')}*/}
                        {/*             className='w-100p'/>*/}
                        {/*    </FadeInOnScroll>*/}
                        {/*</div>*/}
                        {/*{!this.props.mobile &&*/}
                        {/*    <div style={{flex: 1, transform: `translateY(${translateAmounts[2]}px)`}} className='mt-20'>*/}
                        {/*        <FadeInOnScroll triggerImmediately delay={1100}>*/}
                        {/*            <img alt={''} src={require('../img/cases/starfish/starfishsplash1.png')}*/}
                        {/*                 className='w-100p'/>*/}
                        {/*        </FadeInOnScroll>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <div className={'absolute'} style={{
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 300,
                            background: 'linear-gradient(to bottom, #00000000, #00000040)',
                            zIndex: 999
                        }}/>
                    </div>
                </div>
                <div className='section row-plain'
                     style={{
                         paddingLeft: this.props.mobile && 20,
                         paddingRight: this.props.mobile && 20,
                         paddingTop: this.props.mobile && '15vh',
                         paddingBottom: this.props.mobile && '5vh'
                     }}>
                    {!this.props.mobile &&
                        <div style={{flex: .4}}>
                            <CaseStudyStat label={'Brand'} value={object.brand}/>
                            <CaseStudyStat label={'Industry'} value={object.industry}/>
                            <CaseStudyStat label={'Location'} value={object.location}/>
                            <CaseStudyStat label={'Environment'} value={object.environment}/>
                            <CaseStudyStat label={'Release'} value={object.release} className={'mr-32'}/>
                            <CaseStudyStat label={'Live'} value={object.live}
                                           link={'https://www.recruitu.io/'}/>
                        </div>
                    }
                    <div style={{flex: 1}}>
                        {object.summary.map((block, i) =>
                            <div className={i !== object.summary.length - 1 && 'mb-60'}>
                                <FadeInOnScroll>
                                    <p className='body2-bold uppercase base-black-30 mb-16 mt-0'>
                                        {block.label}
                                    </p>
                                </FadeInOnScroll>
                                <div className='m-0'>
                                    <FadeInOnScroll delay={150}>
                                        <p className='body1 base-black-80 mt-0'>
                                            {block.text}
                                        </p>
                                    </FadeInOnScroll>
                                    {block.list &&
                                        <div className='row-plain' style={{gap: 80}}>
                                            <ul className='ml-16'>
                                                {block.list.slice(0, 3).map((item, i) =>
                                                    <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                        <li className={`body2 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                        </li>
                                                    </FadeInOnScroll>
                                                )}
                                            </ul>
                                            <ul>
                                                {block.list.length > 3 &&
                                                    block.list.slice(3).map((item, i) =>
                                                        <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                            <li className={`body2 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                            </li>
                                                        </FadeInOnScroll>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100p mv-100' style={{borderTop: "1px solid #00000020"}}/>
                <div className={this.props.mobile ? 'section-base-mobile' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-60p'}>
                        <CaseStudyContentBlock content={object.content[0]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}} className='col-ac'>
                        <img alt={''} src={require('../img/cases/recruit/recruittable.png')} className='w-80p'/>
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={object.content[1]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 mr-80'}>
                        <CaseStudyContentBlock content={object.content[2]}/>
                    </div>
                    <div style={{flex: 1.2}} className='col-ac'>
                        <img alt={''} src={require('../img/cases/recruit/recruitcolors.png')} className='w-100p'/>
                    </div>
                </div>
                <div className='page-h-padding row-ac-ja mv-100 mt-40' style={{flexWrap: this.props.mobile && 'wrap'}}>
                    <div className='col-ac'>
                        <img alt={''} src={require('../img/cases/recruit/recruitbrand.png')} style={{width: 300}}/>
                    </div>
                    <div className='row-ja' style={{flexWrap: "wrap", marginTop: this.props.mobile && '10vh'}}>
                        <FadeInOnScroll>
                            <img alt={''} src={require('../img/cases/recruit/techny-kanban-board-on-tablet.gif')}
                                 style={{width: this.props.mobile ? '30vw' : "20vw", transform: "scale(1.3)"}}/>
                        </FadeInOnScroll>
                        <FadeInOnScroll delay={200}>
                            <img alt={''} src={require('../img/cases/recruit/techny-data-dashboard.gif')}
                                 style={{width: this.props.mobile ? '30vw' : "20vw", transform: "scale(1.3)"}}/>
                        </FadeInOnScroll>
                        <FadeInOnScroll delay={400}>
                            <img alt={''}
                                 src={require('../img/cases/recruit/techny-school-supplies-for-school-subjects.gif')}
                                 style={{width: this.props.mobile ? '30vw' : "20vw", transform: "scale(1)"}}/>
                        </FadeInOnScroll>
                    </div>
                </div>
                <div className='relative page-h-padding'>
                    <div className='relative'>
                        <img alt={''} src={require('../img/cases/recruit/recruitbrandfull.png')} className='w-100p'
                             style={{objectFit: "cover", borderRadius: 20}}/>
                        <img alt={''} src={require('../img/cases/recruit/spokes.png')} className='rotate absolute'
                             style={{width: '12vw', top: '4vw', right: '4vw'}}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}} c>
                        <img alt={''} src={require('../img/cases/recruit/recruitdesign.png')} className='w-100p'/>
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={object.content[3]}/>
                    </div>
                </div>
                <div className='page-h-padding'>
                    <img alt={''} src={require('../img/cases/recruit/recruitplatform (1).png')} className='w-100p'/>
                </div>
                <div style={{flex: 1.2}}>
                    <video autoPlay
                           playsInline
                           loop muted className="splash-video"
                           style={{
                               objectFit: 'cover',
                               width: "100%",
                               height: '100%',
                               marginBottom: -6,
                               // position: 'absolute',
                               top: 0,
                               left: 0,
                               right: 0,
                               bottom: 0,
                               margin: '10vh 0'
                               // borderRadius: 12
                           }}>
                        <source src={require('../videos/RecruitJobBoard16x9.mp4')}
                                type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 mr-80'}>
                        <CaseStudyContentBlock content={object.content[4]}/>
                    </div>
                    <div style={{flex: 1.2}}>
                        <video autoPlay
                               playsInline
                               loop muted className="splash-video"
                               style={{
                                   objectFit: 'cover',
                                   width: "100%",
                                   height: '100%',
                                   marginBottom: -6,
                                   // position: 'absolute',
                                   top: 0,
                                   left: 0,
                                   right: 0,
                                   bottom: 0,
                                   borderRadius: 12
                               }}>
                            <source src={require('../videos/RecruitSplah.mp4')}
                                    type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile pb-0' : 'section-base'}>
                    <div className={!this.props.mobile && 'flex-1 w-50p'}>
                        <CaseStudyContentBlock content={object.content[5]}/>
                    </div>
                </div>
                <div className={`section-base ${this.props.mobile && 'ph-0'} pt-0 col-ac-jc`}>
                    <img alt={''} src={require('../img/cases/recruit/recruitdeck.png')}
                         className={this.props.mobile ? 'w-100p' : 'w-70p'}
                         style={{objectFit: "contain"}}/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile pb-20' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-50p'}>
                        <CaseStudyContentBlock content={object.content[6]}/>
                    </div>
                </div>
                <div className='col-ac-jc' style={{padding: '10vh 0'}}>
                    <QuickLink
                        title={'View next case'}
                        sm
                        toRight
                        onClick={() => this.props.history.push('/work/stonkmadness')}
                        mobile={this.props.mobile}
                    />
                </div>
                <MoreCases history={this.props.history} case={object}/>
                <div style={{paddingTop: '15vh', marginTop: '10vh', borderTop: '1px solid #00000033'}}>
                    <ContactUsComponent mobile={this.props.mobile}/>
                </div>
            </>
        )
    }
}

export default RecruitCaseStudy
