import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import ParallaxImage from "./ParallaxImage";

class AfterWaterfall extends Component {


    render() {
        if (this.props.mobile) {
            return (
                <div className='col-plain relative' style={{paddingTop: '0vh'}}>
                    <div className={'col-ae'} style={{}}>
                        <FadeInOnScroll delay={300} className=''>
                            <ParallaxImage
                                src={require('../img/after/after1mobile.png')}
                                speed={0.1} // Adjust the speed as needed
                                translateY={100} // Adjust the translation amount as needed
                                style={{
                                    width: this.props.mobile ? '90vw' : '80vw',
                                }}
                            />
                        </FadeInOnScroll>
                    </div>
                    <div style={{flex: 1, marginTop: '-25vw', marginBottom:100}}>
                        <FadeInOnScroll delay={300} className='pb-32'>
                            <ParallaxImage
                                src={require('../img/after/after2mobile.png')}
                                speed={.2} // Adjust the speed as needed
                                translateY={100} // Adjust the translation amount as needed
                                style={{width: this.props.mobile ? '74vw' : '50vw'}}
                            />
                        </FadeInOnScroll>
                    </div>
                </div>
            )
        }
        return (
            <div className='col-plain relative page-h-padding' style={{paddingTop: '0vh'}}>
                <div className={'col-ae'} style={{marginBottom: this.props.mobile && '20vh'}}>
                    <FadeInOnScroll delay={300} className=''>
                        <ParallaxImage
                            src={require('../img/after/after1.png')}
                            speed={0.2} // Adjust the speed as needed
                            translateY={100} // Adjust the translation amount as needed
                            style={{
                                width: this.props.mobile ? '90vw' : '80vw',
                                marginRight: this.props.mobile ? -20 : -200
                            }}
                        />
                    </FadeInOnScroll>
                </div>
                <div style={{flex: 1, marginTop: '-25vw', marginLeft: -120}}>
                    <FadeInOnScroll delay={300} className='pb-32'>
                        <ParallaxImage
                            src={require('../img/after/after2.png')}
                            speed={.6} // Adjust the speed as needed
                            translateY={100} // Adjust the translation amount as needed
                            style={{width: this.props.mobile ? '74vw' : '50vw'}}
                        />
                    </FadeInOnScroll>
                </div>
            </div>
        )
    }
}

export default AfterWaterfall
