import React, {Component} from "react";
import TiltEffect from "./wrappers/TiltEffect";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";

class CapabilitiesSectionTile extends Component {

    render() {
        const group = this.props.item
        return (
            <FadeInOnScroll style={{height: '100%'}} delay={this.props.mobile ? 10 : (this.props.index * 100)}>
                <TiltEffect>
                    <div
                        className='col-plain capabilities-card mb-0 col-plain h-100p'
                        style={{boxSizing: "border-box", padding: this.props.mobile && 16}}>
                        <div>
                            <div style={{
                                width: this.props.mobile ? 48 : 68,
                                transform: this.props.mobile && 'scale(.8)',
                                marginLeft:!this.props.mobile && -12
                            }} className='col-ac-jc'>
                                {group.icon}
                            </div>
                            <p className='title1 mt-0 mb-20'>
                                {group.title}
                            </p>

                        </div>
                        <div className='col-plain' style={{alignItems: 'flex-start'}}>
                            {group.topics.map(item =>
                                <p className='body1 m-4 base-black-50 h-base-black-70 mt-0'>
                                    {item.topic}
                                </p>
                            )}
                        </div>
                    </div>
                </TiltEffect>
            </FadeInOnScroll>
        )
    }
}

export default CapabilitiesSectionTile
