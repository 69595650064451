import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            d="M125.253 78.145a16.448 16.448 0 0 1-16.448 16.448 63.932 63.932 0 0 1-31.827-16.448 63.93 63.93 0 0 1-31.827 16.448 16.448 16.448 0 0 1-16.448-16.448 16.448 16.448 0 0 1 16.448-16.447 63.932 63.932 0 0 1 31.827 16.447 63.932 63.932 0 0 1 31.827-16.447 16.448 16.448 0 0 1 16.448 16.447Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M53.781 112.386C44.098 99.269 42.416 88.138 42.223 78.11c.206-10 1.903-21.117 11.503-34.137M100.263 44.042c9.573 13.006 11.242 24.082 11.462 34.068-.206 10.042-1.875 21.2-11.6 34.317M76.97 78.146"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M76.978 78.145c-1.796 0-14.467 43.962-33.736 33.736-10.66-5.658-14.539-25.55-14.539-33.736 0-9.577 3.015-27.609 14.54-33.735 18.794-9.992 33.735 33.735 33.735 33.735 1.995 0 14.94-43.727 33.736-33.735 11.524 6.126 14.539 24.164 14.539 33.735 0 8.19-3.879 28.078-14.54 33.736-19.268 10.226-33.735-33.736-33.735-33.736ZM125.026 78.174H28.93"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
