import React, {Component} from "react";
import LeadershipTeam from "../components/LeadershipTeam";
import ContactUsComponent from "../components/ContactUsComponent";
import WireframeLogo1 from "../icons/custom/wireframelogo2/WireframeLogo1";
import WireframeLogo2 from "../icons/custom/wireframelogo2/WireframeLogo2";
import WireframeLogo3 from "../icons/custom/wireframelogo2/WireframeLogo3";
import WireframeLogo4 from "../icons/custom/wireframelogo2/WireframeLogo4";
import WireframeLogo5 from "../icons/custom/wireframelogo2/WireframeLogo5";
import WireframeLogo6 from "../icons/custom/wireframelogo2/WireframeLogo6";
import WireframeLogo7 from "../icons/custom/wireframelogo2/WireframeLogo7";
import WireframeLogo8 from "../icons/custom/wireframelogo2/WireframeLogo8";
import WireframeLogo9 from "../icons/custom/wireframelogo2/WireframeLogo9";
import WereHiringSection from "../components/WereHiringSection";
import {WHY_SCOPE} from "../utils/dummy";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import SvgHandPeace from "../icons/custom/SvgHandPeace";
import VericalHero from "../components/VericalHero";
import OurProcess from "../components/OurProcess";
import TestimonialsSeciton from "../components/TestimonialsSection";


class About extends Component {
    state = {
        scrollPosition: window.pageYOffset,
        darkBody: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        this.setState({
            scrollPosition: currentScrollPos,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    switchGraphic = (mobile) => {
        const size = mobile ? '20vw' : '35vw'
        const breakpoints = this.props.mobile ? [200, 400, 600, 800, 1000, 1200, 1400, 1600] : [200, 400, 600, 800, 1000, 1200, 1400, 1600];
        const {scrollPosition} = this.state;
        if (this.state.scrollPosition < breakpoints[0]) {
            return <WireframeLogo1 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[0] && this.state.scrollPosition < breakpoints[1]) {
            return <WireframeLogo2 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[1] && this.state.scrollPosition < breakpoints[2]) {
            return <WireframeLogo3 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[2] && this.state.scrollPosition < breakpoints[3]) {
            return <WireframeLogo4 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[3] && this.state.scrollPosition < breakpoints[4]) {
            return <WireframeLogo5 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[4] && this.state.scrollPosition < breakpoints[5]) {
            return <WireframeLogo6 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[5] && this.state.scrollPosition < breakpoints[6]) {
            return <WireframeLogo7 width={size} height={size}/>
        }
        if (this.state.scrollPosition >= breakpoints[6] && this.state.scrollPosition < breakpoints[7]) {
            return <WireframeLogo8 width={size} height={size}/>
        } else {
            return <WireframeLogo9 width={size} height={size}/>
        }
    }

    toggleDarkNavbar = (toggle) => {
        if (toggle) {
            document.body.style.backgroundColor = 'black';
            this.props.toggleDarkNavbar(true)
            this.setState({darkBody: true})
        } else {
            document.body.style.backgroundColor = 'white';
            this.props.toggleDarkNavbar(false)
            this.setState({darkBody: false})
        }
    }

    render() {
        return (
            <div style={{padding: '0vh 0 0 0'}}>
                <VericalHero
                    width={'50%'}
                    name={'About Scope Labs'}
                    header={'We are problem solvers'}
                    bgImg={require('../img/Sample • Holographic 3D shape.png')}
                    description={'From initial concept to fully operational business, we work as a strategic partner for companies and people we believe in.'}
                    mobile={this.props.mobile}/>
                {/*<div className=' relative' style={{paddingTop: '20vh'}}>*/}
                {/*    <div className='absolute cover-parent hero-cover-2' style={{opacity: .5}}/>*/}
                {/*    <div className={this.props.mobile ? 'section-base-mobile pv-0' : 'section-base pv-0'}*/}
                {/*         style={{marginBottom: '5vh'}}>*/}
                {/*        <div className={this.props.mobile ? 'relative col-plain' : 'relative col-ae ml-40'}*/}
                {/*             style={{flex: .2, float: !this.props.mobile && "right", marginTop: -20, opacity: .5}}>*/}

                {/*        </div>*/}
                {/*        <div style={{flex: 1}}>*/}
                {/*            <FadeInOnScroll loading={this.props.loading} triggerImmediately delay={100}>*/}
                {/*                <p className='heading1 mt-12 mb-40'*/}
                {/*                   style={{fontSize: !this.props.mobile && '5vw', fontWeight: 800, lineHeight: 1.1}}>*/}
                {/*                    If you can interact with it digitally, we can develop it.*/}
                {/*                </p>*/}
                {/*            </FadeInOnScroll>*/}
                {/*            <FadeInOnScroll loading={this.props.loading} triggerImmediately delay={300}>*/}
                {/*                <p className='title1 mb-40' style={{maxWidth: this.props.mobile ? '90vw' : '60vw'}}>*/}
                {/*                    We believe creating world-class digital products requires true collaboration*/}
                {/*                    between ideators and innovators. Our relationships are founded on the*/}
                {/*                    principles of openness, effective communication, and a*/}
                {/*                    meticulous attention to detail.*/}
                {/*                </p>*/}
                {/*            </FadeInOnScroll>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div style={{}}>*/}
                {/*        <FadeInOnScroll loading={this.props.loading} triggerImmediately delay={300}>*/}
                {/*            <Marquee gradient={false}*/}
                {/*                // direction={"right"}*/}
                {/*                     speed={34}*/}
                {/*                     className={this.state.scrollPosition > 500 ? 'base-black-4' : 'base-white-70'}*/}
                {/*                     style={{*/}
                {/*                         textShadow: `1px 2px 12px #${this.state.scrollPosition > 500 ? '0000000' : '00000002'}`,*/}
                {/*                         overflow: "hidden",*/}
                {/*                         transition: 'color 600ms ease-in-out',*/}
                {/*                     }}>*/}
                {/*                <div className='heading1 m-0 uppercase row-ac'*/}
                {/*                     style={{fontSize: this.props.mobile ? '14vw' : '9vw'}}>*/}
                {/*                    Websites*/}
                {/*                    <Shape74 width={this.props.mobile ? '14vw' : '9vw'}*/}
                {/*                             height={this.props.mobile ? '14vw' : '9vw'}/>*/}
                {/*                    Apps*/}
                {/*                    <Shape63 width={this.props.mobile ? '12vw' : '8vw'}*/}
                {/*                             height={this.props.mobile ? '12vw' : '8vw'}/>*/}
                {/*                    Tools*/}
                {/*                    <Shape94 width={this.props.mobile ? '14vw' : '9vw'}*/}
                {/*                             height={this.props.mobile ? '14vw' : '9vw'}/>*/}
                {/*                    Dashboards*/}
                {/*                    <Shape5 width={this.props.mobile ? '14vw' : '9vw'}*/}
                {/*                            height={this.props.mobile ? '14vw' : '9vw'}/>*/}
                {/*                    Systems*/}
                {/*                    <Shape85 width={this.props.mobile ? '14vw' : '9vw'}*/}
                {/*                             height={this.props.mobile ? '14vw' : '9vw'}/>*/}
                {/*                </div>*/}
                {/*            </Marquee>*/}
                {/*        </FadeInOnScroll>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <WeBuildSection*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                <FadeInOnScroll triggerImmediately delay={800}>
                    <div className='page-h-padding' style={{
                        paddingBottom: '10vh',
                        boxSizing: 'border-box',
                        width: this.props.mobile ? '100%' : '70%'
                    }}>
                        <SvgHandPeace style={{
                            width: this.props.mobile ? 64 : 120,
                            height: this.props.mobile ? 64 : 120,
                            transform: 'rotate(4deg)'
                        }}/>
                        <p className='title1 base-black-100 m-0 mb-40'>
                            We are a team of founders consisting of
                            experienced product managers, designers, and developers.
                        </p>
                        <p className='title1 base-black-100 m-0'>
                            Our expertise lies in challenging fundamental assumptions, dissecting legacy behaviors,
                            simplifying intricate processes, and delivering experiences that leave a lasting impact.
                        </p>
                    </div>
                </FadeInOnScroll>
                <LeadershipTeam darkBody={this.state.darkBody} toggleDarkNavbar={this.toggleDarkNavbar}
                                mobile={this.props.mobile}/>
                <div className='page-h-padding row-jb' style={{paddingTop:'20vh'}}>
                    <div className='sticky-section-container'>
                        <div className='sticky-hero-container' style={{opacity: .4}}>
                            <FadeInOnScroll loading={this.props.loading} triggerImmediately delay={500}>
                                {this.switchGraphic(this.props.mobile)}
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <div className='col-ae'>
                        <div style={{width: !this.props.mobile && '35vw', marginLeft: this.props.mobile && 48}}
                             className=''>
                            <div>
                                {WHY_SCOPE.map(item =>
                                    <div className='mb-80'>
                                        <div className='row-plain' style={{marginLeft: -20}}>
                                                <span className='body1-bold base-black-50'>
                                                    {item.label}
                                                </span>
                                            <span className='heading3 mb-0'>
                                                   {item.title}
                                                </span>
                                        </div>
                                        <p className='title1 base-black-70'>
                                            {item.description}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/*<TopCapabilitiesSection*/}
                {/*    darkBody={this.state.darkBody}*/}
                {/*    tablet={this.props.tablet}*/}
                {/*    mobile={this.props.mobile}*/}
                {/*/>*/}
                {/*<div>*/}
                {/*    <CapabilitiesSection*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Services*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className=''>*/}
                {/*    <AboutHighlights darkBody={this.state.darkBody} mobile={this.props.mobile}/>*/}
                {/*</div>*/}
                {/*<FeatureSection*/}
                {/*    darkBody={this.state.darkBody}*/}
                {/*    tablet={this.props.tablet}*/}
                {/*    mobile={this.props.mobile}*/}
                {/*/>*/}
                {/*<div style={{paddingTop:'20vh'}}>*/}
                {/*    <TestimonialsSeciton*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<OurProcess/>*/}
                {/*<LeadershipTeam darkBody={this.state.darkBody} toggleDarkNavbar={this.toggleDarkNavbar}*/}
                {/*                mobile={this.props.mobile}/>*/}
                {/*<div style={{paddingTop: '30vh'}}>*/}
                {/*    <TheLabWidget*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                <WereHiringSection mobile={this.props.mobile} darkBody={this.state.darkBody}/>
                <div className=''>
                    <ContactUsComponent mobile={this.props.mobile} darkBody={this.state.darkBody}/>
                </div>
            </div>
        )
    }
}

export default About
