import React, {Component} from "react";
import {RingLoader} from "react-spinners";
import {TypeAnimation} from "react-type-animation";


class LoadingScreen extends Component {
    render() {
        return (
            <div className='col-ac-jc bg-base-white-100'
                 style={{
                     top: 0,
                     left: 0,
                     position: 'fixed',
                     right: 0,
                     height: this.props.loading ? '100vh' : '0vh',
                     zIndex: 9999,
                     // opacity: this.state.loading ? 1 : 0,
                     transition: "600ms ease-in-out",
                     overflow: "hidden",
                     boxShadow: '0 8px 20px #00000020'
                 }}>
                <div className='absolute cover-parent hero-cover' style={{height: '100vh'}}/>
                <div className='absolute crosshair-line-x'/>
                <div className='absolute crosshair-line-y'/>
                <div className=' col-ac-jc'
                     style={{opacity: this.props.loading ? 1 : 0, transition: "600ms ease-in-out"}}>
                    <div style={{width: this.props.mobile ? 40 :100, height: this.props.mobile ? 40 :100, marginLeft: this.props.mobile ? -6 : -24, marginTop: this.props.mobile ? 48 : 48}}>
                        <RingLoader color="#00000050" size={this.props.mobile ? 40 :100} speedMultiplier={.5}/>
                    </div>
                    <p className={`${this.props.mobile ? 'body4-bold' : 'body3-bold'} base-black-50 relative`} style={{zIndex: 1, marginTop: this.props.mobile ? 32 : 42}}>
                        <TypeAnimation
                            sequence={[
                                'Loading your experience...',
                                1200,
                            ]}
                            repeat={Infinity}
                        />
                    </p>
                </div>
            </div>
        )
    }
}
export default LoadingScreen
