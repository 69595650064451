import React, {PureComponent} from "react";
import {motion} from "framer-motion";

class CollapseComponent extends PureComponent {
    render() {
        const variants = {
            closed: {
                height: 0
            },
            open: {
                height: 'fit-content'
            }
        }

        return (
            <motion.div variants={variants}
                        animate={this.props.isOpen ? 'open' : 'closed'}
                        initial={this.props.isOpen}
                        style={{...this.props.style, overflow: 'hidden'}}
            >
                <div className={this.props.className}>
                    {this.props.children}
                </div>
            </motion.div>
        );
    }
}

export default CollapseComponent;
