import * as React from "react"

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <path
                fill="none"
                stroke={props.fill || 'currentColor'}
                strokeLinejoin="round"
                strokeWidth={1.58364}
                d="M21.44106 49.67651L83.17721 49.67651"
            />
            <path
                fill="none"
                stroke={props.fill || 'currentColor'}
                strokeLinejoin="round"
                strokeWidth={1.58364}
                d="M43.92714 77.56723L43.92714 21.78244"
            />
            <path
                fill="none"
                stroke={props.fill || 'currentColor'}
                strokeLinejoin="round"
                strokeWidth={1.58364}
                d="M16.17721 77.42979L71.68209 21.92323"
            />
            <path
                fill="none"
                stroke={props.fill || 'currentColor'}
                strokeLinejoin="round"
                strokeWidth={1.58364}
                d="M63.65039 69.39976L24.20405 29.95326"
            />
        </svg>
    )
}

export default SvgComponent
