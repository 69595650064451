import * as React from "react"

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <path
                d="M83.477 51.155c-29.147 1.202-29.634 2.29-9.812 23.688-21.397-19.822-22.486-19.335-23.688 9.812-1.202-29.147-2.29-29.634-23.688-9.812 19.822-21.397 19.335-22.486-9.812-23.688 29.147-1.202 29.634-2.29 9.812-23.688 21.398 19.822 22.486 19.335 23.688-9.812 1.202 29.147 2.29 29.634 23.688 9.812-19.822 21.398-19.335 22.486 9.812 23.688z"
                fill="none"
                stroke={props.fill || 'currentColor'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
        </svg>
    )
}

export default SvgComponent
