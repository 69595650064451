import React, {Component} from "react";
import ContactForm from "../components/ContactForm";

class ContactUs extends Component {
    render() {
        return (
            <ContactForm loading={this.props.loading} mobile={this.props.mobile}/>
        )
    }
}

export default ContactUs
