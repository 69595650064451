import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
        <path
            d="M85.23 64.205a2.506 2.506 0 0 1-2.343 2.498 2.576 2.576 0 0 1-1.19-.213q-1.926-.864-3.802-1.709C71.49 61.92 65.705 59.397 62.46 58.4c1.99 3.268 6.725 9.339 11.34 15.251a2.505 2.505 0 0 1-3.917 3.123c-4.847-5.96-8.493-10.409-11.234-13.372-2.701-2.925-3.98-3.772-4.497-4.014-.983 1.55-1.864 7.76-2.518 12.37a2.503 2.503 0 0 1-4.968-.6c.121-1.26.242-2.44.354-3.55.658-6.54 1.23-12.2.513-13.193a3.06 3.06 0 0 0-1.206-.146 44.984 44.984 0 0 0-4.652.427l-.204.024a2.502 2.502 0 0 1-1.065-4.866l.175-.058c.658-.213 1.389-.445 2.043-.672-2.75-1.633-8.55-4.368-16.414-8.052-.48-.222-.963-.45-1.457-.683q-2.382-1.12-5.084-2.387a2.502 2.502 0 0 1 2.087-4.547c1.283.576 2.557 1.148 3.81 1.705 6.406 2.866 12.192 5.383 15.43 6.385-1.99-3.272-6.724-9.343-11.338-15.255a2.501 2.501 0 0 1 3.911-3.118c4.852 5.96 8.498 10.404 11.234 13.367 2.706 2.926 3.984 3.772 4.502 4.014.983-1.543 1.864-7.756 2.518-12.365a2.502 2.502 0 0 1 4.968.596c-.121 1.258-.242 2.444-.354 3.553-.658 6.542-1.23 12.2-.513 13.193a3.09 3.09 0 0 0 1.2.14 44.986 44.986 0 0 0 4.658-.42l.204-.025a2.502 2.502 0 0 1 1.064 4.866l-.174.054c-.663.213-1.389.45-2.043.673 2.755 1.636 8.556 4.373 16.414 8.057q.719.334 1.452.682 2.34 1.09 4.978 2.334a2.496 2.496 0 0 1 1.554 2.314Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M64.726 47.172a2.498 2.498 0 0 0-2.74-1.947l-.204.025a44.986 44.986 0 0 1-4.657.42 3.09 3.09 0 0 1-1.201-.14c-.717-.992-.145-6.651.513-13.193.112-1.109.233-2.295.354-3.553a2.502 2.502 0 0 0-4.968-.596c-.654 4.609-1.535 10.822-2.518 12.365-.518-.242-1.796-1.088-4.502-4.014-2.736-2.963-6.382-7.407-11.234-13.367a2.501 2.501 0 0 0-3.911 3.118c4.614 5.912 9.348 11.983 11.339 15.255-3.239-1.002-9.025-3.52-15.43-6.385-.005-.102-.005-.2-.005-.295 0-14.452 11.717-19.406 26.169-11.063 12.448 7.19 22.868 21.894 25.516 35.073-7.858-3.684-13.659-6.42-16.414-8.057.654-.223 1.38-.46 2.043-.673l.174-.054a2.505 2.505 0 0 0 1.676-2.919ZM77.9 65.082c0 14.452-11.717 19.404-26.17 11.058-12.447-7.185-22.866-21.895-25.52-35.068 7.863 3.684 13.664 6.42 16.414 8.052-.654.227-1.385.459-2.043.672l-.175.058a2.502 2.502 0 0 0 1.065 4.866l.204-.024a44.984 44.984 0 0 1 4.652-.427 3.06 3.06 0 0 1 1.206.146c.717.993.145 6.653-.513 13.193-.112 1.11-.233 2.29-.354 3.55a2.503 2.503 0 0 0 4.968.6c.654-4.61 1.535-10.82 2.518-12.37.518.242 1.796 1.089 4.497 4.014 2.74 2.963 6.387 7.411 11.234 13.372a2.505 2.505 0 0 0 3.916-3.123C69.185 67.74 64.45 61.668 62.46 58.4c3.244.997 9.03 3.52 15.435 6.381.005.102.005.198.005.301Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
    </svg>
)

export default SvgComponent
