import React, {Component} from "react";
import TeamCard from "./TeamCard";
import {TEAM} from "../utils/dummy";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import Shape17 from "../icons/pack1/Shape17";
import SvgHourglass from "../icons/SvgHourglass";


class LeadershipTeam extends Component {
    state = {
        selectedIndex: null
    }

    selectIndex = (selectedIndex) => {
        this.setState({selectedIndex})
    }

    render() {
        return (
            <section
                style={{paddingTop:this.props.mobile ? '10vh' : '15vh'}}
                className='section ph-0 pb-0 relative' ref={(ref) => {
                this.sectionRef = ref;
            }}>
                <div className='page-h-padding'>
                    <FadeInOnScroll delay={10}>
                        <h2 className={`heading2 mt-0 ${this.props.mobile ? 'mb-24' : 'mb-40'}  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                            Leadership Team
                        </h2>
                    </FadeInOnScroll>
                </div>
                {/*<img src={require('../img/cases/stan/stan-cover.png')} className='page-h-padding' style={{height: '40vh', width: '100%', boxSizing:"border-box"}}/>*/}
                <div className='col-plain'
                     style={{
                         flexWrap: 'wrap',
                         // borderTop: '1px solid #00000020', borderBottom: '1px solid #00000020'
                     }}>
                    <div className='page-h-padding mb-80'>
                        <div className='relative'>
                            <TeamCard member={TEAM[0]} i={0} toggleDarkNavbar={this.props.toggleDarkNavbar}
                                      darkBody={this.props.darkBody}
                                      selectIndex={this.selectIndex} selectedIndex={this.state.selectedIndex}
                                      mobile={this.props.mobile}
                            />
                            <div className='absolute base-black-100' style={{
                                bottom: !this.props.mobile && 0,
                                left: !this.props.mobile && -80,
                                right: this.props.mobile && 0,
                                top: this.props.mobile && 24,
                            }}>
                                <p className='body4-bold uppercase mt-0' style={{marginBottom: -16}}>
                                    &nbsp;&nbsp;&nbsp;{this.props.mobile ? 'Tap' : 'Hover'} Us
                                </p>
                                <Shape17 width={80} height={80}
                                         style={{transform: `rotate(-12deg) ${this.props.mobile && 'scaleX(-1)'}`}}/>
                            </div>
                        </div>
                    </div>
                    {/*<div style={{borderTop: '1px solid #00000020'}} className='w-100p'/>*/}
                    <div className='page-h-padding mb-80'>
                        <TeamCard member={TEAM[1]} i={1} toggleDarkNavbar={this.props.toggleDarkNavbar}
                                  darkBody={this.props.darkBody}
                                  selectIndex={this.selectIndex} selectedIndex={this.state.selectedIndex}
                                  mobile={this.props.mobile}
                        />
                    </div>
                    {/*<div style={{borderTop: '1px solid #00000020'}} className='w-100p'/>*/}
                    <div className='page-h-padding mb-80'>
                        <TeamCard member={TEAM[2]} i={1} toggleDarkNavbar={this.props.toggleDarkNavbar}
                                  darkBody={this.props.darkBody}
                                  selectIndex={this.selectIndex} selectedIndex={this.state.selectedIndex}
                                  mobile={this.props.mobile}
                        />
                    </div>
                    {/*<div style={{borderTop: '1px solid #00000020'}} className='w-100p'/>*/}
                    <div className='page-h-padding mb-80'>
                        <TeamCard member={TEAM[3]} i={2} toggleDarkNavbar={this.props.toggleDarkNavbar}
                                  darkBody={this.props.darkBody}
                                  selectIndex={this.selectIndex} selectedIndex={this.state.selectedIndex}
                                  mobile={this.props.mobile}
                        />
                    </div>

                    <div className='page-h-padding'>
                        <TeamCard member={TEAM[4]} i={2} toggleDarkNavbar={this.props.toggleDarkNavbar}
                                  darkBody={this.props.darkBody}
                                  selectIndex={this.selectIndex} selectedIndex={this.state.selectedIndex}
                                  mobile={this.props.mobile}
                        />
                    </div>
                    {/*<div className='row-ac w-100p mt-40'>*/}
                    {/*    <div className='page-h-padding w-100p row-ac'>*/}
                    {/*        <div style={{backgroundColor: this.props.darkBody ? '#FFFFFF40' : '#00000020', height: 1}}*/}
                    {/*             className='flex-1'/>*/}
                    {/*        <div*/}
                    {/*            className={`mh-16 row-ac transition1 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>*/}
                    {/*            <SvgHourglass width={32} height={32} className='mr-8'/>*/}
                    {/*            <p className={`body2-bold`}>*/}
                    {/*                One more founder to be named*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*        <div style={{backgroundColor: this.props.darkBody ? '#FFFFFF40' : '#00000020', height: 1}}*/}
                    {/*             className='flex-1'/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </section>
        )
    }
}

export default LeadershipTeam
