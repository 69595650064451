import React, {Component} from 'react';

class ParallaxImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inViewport: false,
        };
        this.handleIntersection = this.handleIntersection.bind(this);
    }

    componentDidMount() {
        const observer = new IntersectionObserver(this.handleIntersection, {
            threshold: .01, // Adjust this threshold as needed
        });
        observer.observe(this.container);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (this.state.inViewport) {
            const translateY = this.container.getBoundingClientRect().top
            console.log(this.container.getBoundingClientRect().top)
            this.setState({translateY: translateY * this.props.speed})
        }
    }

    handleIntersection(entries) {
        entries.forEach((entry) => {
            const {speed} = this.props;
            const translateY = entry.isIntersecting ? entry.boundingClientRect.y * speed : 0;
            console.log('translate', entry.boundingClientRect)
            this.setState({inViewport: entry.isIntersecting});
        });
    }


    render() {
        const {src, speed} = this.props;
        const {inViewport, translateY} = this.state;

        return (
            <div
                ref={(ref) => (this.container = ref)}
                style={{
                    marginTop:this.props.speed*-200,
                    marginBottom:this.props.speed*-200,
                    ...this.props.style,
                    paddingTop: 20,
                }}
            >
                <img src={src} alt="Parallax Image"
                     style={{transform: `translateY(${inViewport ? translateY : 0}px)`, ...this.props.style}}/>
            </div>
        );
    }
}

export default ParallaxImage;
