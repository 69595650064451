import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64px"
            height="64px"
            {...props}
        >
            <path d="M6 6a1 1 0 00-1 1v21a1 1 0 001 1h11.203c.977 0 1.946-.167 2.902-.383l1.45 6.717a1 1 0 00.216.437s8.182 9.583 15.965 18.354a1 1 0 001.393.102l3.807-3.21 1.65 1.885-3.7 3.211a1 1 0 00-.07 1.442c6.403 6.766 17.993-4.26 12.819-9.795a1 1 0 10-1.461 1.365c2.882 3.083-4.14 11.002-9.006 7.658l3.488-3.027a1 1 0 00.096-1.414l-2.287-2.613 5.488-4.625a1 1 0 00.108-1.424c-6.147-7.026-11.388-13.053-15.235-17.744a1 1 0 00-1.722.318c-.472 1.416-1.79 2.887-3.022 3.945-1.232 1.058-2.314 1.701-2.314 1.701a1 1 0 00-.243 1.52l14.092 16.094-3.025 2.548a1620.302 1620.302 0 01-15.137-17.404l-1.45-6.719c.64-.266 1.304-.465 1.89-.855 2.752-1.83 3.548-4.234 3.548-4.234a1 1 0 00-.55-1.217l-7.372-3.254a1 1 0 00-1.382 1.125l1.547 7.17c-.81.185-1.637.326-2.483.326H7V8h49v19H42a1 1 0 100 2h15a1 1 0 001-1V7a1 1 0 00-1-1H6zm14.508 15l4.619 2.04c-.35.623-.957 1.46-2.34 2.38a1 1 0 00-.002 0c-.368.245-.806.358-1.207.545L20.508 21zm13.701 7.287c3.622 4.379 8.246 9.72 13.684 15.938l-4.747 4-13.4-15.305c.479-.315.776-.46 1.64-1.203 1.02-.875 2.033-2.076 2.823-3.43z" />
        </svg>
    )
}

export default SvgComponent
