import React, {Component} from 'react';
import CountUp from "react-countup";

class FeatureTile extends Component {


    render() {
        return (
            <div
                className={`${this.props.darkBody ? 'feature-tile-dark h-bg-base-white-8' : 'feature-tile h-bg-base-black-4'} col-ac-jc text-center ${this.props.mobile ? 'pv-8' : 'pv-24'}`}
                style={this.props.style}
            >
                <p className={`${this.props.mobile ? 'heading3' : 'heading1'} ${this.props.darkBody ? 'base-white-100' : 'base-black-80'} m-0`}>
                    <CountUp enableScrollSpy={true} end={this.props.stat}
                             duration={this.props.duration}/><span>{this.props.append}</span>
                </p>
                {this.props.mobile && (this.props.label === 'Sites / Apps Built') ?
                    <p className={`${this.props.tablet ? 'title2' : 'title2'} ${this.props.darkBody ? 'base-white-50' : 'base-black-50'} m-0 uppercase`}
                       style={{fontSize: this.props.mobile && 9}}
                    >
                        SITES / APPS<br/>BUILT
                    </p>
                    :this.props.mobile && (this.props.label === 'Users Served') ?
                        <p className={`${this.props.tablet ? 'title2' : 'title2'} ${this.props.darkBody ? 'base-white-50' : 'base-black-50'} m-0 uppercase`}
                           style={{fontSize: this.props.mobile && 9}}
                        >
                            USERS<br/>SERVED
                        </p>
                    :
                    <p className={`${this.props.tablet ? 'title2' : 'title2'} ${this.props.darkBody ? 'base-white-50' : 'base-black-50'} m-0 uppercase`}
                       style={{fontSize: this.props.mobile && 9}}
                    >
                        {this.props.label}
                    </p>
                }
            </div>
        );
    }
}

export default FeatureTile;
