import React, {Component} from "react";

class TiltEffect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotate: {x: 0, y: 0},
        };
    }

    throttle = (func, delay) => {
        let lastCall = 0;
        return (...args) => {
            const now = new Date().getTime();
            if (now - lastCall < delay) {
                return;
            }
            lastCall = now;
            return func(...args);
        };
    };

    onMouseMove = (e) => {
        const card = e.currentTarget;
        const box = card.getBoundingClientRect();
        const x = e.clientX - box.left;
        const y = e.clientY - box.top;
        const centerX = box.width / 2;
        const centerY = box.height / 2;
        const rotateX = (y - centerY) / 7;
        const rotateY = (centerX - x) / 7;

        this.setState({rotate: {x: rotateX * .05, y: rotateY * .1}});
    };

    onMouseLeave = () => {
        this.setState({rotate: {x: 0, y: 0}});
    };

    render() {
        const {rotate} = this.state;

        const cardStyles = {
            position: "relative",
            overflow: "hidden",
            height: "80vh", // Corresponds to h-52 class
            width: "100%", // Corresponds to w-52 class
            borderRadius: "1rem", // Corresponds to rounded-xl class
            backgroundColor: "#ffffff", // Corresponds to bg-white class
            transition: "transform 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s",
            willChange: "transform",
            transform: `perspective(1000px) rotateX(${rotate.x}deg) rotateY(${rotate.y}deg) scale3d(1, 1, 1)`,
        };

        const pulseStyles = {
            position: "absolute",
            top: "-8px", // Corresponds to -inset-2 class
            left: "-8px", // Corresponds to -inset-2 class
            right: "-8px", // Corresponds to -inset-2 class
            bottom: "-8px", // Corresponds to -inset-2 class
            borderRadius: "1.25rem", // Corresponds to rounded-lg class
            backgroundImage:
                "linear-gradient(to right, #3b82f6, #9333ea, #9333ea, #9333ea)", // Corresponds to bg-gradient-to-r class
            opacity: "0.32", // Corresponds to opacity-75 class
            filter: "blur(16px)", // Corresponds to blur-xl class
        };

        const contentStyles = {
            position: "relative",
            display: "flex",
            height: "100%", // Corresponds to h-full class
            width: "100%", // Corresponds to w-full class
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "1rem", // Corresponds to rounded-lg class
            backgroundColor: "#00000020", // Corresponds to bg-slate-900 class
            fontSize: "0.875rem", // Corresponds to text-sm class
            fontWeight: "300", // Corresponds to font-light class
            color: "#e5e7eb", // Corresponds to text-slate-300 class
        };

        return (
            <>
                <div
                    style={cardStyles}
                    onMouseMove={this.throttle(this.onMouseMove, 100)}
                    onMouseLeave={this.onMouseLeave}
                >
                    <div style={pulseStyles}></div>
                    <img src={require('../img/gallery/rea/reacover.png')} className='w-100p h-100p'
                         style={{objectFit: "cover"}}/>
                </div>
            </>
        );
    }
}

export default TiltEffect;
