import React, {useState, useEffect} from "react";
import SvgArrowRight from "../../../icons/SvgArrowRight";

function RandomCharacter(props) {
    const [character, setCharacter] = useState(null);
    const [trigger, setTrigger] = useState(0); // State to trigger useEffect

    useEffect(() => {
        setCharacter(getRandomCharacter());
    }, [trigger]); // Effect runs when 'trigger' changes

    const getRandomCharacter = () => {
        const characters = [
            "AAPL", "META", "GOOG", "MSFT", "AMZN", "SHOP", "V", "MA", "NFLX", "DIS", "TSLA", "RIVN",
            "PYPL", "SQ", "RBLX", "EA", 'PDD', 'BABA', 'NKE', 'LULU', 'UA', 'CROX', 'HD', 'LOW', 'T', 'VZ',
            'WMT', 'TGT', 'GME', 'AMC', 'PENN', 'DKNG', "NVDA", "AMD", "SMCI", "ARM", 'MCD', "CMG", "DPZ",
            "PZZA", "PEP", "KO", "MNST", "CELH", "SBUX", "DNUT", "TLRY", "WEN", 'BKNG', 'ABNB', 'COIN',
            'HOOD', 'UBER', 'LYFT', 'SNAP', 'PINS', 'MTCH', 'BMBL', 'LUV', 'DAL', 'SPOT', 'SIRI', 'F', 'GM'
        ];
        return characters[Math.floor(Math.random() * characters.length)];
    };

    const handleRandomizeClick = () => {
        setTrigger(prev => prev + 1); // Increment trigger to re-run useEffect
    };

    return (
        <div className='col-plain'>
            <div className={'bg-base-black-10 br-12 relative p-12 mb-12 w-full col-ac-jc'} style={{minHeight: 400}}>
                {character && (
                    <img
                        src={`https://zyidwzbarunqmcnmfukk.supabase.co/storage/v1/object/public/characters/md/${character}.png`}
                        alt={character}
                        style={{objectFit: 'contain'}}
                        className={props.className}
                    />
                )}
                <p className='absolute title1' style={{bottom: 12}}>
                    ${character}
                </p>
                <a href={'https://www.instagram.com/camereto/'} target={'_blank'}
                   className='absolute body4 base-black-50 h-base-black-100 pointer'
                   style={{bottom: 12, right: 12}}>
                    Art by @camereto&nbsp;
                    <SvgArrowRight width={12} height={12}/>
                </a>
            </div>
            <button onClick={handleRandomizeClick} className='primary-button body1-bold ml-a mr-a'>
                Randomize Character
            </button>
        </div>
    );
}

export default RandomCharacter;
