import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M316 916h328V789q0-70-47.5-120.5T480 618q-69 0-116.5 50.5T316 789v127zm164-382q69 0 116.5-50.5T644 362V236H316v126q0 71 47.5 121.5T480 534zM160 976v-60h96V789q0-70 36.5-128.5T394 576q-65-26-101.5-85T256 362V236h-96v-60h640v60h-96v126q0 70-36.5 129T566 576q65 26 101.5 84.5T704 789v127h96v60H160z"/>
        </svg>
    )
}

export default SvgComponent
