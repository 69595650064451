import React, {Component} from "react";

class TiltEffect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotate: {x: 0, y: 0},
        };
    }

    throttle = (func, delay) => {
        let lastCall = 0;
        return (...args) => {
            const now = new Date().getTime();
            if (now - lastCall < delay) {
                return;
            }
            lastCall = now;
            return func(...args);
        };
    };

    onMouseMove = (e) => {
        const card = e.currentTarget;
        const box = card.getBoundingClientRect();
        const x = e.clientX - box.left;
        const y = e.clientY - box.top;
        const centerX = box.width / 2;
        const centerY = box.height / 2;
        const rotateX = (y - centerY) / 7;
        const rotateY = (centerX - x) / 7;
        //
        this.setState({rotate: {x: rotateX * (this.props.scale?.x || 0.2), y: rotateY * (this.props.scale?.y || 0.2)}});
    };

    onMouseLeave = () => {
        this.setState({rotate: {x: 0, y: 0}});
    };

    render() {
        const {rotate} = this.state;

        const cardStyles = {
            position: "relative",
            height: '100%',
            // overflow: "hidden",
            width: "100%",
            // borderRadius: "1rem",
            // backgroundColor: "#ffffff",
            transition:
                "transform 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s",
            willChange: "transform",
            transform: `perspective(1000px) rotateX(${rotate.x}deg) rotateY(${rotate.y}deg) scale3d(1, 1, 1)`,
            ...this.props.style,
        };

        const pulseStyles = {
            position: "absolute",
            top: "-8px",
            left: "-8px",
            right: "-8px",
            bottom: "-8px",
            borderRadius: "1.25rem",
            backgroundImage:
                "linear-gradient(to right, #3b82f6, #9333ea, #9333ea, #9333ea)",
            opacity: "0.32",
            filter: "blur(16px)",
        };

        return (
            <div
                style={cardStyles}
                onMouseMove={this.throttle(this.onMouseMove, 100)}
                onMouseLeave={this.onMouseLeave}
            >
                {/*<div style={pulseStyles}></div>*/}
                {this.props.children}
            </div>
        );
    }
}

export default TiltEffect;
