import React, {Component} from 'react';
import '../styles/Tiles.css'

class Tiles extends Component {
    constructor(props) {
        super(props);
        this.squares = [];
        this.state = {
            isScrolling: false
        }
    }


    componentDidMount() {
        this.squares = Array.from(document.querySelectorAll('.item'));
        document.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleMouseMove = (e) => {
        const mouseX = e.pageX;
        const mouseY = e.pageY;
        if (this.squares.length > 0) {
            this.squares.forEach((sqr) => {
                if (sqr) {
                    const sqrX = sqr.offsetLeft + 20;
                    const sqrY = sqr.offsetTop + 20;

                    const diffX = mouseX - sqrX;
                    const diffY = mouseY - sqrY;

                    const radians = Math.atan2(diffY, diffX);

                    const angle = radians * (180 / Math.PI);

                    sqr.style.transform = `rotate(${angle}deg)`;
                }
            });
        }
    };

    handleScroll = () => {
        this.setState({isScrolling: true});
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            this.setState({isScrolling: false});
        }, 500);
    };

    render() {
        const {rows, columns} = this.props;
        const tileArray = Array(rows * columns).fill('');

        return (
            <div className="wrapper mb-12">
                {tileArray.map((_, index) => (
                    <div key={index} className="item" style={{
                        borderTopLeftRadius: index % 2 === 0 ? 32 : 0,
                        borderBottomLeftRadius: index % 2 === 0 ? 32 : 0,
                    }}></div>
                ))}
            </div>
        );
    }
}

export default Tiles;
