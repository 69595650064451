import React, {Component} from "react";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import ContactUsComponent from "../components/ContactUsComponent";
import {CASE_STUDY, EPIC_CASE_STUDY, REA_CASE_STUDY, RECRUIT_CASE_STUDY} from "../utils/dummy";
import CaseStudyStat from "../components/CaseStudyStat";
import CaseStudyContentBlock from "../components/CaseStudyContentBlock";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import TiltEffect from "../components/wrappers/TiltEffect";
import TiltingImage from "../components/TiltingImage";
import CaseHeaderInner from "../components/cases/CaseHeaderInner";
import QuickLink from "../components/QuickLink";


class EpicCaseStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollPosition = this.sectionRef.getBoundingClientRect().top;
        this.setState({
            scrollPosition: scrollPosition
        });
    }


    render() {
        const {scrollPosition} = this.state
        const translateAmounts = [
            scrollPosition * 0.08 * .4,
            scrollPosition * -0.16 * .4,
            scrollPosition * 0.20 * .4,
        ];
        const delimiterIconStyles = {
            width: this.props.mobile ? '24px' : '50px',
            height: this.props.mobile ? '24px' : '50px',
            borderRadius: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${require('../img/arrows.png')})`,
        }
        const object = EPIC_CASE_STUDY
        return (
            <>
                <div className='case-hero-section col-jb'
                     style={{
                         overflow: "hidden",
                         background: `linear-gradient(13.9deg, ${object.colors[0]} 8.2%, ${object.colors[1]} 90.7%)`
                     }}
                     ref={(ref) => {
                         this.sectionRef = ref;
                     }}
                >
                    <CaseHeaderInner
                        mobile={this.props.mobile}
                        case={object}
                    />
                    <div className='row-plain relative' style={{padding: '0 2vw', marginTop: '2vh'}}>
                        {!this.props.tablet &&
                            <div style={{
                                flex: 1,
                                transform: `translateY(${translateAmounts[0] * (this.props.mobile ? 3 : 8)}px) scale(${1 - translateAmounts[1] * .005}, ${1 - translateAmounts[1] * .005})`
                            }}>
                                <FadeInOnScroll triggerImmediately delay={300}>
                                    <TiltEffect
                                        scale={{
                                            x: .02,
                                            y: .01,
                                        }}
                                        style={{
                                            borderRadius: 20,
                                            boxShadow: '0px -4px 20px 20px #00000010'
                                        }}
                                    >
                                        <video autoPlay loop muted className="splash-video"
                                               playsInline
                                               style={{
                                                   objectFit: 'cover',
                                                   width: "100%",
                                                   height: '100%',
                                                   marginBottom: -6,
                                                   // position: 'absolute',
                                                   top: 0,
                                                   left: 0,
                                                   right: 0,
                                                   bottom: 0,
                                                   borderRadius: this.props.mobile ? 8 : 20
                                               }}>
                                            <source src={require('../img/cases/epic/EpicWesbiteSplash.mp4')}
                                                    type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </TiltEffect>
                                </FadeInOnScroll>
                            </div>
                        }
                        {/*<div style={{*/}
                        {/*    flex: this.props.mobile ? 1 : .4,*/}
                        {/*    transform: `translateY(${translateAmounts[1]}px)`*/}
                        {/*}} className={this.props.mobile ? 'ml-16' : 'mh-16'}>*/}
                        {/*    <FadeInOnScroll triggerImmediately delay={600}>*/}
                        {/*        <img alt={''} src={require('../img/cases/starfish/starfishhero1.png')}*/}
                        {/*             className='w-100p'/>*/}
                        {/*    </FadeInOnScroll>*/}
                        {/*</div>*/}
                        {/*{!this.props.mobile &&*/}
                        {/*    <div style={{flex: 1, transform: `translateY(${translateAmounts[2]}px)`}} className='mt-20'>*/}
                        {/*        <FadeInOnScroll triggerImmediately delay={1100}>*/}
                        {/*            <img alt={''} src={require('../img/cases/starfish/starfishsplash1.png')}*/}
                        {/*                 className='w-100p'/>*/}
                        {/*        </FadeInOnScroll>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <div className={'absolute'} style={{
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 300,
                            background: 'linear-gradient(to bottom, #00000000, #00000040)',
                            zIndex: 999
                        }}/>
                    </div>
                </div>
                <div className='section row-plain'
                     style={{
                         paddingLeft: this.props.mobile && 20,
                         paddingRight: this.props.mobile && 20,
                         paddingTop: this.props.mobile && '15vh',
                         paddingBottom: this.props.mobile && '5vh'
                     }}>
                    {!this.props.mobile &&
                        <div style={{flex: .4}}>
                            <CaseStudyStat label={'Brand'} value={object.brand}/>
                            <CaseStudyStat label={'Industry'} value={object.industry}/>
                            <CaseStudyStat label={'Location'} value={object.location}/>
                            <CaseStudyStat label={'Environment'} value={object.environment}/>
                            <CaseStudyStat label={'Release'} value={object.release} className={'mr-32'}/>
                            <CaseStudyStat label={'Live'} value={object.live}
                                           link={'https://epicphotoops.com/'}/>
                        </div>
                    }
                    <div style={{flex: 1}}>
                        {object.summary.map((block, i) =>
                            <div className={i !== object.summary.length - 1 && 'mb-60'}>
                                <FadeInOnScroll>
                                    <p className='body2-bold uppercase base-black-30 mb-16 mt-0'>
                                        {block.label}
                                    </p>
                                </FadeInOnScroll>
                                <div className='m-0'>
                                    <FadeInOnScroll delay={150}>
                                        <p className='body1 base-black-80 mt-0'>
                                            {block.text}
                                        </p>
                                    </FadeInOnScroll>
                                    {block.list &&
                                        <div className='row-plain' style={{gap: 80}}>
                                            <ul className='ml-16'>
                                                {block.list.slice(0, 3).map((item, i) =>
                                                    <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                        <li className={`body2 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                        </li>
                                                    </FadeInOnScroll>
                                                )}
                                            </ul>
                                            <ul>
                                                {block.list.length > 3 &&
                                                    block.list.slice(3).map((item, i) =>
                                                        <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                            <li className={`body2 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                            </li>
                                                        </FadeInOnScroll>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100p mv-100' style={{borderTop: "1px solid #00000020"}}/>
                <div className={this.props.mobile ? 'section-base-mobile col-plain col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}}>
                        <img alt={''} src={require('../img/cases/epic/Face-Scanner-Zoom (3).gif')}
                             className={this.props.mobile ? 'w-100p' : 'w-90p mr-32'}/>
                    </div>
                    <div className={this.props.mobile ? 'flex-1 mb-32' : 'flex-1'}>
                        <CaseStudyContentBlock content={{
                            title: 'Computer Vision',
                            text: "A visualisation of Scope's custom computer-vision model that works to intelligently suggest where and how photos should be cropped."
                        }}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-ac pb-0' : 'section-base col-ac pb-0'}>
                    <div className='relative'>
                        <FadeInOnScroll delay={150}>
                            <div className='relative text-center mb-32 col-ac'>
                                <h2 className='heading2 mt-0 mb-12 base-black-80'>
                                    Line Management Boards
                                </h2>
                                <p className='body1 w-70p base-black-50'>
                                    Scope also helped improve visual designs of Epic's video boards. There boards are
                                    used at events to show customers where they need to be and when.
                                </p>
                            </div>
                        </FadeInOnScroll>
                    </div>
                </div>
                <div
                    className={this.props.mobile ? 'section-base-mobile col-plain-rev pt-0' : 'section-base row-ac pt-0'}>
                    <div style={{flex: 1}} className='col-ac'>
                        <img alt={''} src={require('../img/cases/epic/LM1.png')} className='w-100p'/>
                    </div>
                    <div style={{flex: 1}} className='col-ac mt-80'>
                        <img alt={''} src={require('../img/cases/epic/LM2.png')} className='w-100p'/>
                    </div>
                </div>

                <div className='bg-img'>
                    <div className={this.props.mobile ? 'section-base-mobile col-ac pb-0' : 'section-base col-ac pb-0'}>
                        <div className='relative'>
                            <FadeInOnScroll delay={150}>
                                <div className='relative text-center mb-32 col-ac'>
                                    <h2 className='heading2 mt-0 mb-12 base-white-100'>
                                        Website Redesign
                                    </h2>
                                    <p className='body1 w-70p base-white-50'>
                                        And finally, a look at Scope's redesign of the old Epic website.
                                    </p>
                                </div>
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <div
                        className={this.props.mobile ? 'section-base-mobile col-plain-rev pt-0' : 'section-base row-ac pt-0'}>
                        <div style={{flex: 1, transform: 'scale(1.2)'}} className='col-ac'>
                            <img alt={''} src={require('../img/cases/epic/Before SL.png')} className='w-100p'/>
                        </div>
                        <div style={{flex: 1, transform: 'scale(1.2)'}} className='col-ac'>
                            <img alt={''} src={require('../img/cases/epic/After SL.png')} className='w-100p'/>
                        </div>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 mr-80'}>
                        <CaseStudyContentBlock content={{
                            title: 'New look & feel, mobile first',
                            text: "Our website overhaul provided a splash of color and a modernized feel that breathe life into the brand. We took care to ensure a quality experience for users at home or on the go."
                        }}/>
                    </div>
                    <div style={{flex: 1.2}}>
                        <video autoPlay
                               playsInline
                               loop muted className="splash-video"
                               style={{
                                   objectFit: 'cover',
                                   width: "100%",
                                   height: '100%',
                                   marginBottom: -6,
                                   // position: 'absolute',
                                   top: 0,
                                   left: 0,
                                   right: 0,
                                   bottom: 0,
                                   borderRadius: 12
                               }}>
                            <source src={require('../img/cases/epic/EpicWebsite2.mp4')}
                                    type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className='col-ac-jc' style={{padding: '10vh 0'}}>
                    <QuickLink
                        title={'View next case'}
                        sm
                        toRight
                        onClick={() => this.props.history.push('/work/recruit')}
                        mobile={this.props.mobile}
                    />
                </div>
                <div style={{paddingTop: '15vh', marginTop: '10vh', borderTop: '1px solid #00000033'}}>
                    <ContactUsComponent mobile={this.props.mobile}/>
                </div>
            </>
        )
    }
}

export default EpicCaseStudy
