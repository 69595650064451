import React, {Component} from 'react';
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import ReplyAll from "../icons/custom/ReplyAll";
import {Link} from "react-router-dom";

class ContactUsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            lastName: '',
            email: '',
            // selectedOption: '',
            feedback: '',
            hasSubmit: false,
            processing: false,
            content: ''

        }
    }

    render() {
        return (
            <section className='section page-h-padding' style={{
                paddingTop: this.props.mobile ? '5vh' : '10vh',
                paddingBottom: this.props.mobile ? '15vh' : '20vh'
            }}>
                <div className={`${this.props.mobile ? 'col-plain' : 'row-ac-jb'} relative`}>
                    <div className='col-plain'>
                        <FadeInOnScroll>
                            <h1 className={`heading1 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                            >
                                Let's Work<br/> Together
                            </h1>
                            <p className={` title1 ${this.props.darkBody ? 'base-white-100' : 'base-black-70'}`}>
                                Pitch a project &nbsp;&nbsp;/&nbsp;&nbsp; Get specific
                                help &nbsp;&nbsp;/&nbsp;&nbsp; Other
                                inquiries
                            </p>
                        </FadeInOnScroll>
                        <div className={this.props.mobile ? 'col-plain mt-80' : 'row-ac mt-80'}>
                            <FadeInOnScroll>
                                <Link to={'/contact'} className={`primary-button`}
                                      style={{height: 64, width: this.props.mobile && '100%',
                                      boxSizing:'border-box'}}>
                                    <div className={`my-super-cool-btn`} >
                                        <div className="dots-container">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                        <span className='transparent'>.</span>
                                    </div>
                                    <div className='relative'>
                                        {/*<ReplyAll style={{transform: 'rotate(180deg)'}}/>*/}
                                        <p className='title1 ml-20'>
                                           Get in touch
                                        </p>
                                    </div>
                                </Link>
                            </FadeInOnScroll>
                            {/*<FadeInOnScroll delay={300}>*/}
                            {/*    <div className='secondary-button' style={{*/}
                            {/*        height: 64,*/}
                            {/*        border: '3px solid black',*/}
                            {/*        marginTop: this.props.mobile && 12,*/}
                            {/*        marginLeft:!this.props.mobile && 20,*/}
                            {/*        width: this.props.mobile && '100%',*/}
                            {/*        boxSizing:'border-box'*/}
                            {/*    }}>*/}
                            {/*        <p className='title1 uppercase'>*/}
                            {/*            Ask A Question*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*</FadeInOnScroll>*/}
                        </div>
                    </div>
                    <div className={this.props.mobile && 'col-ae mt-12'}>
                        <FadeInOnScroll delay={350}>
                            <img src={require('../img/face.png')} style={{width: '30vw'}}/>
                        </FadeInOnScroll>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactUsComponent;
