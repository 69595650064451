import React from "react";
import {useDropzone} from "react-dropzone";
import DropboxFile from "./DropboxFile";
import SvgClose from "../icons/SvgClose";

function Dropzone({onDrop, accept, open, addedFiles, deleteFile}) {
    const {getRootProps, getInputProps, isDragActive, acceptedFiles} =
        useDropzone({
            accept,
            onDrop,
        });
    const zoneHeight = 44
    return (
        <div>
            <div {...getRootProps({className: "dropzone"})}
                 className={addedFiles.length > 0 ? 'file-drop-area mt-8 row-ac' : 'file-drop-area mt-8 col-ac-jc'}
                 style={{height: zoneHeight}}
            >
                <div onClick={open} className='w-100p'>
                    <input className="input-zone" {...getInputProps()} />
                    {addedFiles.length === 0 ?
                        <div className="text-center">
                            {isDragActive ? (
                                <p className="body3 base-black-50">
                                    Release to drop the files here
                                </p>
                            ) : (
                                <div className='row-ac-jc'>
                                    <p className="body3 base-black-50">
                                        Drop files here or click
                                    </p>
                                    <div className='body4 base-black-50 h-base-white-100 ml-4'
                                         style={{marginTop:2}}>
                                        <SvgClose style={{transform: "rotate(-45deg)"}} width={24} height={24}/>
                                    </div>
                                </div>
                            )}
                        </div> :
                        <div className='row-ac w-100p relative'>
                            {addedFiles.map(file =>
                                <DropboxFile file={file} key={file.name} deleteFile={deleteFile} className={'mr-8'}/>
                            )}
                            <div className='body4 base-black-50 h-base-white-100' style={{bottom: 16, right: 0}}>
                                <SvgClose style={{transform: "rotate(-45deg)"}} width={24} height={24}/>
                            </div>
                            <p className='body4 absolute base-black-50' style={{bottom: -12, right: 0}}>
                                {isDragActive ? 'Drop files here' : 'Drag more in if you want'}
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Dropzone;
