import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            d="M29.534 77.62a14.297 14.297 0 0 1 3.794-9.305 32.461 32.461 0 0 1 10.346-7.6 64.725 64.725 0 0 1 15.344-5.123 94.916 94.916 0 0 1 37.582 0 64.725 64.725 0 0 1 15.345 5.124 32.461 32.461 0 0 1 10.346 7.599 14.297 14.297 0 0 1 3.794 9.305 14.297 14.297 0 0 1-3.794 9.306 32.461 32.461 0 0 1-10.346 7.598A64.725 64.725 0 0 1 96.6 99.648a94.916 94.916 0 0 1-37.582 0 64.725 64.725 0 0 1-15.345-5.124 32.461 32.461 0 0 1-10.345-7.598 14.297 14.297 0 0 1-3.794-9.306Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M42.19 77.62a17.333 17.333 0 0 1 2.8-9.305 26.095 26.095 0 0 1 7.633-7.6 39.898 39.898 0 0 1 11.322-5.123 52.098 52.098 0 0 1 27.729 0 39.897 39.897 0 0 1 11.322 5.124 26.095 26.095 0 0 1 7.633 7.599 16.867 16.867 0 0 1 0 18.61 26.095 26.095 0 0 1-7.633 7.6 39.897 39.897 0 0 1-11.322 5.123 52.098 52.098 0 0 1-27.729 0 39.898 39.898 0 0 1-11.322-5.124 26.095 26.095 0 0 1-7.633-7.598 17.333 17.333 0 0 1-2.8-9.306Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M92.925 62.505a6.33 6.33 0 0 1 1.726 4.101 14.374 14.374 0 0 1-.86 5.619 28.66 28.66 0 0 1-3.2 6.409 40.804 40.804 0 0 1-5.296 6.471M62.694 92.736a6.33 6.33 0 0 1-1.726-4.102 14.374 14.374 0 0 1 .86-5.619 28.66 28.66 0 0 1 3.2-6.408 40.804 40.804 0 0 1 5.296-6.472"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <circle
            cx={77.809}
            cy={77.62}
            r={10.586}
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M62.694 62.505a6.33 6.33 0 0 1 4.101-1.726 14.374 14.374 0 0 1 5.62.86 28.66 28.66 0 0 1 6.408 3.2 40.805 40.805 0 0 1 6.472 5.296M92.925 92.736a6.33 6.33 0 0 1-4.102 1.725 14.374 14.374 0 0 1-5.618-.86 28.66 28.66 0 0 1-6.409-3.2 40.804 40.804 0 0 1-6.472-5.296"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <rect
            x={56.433}
            y={56.244}
            width={42.753}
            height={42.753}
            rx={15.269}
            transform="rotate(-45 77.81 77.62)"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
