import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64px"
            height="64px"
            {...props}
        >
            <path d="M33.03 0A1 1 0 0032 1v7H22c-5.365 0-9.687 4.278-9.916 9.588A1 1 0 0012 18v6a1 1 0 001 1c3.877 0 7 3.123 7 7 0 3.522-2.621 6.297-6 6.799V32a1 1 0 00-1-1H5a1 1 0 00-1 1c0 4.604 3.532 8.275 8 8.797V46c0 5.511 4.489 10 10 10h10v7a1 1 0 001.8.6l6-8A1 1 0 0039 54h-5v-5.178l9.127 5.809C43.458 57.624 45.922 60 49 60c3.302 0 6-2.698 6-6 0-2.947-2.19-5.301-5-5.797V35.797c2.81-.496 5-2.85 5-5.797 0-3.302-2.698-6-6-6-2.765 0-5.005 1.93-5.693 4.484l-9.828-5.361A1 1 0 0032 24v7h-5a1 1 0 100 2h5v7.125a1 1 0 001.805.594l6-8.125A1 1 0 0039 31h-5v-5.316l9.521 5.193A1 1 0 0045 30c0-2.22 1.78-4 4-4 2.22 0 4 1.78 4 4 0 2.22-1.78 4-4 4a1 1 0 00-1 1v14a1 1 0 001 1c2.22 0 4 1.78 4 4 0 2.22-1.78 4-4 4-2.22 0-4-1.78-4-4a1 1 0 00-.463-.844l-11-7A1 1 0 0032 47v7H22c-4.43 0-8-3.57-8-8v-5.203c4.468-.522 8-4.193 8-8.797 0-4.604-3.532-8.275-8-8.797V18c0-4.43 3.57-8 8-8h10v7a1 1 0 001.537.844l11-7A1 1 0 0045 10c0-2.22 1.78-4 4-4 2.22 0 4 1.78 4 4 0 2.22-1.78 4-4 4a1 1 0 100 2c3.302 0 6-2.698 6-6s-2.698-6-6-6c-3.078 0-5.542 2.376-5.873 5.37L34 15.177V10h5a1 1 0 00.707-1.707s-2.91-2.908-5.85-7.807A1 1 0 0033.03 0zM34 4.156C35.137 5.777 36.03 7.124 36.785 8H34V4.156zM6.201 33H12v5.799c-3.038-.451-5.348-2.76-5.799-5.799zM34 33h3.018L34 37.088V33zm0 23h3l-3 4v-4z" />
        </svg>
    )
}

export default SvgComponent
