import React, {Component} from "react";
import SvgArrowRight from "../icons/SvgArrowRight";

class ContactUsLink extends Component {
    state = {
        hovering: false,
    }
    onMouseEnter = () => {
        this.setState({hovering: true})
    }

    onMouseLeave = () => {
        this.setState({hovering: false})
    }

    render() {
        return (
            <a onMouseEnter={this.onMouseEnter}
               onMouseLeave={this.onMouseLeave}
               href={`mailto: ${this.props.email || 'hello'}@scopelabs.com`}
               className={`${this.props.darkbody ? 'base-white-100' : 'base-black-100'} col-plain pointer ${this.props.mobile ? 'body2-bold' : this.props.tablet ? 'body1-bold' : 'title2'}`}
               style={{float: "left"}}>
                <div className='row-ac'>
                    <SvgArrowRight style={{
                        transform: `rotate(${this.state.hovering ? '0deg' : '-45deg'})`,
                        transition: 'transform 300ms ease-in-out'
                    }} width={this.props.mobile ? 20 : 32}
                                   height={this.props.mobile ? 20 : 32} strokeWidth={2}/>
                    <p className={`m-0 ml-8 ${!this.props.email && 'pr-12'}`}>
                        {this.props.email || 'hello'}@scopelabs.com
                    </p>
                </div>
                <div className={this.props.darkbody ? 'bg-base-white-100' : `bg-base-black-100`} style={{
                    height: this.props.mobile ? 1.5 : 3,
                    width: this.state.hovering ? '100%' : '0%',
                    transition: "width 600ms ease-in-out"
                }}/>
            </a>
        )
    }
}

export default ContactUsLink
