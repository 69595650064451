import React, {Component} from 'react';
import FadeInOnScroll from './wrappers/FadeInOnScroll';
import {PAST_CLIENTS} from '../utils/dummy';

class PastClientsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stickyIndex: -1, // Initialize to -1 to ensure no card is initially sticky
        };
        this.cardRefs = PAST_CLIENTS.map(() => React.createRef()); // Create refs for each card
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.setCardPositions();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    setCardPositions() {
        // Calculate the top positions of each project card relative to the viewport
        this.cardPositions = this.cardRefs.map((ref) => ref.current.getBoundingClientRect().top + window.scrollY);
    }

    handleScroll = () => {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const visibleCardIndex = this.cardPositions.findIndex(
            (position) => scrollTop >= position && scrollTop < position + windowHeight
        );
        console.log(visibleCardIndex)
        this.setState({stickyIndex: visibleCardIndex + 1});
    };

    render() {
        const {stickyIndex} = this.state;

        return (
            <section
                className="section col-plain"
                style={{padding: this.props.mobile ? '15vh 0 0 0' : '15vh 0 0 0'}}
            >
                <FadeInOnScroll>
                    <p
                        className={`heading1 page-h-padding mt-0 mb-0 ${
                            this.props.darkBody ? 'base-white-100' : 'base-black-80'
                        }`}
                    >
                        Past 'Zero to One' Sprints
                    </p>
                </FadeInOnScroll>
                <div className="page-h-padding relative">
                    {PAST_CLIENTS.map((item, index) => (
                        <div
                            className={`pb-32 project-card row-jb pt-100`}
                            style={{
                                position: index <= stickyIndex ? 'sticky' : 'relative',
                                borderTop:index !== 0 && '1px solid #ffffff40',
                                top: 0,
                                backgroundColor: 'black',
                                transition:'opacity 300ms ease-in-out'
                            }}
                            key={index}
                            ref={this.cardRefs[index]}
                        >
                            <div className='mr-32' style={{width:400}}>
                                <p className='heading3 mt-0 base-white-100 mb-0'>
                                    {item.client}
                                </p>
                                <p className='title1 base-white-100' style={{fontWeight:400}}>
                                    {item.description}
                                </p>
                                {item.tags?.length > 0 &&
                                    <div className={this.props.mobile ? 'row-ac mt-8' : 'row-ac wrap mt-8'}>
                                        {item.tags.map(tag =>
                                            <div key={tag} className='body4-bold uppercase ph-12 pv-4 mr-8 bg-base-white-20 base-white-100 mb-8 gallery-tag'
                                                // style={{border: `1px solid white`}}
                                            >
                                                {tag}
                                            </div>
                                        )}
                                    </div>
                                }
                                <button className='secondary-button body1-bold mt-32'>
                                    View Case Study
                                </button>
                            </div>
                            <img
                                src={item.cover}
                                className="w-100p"
                                style={{height: '70vh', objectFit: 'cover'}}
                                alt={`Project ${index}`}
                            />
                        </div>
                    ))}
                </div>
            </section>
        );
    }
}

export default PastClientsSection;
