import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            d="M42.474 77.897c12.44.946 20.172 1.836 25.145 4.362-5.963 3.582-7.187 10.436-8.567 25.434-2.347-25.535-4.25-27.448-29.795-29.796 25.545-2.347 27.448-4.261 29.795-29.795 1.38 14.998 2.604 21.851 8.567 25.434-4.973 2.525-12.706 3.415-25.145 4.361ZM125.808 77.897c-25.534 2.348-27.448 4.261-29.796 29.796-1.38-14.998-2.603-21.852-8.566-25.434 4.973-2.526 12.705-3.416 25.144-4.362-12.439-.946-20.171-1.836-25.144-4.361 5.963-3.583 7.187-10.436 8.566-25.434 2.348 25.534 4.262 27.448 29.796 29.795Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinejoin="bevel"
            strokeWidth={2.759}
        />
        <path
            d="M112.59 77.897c-12.439.946-20.171 1.836-25.144 4.362-7.054 3.593-8.534 10.47-9.914 25.434-1.38-14.965-2.86-21.84-9.913-25.434-4.973-2.526-12.706-3.416-25.145-4.362 12.44-.946 20.172-1.836 25.145-4.361 7.054-3.594 8.534-10.47 9.913-25.434 1.38 14.964 2.86 21.84 9.914 25.434 4.973 2.525 12.705 3.415 25.144 4.361Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinejoin="bevel"
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
