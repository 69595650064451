import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            d="M76.424 102.58c-13.326 0-48.276-24.122-48.276-24.128s34.95-24.13 48.276-24.13 48.275 24.123 48.275 24.13-34.95 24.128-48.275 24.128Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.759}
        />
        <path
            d="M124.7 78.452h0c0 .002-27.828 8.22-32.368 8.22H60.516c-4.54 0-32.368-8.218-32.368-8.22h0c0-.003 27.827-8.222 32.368-8.222h31.816c4.54 0 32.367 8.22 32.367 8.222Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.759}
        />
        <path
            d="M65.296 99.854c-8.064 33.463-8.064-76.268 0-42.805M87.543 57.049c8.055-33.42 8.055 76.225 0 42.805"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
