import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M80 896V256h800v640H80zm400-302L140 371v465h680V371L480 594zm0-60l336-218H145l335 218zM140 371v-55 55z" />
        </svg>
    )
}

export default SvgComponent
