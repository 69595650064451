import React, {Component} from 'react';

class AnimatedStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGreen: false,
            isDark:false
        };
        this.stepRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll(); // Call this to check the initial state

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', this.handleScroll);
        };
    }

    handleScroll = () => {
        if (this.stepRef.current) {
            const rect = this.stepRef.current.getBoundingClientRect();
            const middle = (rect.top + rect.bottom) / 2;

            // Calculate the viewport height
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            // Adjust this threshold value to determine when to change the color
            const threshold = 0.65;
            const threshold2 = 0.8;
            const threshold3 = 0.2;
            const isGreen = middle < threshold * viewportHeight;
            const isDark = middle > threshold2 * viewportHeight ;
            // const isDark = middle > threshold2 * viewportHeight || middle < threshold3 * viewportHeight;
            this.setState({isGreen, isDark});
        }
    };

    render() {
        const {step, darkBody} = this.props;
        const {isGreen} = this.state;

        const textColorClass = isGreen ? 'green-text' : darkBody ? 'base-white-100' : 'base-black-80';

        return (
            <div ref={this.stepRef} className={`animated-step ${darkBody ? 'base-white-100' : 'base-black-80'}`} style={{opacity:this.state.isDark && .2, transition:"350ms ease-in-out"}}>
                <p className={`heading1 mb-0`}>{step.label}</p>
                <div className='relative'>
                    <p className={`${this.props.mobile ? 'heading3' : 'heading2'} mt-0 mb-0`}>{step.title}</p>
                    <div className={`highlight absolute`}
                         style={{
                             opacity: this.state.isGreen ? .5 : 0,
                             marginLeft: -20,
                             left: 0,
                             width: isGreen ? '100%' : '0%',
                             top: 0,
                             bottom: 0,
                             transition: "all 350ms ease-in-out"
                         }}/>
                </div>
                <p className={`${this.props.mobile ? 'body1 mt-12' : 'title1 mt-32'} mb-0`} style={{fontWeight: '400'}}>
                    {step.description}
                </p>
            </div>
        );
    }
}

export default AnimatedStep;
