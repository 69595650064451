import React, {Component} from "react";
import {FULL_CAPABILITIES, MOBILE_CAPABILITY_ICONS} from "../utils/dummy";
import CapabilitySectionBlock from "./CapabilitySectionBlock";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import CapabilitiesSectionTile from "./CapabilitiesSectionTile";

class CapabilitiesSection extends Component {
    state = {
        selectedIndex: null
    }

    selectIndex = (selectedIndex) => {
        this.setState({selectedIndex})
    }

    deselectIndex = () => {
        this.setState({selectedIndex: null})
    }

    render() {
        return (
            <div>
                <div className='page-h-padding' style={{
                    // borderTop: `1px solid #${this.props.darkBody ? 'ffffff' : '000000'}10`,
                    paddingTop: this.props.mobile ? '15vh' : "20vh",
                }}>
                    <div className={this.props.mobile ? 'col-plain mb-40' : 'col-plain mb-60'}>
                        <FadeInOnScroll>
                            <h1 className={`heading2 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                                Capabilities
                            </h1>
                        </FadeInOnScroll>
                        <p className='title1 mb-8' style={{width: !this.props.mobile && '70vw'}}>
                            Some of what we excel at. As technology changes, so do we. We are constantly expanding our
                            capabilities and learning the best ways to grow your business.
                        </p>
                    </div>
                    <div className='row-plain wrap'
                         style={{
                             display: 'grid',
                             gridTemplateColumns: this.props.mobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)',
                             gridGap: '20px'
                         }}>
                        {FULL_CAPABILITIES.map((group, index) =>
                            <CapabilitiesSectionTile
                                item={group}
                                index={index}
                                mobile={this.props.mobile}
                            />
                        )}
                    </div>
                </div>
                {/*<div className=' row-plain mt-32'*/}
                {/*     style={{*/}
                {/*         borderColor: this.props.darkBody && '#ffffff40', flexWrap: "wrap",*/}
                {/*         borderTopWidth: 0,*/}
                {/*         minHeight: this.props.tablet ? "80vh" : '68vh',*/}
                {/*         alignItems: "stretch",*/}
                {/*         // backgroundColor:'red',*/}
                {/*         borderBottom: `1px solid #${this.props.darkBody ? 'ffffff' : '000000'}20`,*/}
                {/*     }}>*/}
                {/*    <div className='flex-1 col-plain'>*/}
                {/*        {FULL_CAPABILITIES.map((group, i) => {*/}
                {/*                if (i % 2 === 0) {*/}
                {/*                    return (*/}
                {/*                        <CapabilitySectionBlock*/}
                {/*                            i={i}*/}
                {/*                            selectedIndex={this.state.selectedIndex}*/}
                {/*                            darkBody={this.props.darkBody}*/}
                {/*                            length={FULL_CAPABILITIES.length}*/}
                {/*                            group={group}*/}
                {/*                            key={group.title + i}*/}
                {/*                            selectIndex={this.selectIndex}*/}
                {/*                            deselectIndex={this.deselectIndex}*/}
                {/*                            tablet={this.props.tablet}*/}
                {/*                            mobile={this.props.mobile}*/}
                {/*                        />*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            }*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*    <div className='flex-1 col-plain'>*/}
                {/*        {FULL_CAPABILITIES.map((group, i) => {*/}
                {/*                if (i % 2 === 1) {*/}
                {/*                    return (*/}
                {/*                        <CapabilitySectionBlock*/}
                {/*                            i={i}*/}
                {/*                            selectedIndex={this.state.selectedIndex}*/}
                {/*                            darkBody={this.props.darkBody}*/}
                {/*                            length={FULL_CAPABILITIES.length}*/}
                {/*                            group={group}*/}
                {/*                            key={group.title + i}*/}
                {/*                            selectIndex={this.selectIndex}*/}
                {/*                            deselectIndex={this.deselectIndex}*/}
                {/*                            tablet={this.props.tablet}*/}
                {/*                            mobile={this.props.mobile}*/}
                {/*                        />*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            }*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*{!this.props.mobile &&*/}
                {/*    <div*/}
                {/*        className={`row-ac-ja ${this.props.mobile ? 'mt-12' : 'mt-40'} page-h-padding ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}*/}
                {/*        style={{flexWrap: 'wrap'}}*/}
                {/*    >*/}
                {/*        {FULL_CAPABILITIES.map((group, i) =>*/}
                {/*            <FadeInOnScroll key={group} delay={i * 300} key={group.title}>*/}
                {/*                <div*/}
                {/*                    onMouseEnter={() => this.selectIndex(i)}*/}
                {/*                    onMouseLeave={this.deselectIndex}*/}
                {/*                    style={{*/}
                {/*                        flex: 1,*/}
                {/*                        transform: this.props.mobile ? 'scale(0.8)' : this.props.tablet ? 'scale(1.2)' : 'scale(1.8)',*/}
                {/*                        opacity: this.state.selectedIndex === i ? 1 : (this.props.darkBody ? .15 : .1),*/}
                {/*                        transition: "opacity 300ms ease-in-out"*/}
                {/*                    }}>*/}
                {/*                    {group.icon}*/}
                {/*                </div>*/}
                {/*            </FadeInOnScroll>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*}*/}
                {/*{this.props.mobile &&*/}
                {/*    <div className='ph-20'>*/}
                {/*        <div*/}
                {/*            className={`row-ac-ja ${this.props.mobile ? 'mt-12' : 'mt-40'}  ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}*/}
                {/*            // style={{flexWrap: 'wrap'}}*/}
                {/*        >*/}
                {/*            {MOBILE_CAPABILITY_ICONS.map((group, i) =>*/}
                {/*                <FadeInOnScroll key={group} delay={i * 300} key={group.title}>*/}
                {/*                    <div*/}
                {/*                        onMouseEnter={() => this.selectIndex(i)}*/}
                {/*                        onMouseLeave={this.deselectIndex}*/}
                {/*                        style={{*/}
                {/*                            flex: 1,*/}
                {/*                            marginRight:(i === 1 || i ===2) && 10,*/}
                {/*                            transform: this.props.mobile ? 'scale(0.8)' : this.props.tablet ? 'scale(1.2)' : 'scale(1.8)',*/}
                {/*                            opacity: this.state.selectedIndex === i ? 1 : (this.props.darkBody ? .15 : .1),*/}
                {/*                            transition: "opacity 300ms ease-in-out"*/}
                {/*                        }}>*/}
                {/*                        {group.icon}*/}
                {/*                    </div>*/}
                {/*                </FadeInOnScroll>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
        )
    }
}

export default CapabilitiesSection
