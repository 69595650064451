import React, {Component} from "react";
import {MVP_STEPS, WE_BUILD, WE_KNOW, WHY_SCOPE} from "../utils/dummy";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";

class WeBuildSection extends Component {
    state = {
        selectedIndex: null
    }

    selectIndex = (selectedIndex) => {
        this.setState({selectedIndex})
    }

    deselectIndex = () => {
        this.setState({selectedIndex: null})
    }

    render() {
        return (
            <div className=''>
                <div className='row-plain wrap page-h-padding  relative'
                     style={{paddingBottom: '0vh'}}>
                    {/*<h1 className={`heading1 row-plain mt-0 mb-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}*/}
                    {/*    style={{fontSize: 80}}*/}
                    {/*>*/}
                    {/*    {[...'We build'].map((char, charIndex) => (*/}
                    {/*        <FadeInOnScroll key={charIndex} triggerImmediately*/}
                    {/*                        delay={50 * (charIndex + 1) + charIndex * 10}>*/}
                    {/*            {char === ' ' ? '\u00A0' : char}*/}
                    {/*        </FadeInOnScroll>*/}
                    {/*    ))}*/}
                    {/*    &nbsp;*/}
                    {/*</h1>*/}
                    {/*{WE_BUILD.map((item, index) => (*/}
                    {/*    <div*/}
                    {/*        key={index}*/}
                    {/*        className={`heading1 text-with-stroke m-0 mv-0 row-ac ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}*/}
                    {/*        style={{fontSize: 80}}*/}
                    {/*    >*/}
                    {/*        {[...item].map((char, charIndex) => (*/}
                    {/*            <FadeInOnScroll key={charIndex} triggerImmediately*/}
                    {/*                            delay={80 * index + 10 + charIndex * 10}>*/}
                    {/*                {char}*/}
                    {/*            </FadeInOnScroll>*/}
                    {/*        ))}*/}
                    {/*        {index === WE_BUILD.length - 2 ? (*/}
                    {/*            <>*/}
                    {/*                {[...' ,  and '].map((char, charIndex) => (*/}
                    {/*                    <FadeInOnScroll triggerImmediately key={charIndex}*/}
                    {/*                                    delay={180 * (index + 1) + charIndex * 10}>*/}
                    {/*                        {char}*/}
                    {/*                    </FadeInOnScroll>*/}
                    {/*                ))}*/}
                    {/*                &nbsp;*/}
                    {/*            </>*/}
                    {/*        ) : index === WE_BUILD.length - 1 ? (*/}
                    {/*            <>*/}
                    {/*                <FadeInOnScroll delay={180 * index + 10} triggerImmediately>*/}
                    {/*                    .*/}
                    {/*                </FadeInOnScroll>*/}
                    {/*            </>*/}
                    {/*        ) : (*/}
                    {/*            <FadeInOnScroll delay={180 * index + 10} triggerImmediately>*/}
                    {/*                ,&nbsp;*/}
                    {/*            </FadeInOnScroll>*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </div>

                {/*<div className='row-plain wrap page-h-padding relative'*/}
                {/*     style={{paddingTop: '10vh', paddingBottom: '20vh', maxWidth:'70vw'}}>*/}
                {/*    <img className='absolute rotate' src={require('../img/Holographic 3D shape 74 (1).png')}*/}
                {/*         style={{left: '10vw', width: 600, bottom: -200, opacity: .2, zIndex: -1}}/>*/}
                {/*    <h1 className={`heading1 mt-0 mb-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}*/}
                {/*    >*/}
                {/*        And we know&nbsp;*/}
                {/*    </h1>*/}
                {/*    {WE_KNOW.map((item, index) => (*/}
                {/*        <div*/}
                {/*            className={`heading1 text-with-stroke m-0 mv-0 row-ac ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}*/}
                {/*        >*/}
                {/*            {[...item].map((char, charIndex) => (*/}
                {/*                <FadeInOnScroll key={charIndex} delay={180 * index + 10 + charIndex * 10}>*/}
                {/*                    {char}*/}
                {/*                </FadeInOnScroll>*/}
                {/*            ))}*/}
                {/*            {index === WE_KNOW.length - 1 ? (*/}
                {/*                <FadeInOnScroll delay={180 * index + 10 + item.length * 10}>*/}
                {/*                    .*/}
                {/*                </FadeInOnScroll>*/}
                {/*            ) : (*/}
                {/*                <FadeInOnScroll delay={180 * index + 10 + item.length * 10}>*/}
                {/*                    ,*/}
                {/*                </FadeInOnScroll>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default WeBuildSection
