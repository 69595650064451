import React, {Component} from "react";
import {FAQS} from "../utils/dummy";
import CollapseComponent from "./CollapseComponent";
import SvgChevronForward from "../icons/SvgChevronForward";

class FAQItem extends Component {
    state = {
        collapseOpen: false,
    };

    render() {
        const item = this.props.item;
        const {collapseOpen} = this.state;

        return (
            <div style={{maxWidth: 1000}}
                 onMouseEnter={() => this.setState({collapseOpen: true})}
                 onMouseLeave={() => this.setState({collapseOpen: false})}
            >
                <div className="pointer">
                    <p
                        className={`heading3 ${
                            this.props.darkBody ? "base-white-100" : "base-black-80"
                        }`}
                    >
                        <span
                            className={`${this.props.darkBody ? 'base-white-30' : 'base-black-30'}`}>0{this.props.index + 1} </span>{" "}
                        {item.title}
                        <span>
                            {this.props.mobile ?
                                <SvgChevronForward
                                    className={` mt-4 ml-12 ${this.props.darkBody ? (this.state.collapseOpen ? 'base-white-100' : 'base-white-30') : 'base-black-30'}`}
                                    style={{
                                        width: 16,
                                        height: 16,
                                        transition: '300ms ease-in-out',
                                        transform: `rotate(${this.state.collapseOpen ? '90deg' : '0deg'})`
                                    }}/>
                                :
                                <SvgChevronForward style={{
                                    width: this.props.tablet ? 28 : 36,
                                    height: this.props.tablet ? 28 : 36,
                                    transition: '300ms ease-in-out',
                                    transform: `rotate(${this.state.collapseOpen ? '90deg' : '0deg'})`
                                }}
                                                   className={` ml-12 ${this.props.darkBody ? (this.state.collapseOpen ? 'base-white-100' : 'base-white-30') : 'base-black-30'}`}/>
                            }
                        </span>
                    </p>
                </div>
                <CollapseComponent isOpen={this.state.collapseOpen}>
                    <p
                        className={`title1 mt-0 ${
                            this.props.darkBody ? "base-white-100" : "base-black-80"
                        }`}
                        style={{
                            fontWeight: 400,
                            opacity: collapseOpen ? 1 : 0,
                            transform: `translateY(${collapseOpen ? 0 : "1rem"})`, // Apply translation based on state
                            transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out", // Add transitions for opacity and transform
                        }}
                    >
                        {item.description}
                    </p>
                </CollapseComponent>
            </div>
        );
    }
}

export default FAQItem;
