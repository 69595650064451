import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
        <path
            d="M38.594 70.8a20.536 20.536 0 0 1-19.58-16.069 20.536 20.536 0 0 1 19.58-24.994c-3.995.218-7.52 3.298-9.8 7.975a26.983 26.983 0 0 0-2.442 8.364 32.4 32.4 0 0 0 0 8.384 27.138 27.138 0 0 0 2.443 8.374c2.28 4.676 5.804 7.747 9.8 7.965ZM64.815 70.808h-.045a1.24 1.24 0 0 1-.182 0 .26.26 0 0 1-.081-.009c-3.86-.21-7.284-3.088-9.564-7.51a7.298 7.298 0 0 1-.236-.455 26.988 26.988 0 0 1-2.443-8.356v-.018c-.119-.926-.2-1.88-.237-2.853-.026-.445-.036-.89-.036-1.334 0-.454.01-.899.036-1.335a35.986 35.986 0 0 0-.245-2.862v-.017a27.1 27.1 0 0 0-2.443-8.346 6.19 6.19 0 0 0-.245-.465c-2.27-4.414-5.704-7.293-9.555-7.511a20.238 20.238 0 0 1 12.48 4.596 20.563 20.563 0 0 1 2.733 2.67c.064.081.137.163.19.245a5.836 5.836 0 0 1 .418.526 20.432 20.432 0 0 1 3.761 8.03l.082.409a20.203 20.203 0 0 1 .363 2.789c-.018.416-.018.844-.018 1.271 0 .426 0 .845.018 1.262.01.945.036 1.871.09 2.78a58.276 58.276 0 0 0 .945 8.083c.954 4.94 2.48 8.21 4.214 8.41ZM52.018 66.203a20.238 20.238 0 0 1-12.479 4.596c3.851-.21 7.285-3.088 9.564-7.502.064.082.127.164.19.236a20.497 20.497 0 0 0 2.725 2.67Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M49.103 63.297c-2.28 4.414-5.713 7.293-9.564 7.502a.255.255 0 0 1-.082.009 1.111 1.111 0 0 1-.172 0h-.036a1.86 1.86 0 0 0 .3-.073c.036-.018.081-.026.118-.046a1.648 1.648 0 0 0 .354-.19 1.016 1.016 0 0 0 .154-.119 2.276 2.276 0 0 0 .354-.326 2.802 2.802 0 0 0 .3-.355 3.208 3.208 0 0 0 .209-.29c.082-.118.163-.245.236-.373a11.63 11.63 0 0 0 .854-1.817c.09-.227.182-.471.264-.725.154-.445.3-.917.435-1.426.227-.818.436-1.717.618-2.67a61.136 61.136 0 0 0 .944-8.084c.027-.527.046-1.053.055-1.59.009-.19.018-.39.018-.59.01-.18.01-.355.01-.536a17.859 17.859 0 0 0 .371 2.725l.083.408a20.38 20.38 0 0 0 3.75 8.04c.136.18.281.352.427.526ZM38.894 70.808h-.045a1.111 1.111 0 0 1-.172 0 .263.263 0 0 1-.083-.009c-3.995-.218-7.52-3.289-9.8-7.965a27.138 27.138 0 0 1-2.442-8.374 32.4 32.4 0 0 1 0-8.384 26.983 26.983 0 0 1 2.443-8.364c2.28-4.677 5.804-7.757 9.8-7.975a.263.263 0 0 1 .082-.009 1.111 1.111 0 0 1 .172 0 1.368 1.368 0 0 0-.272.073c-.019.009-.037.009-.055.018a1.644 1.644 0 0 0-.281.136c-.046.027-.09.055-.127.082a.68.68 0 0 0-.128.1.171.171 0 0 0-.09.072 2.905 2.905 0 0 0-.255.227.11.11 0 0 1-.036.037c-.082.09-.172.19-.255.3-.1.128-.19.254-.29.4-.091.145-.19.299-.282.462a.698.698 0 0 1-.063.11c-.064.126-.137.263-.21.408a7.14 7.14 0 0 0-.263.582c-.09.199-.172.408-.254.626q-.123.327-.245.681a23.598 23.598 0 0 0-.663 2.326 28.95 28.95 0 0 0-.39 1.77 58.288 58.288 0 0 0-.945 8.084 73.127 73.127 0 0 0-.11 4.051c0 1.38.037 2.734.11 4.041a58.29 58.29 0 0 0 .944 8.084c.954 4.94 2.48 8.21 4.205 8.41Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M42.827 65.068c-.136.509-.281.98-.436 1.426.146-.445.29-.917.436-1.426ZM64.507 70.8a20.263 20.263 0 0 1-12.489-4.597 20.497 20.497 0 0 1-2.724-2.67c-.064-.072-.127-.154-.191-.236-.146-.174-.29-.346-.427-.527a20.38 20.38 0 0 1-3.75-8.039l-.083-.408a17.859 17.859 0 0 1-.372-2.725v-.19a15.479 15.479 0 0 1-.036-1.135c0-.381.009-.764.036-1.146v-.18c0-.182 0-.355-.01-.536 0-.2 0-.408-.017-.609q-.013-.803-.055-1.58a61.134 61.134 0 0 0-.944-8.084 37.452 37.452 0 0 0-.391-1.77c-.1-.428-.2-.827-.318-1.217a20.79 20.79 0 0 0-.59-1.79 9.24 9.24 0 0 0-.255-.626c-.054-.12-.1-.227-.154-.337a5.896 5.896 0 0 0-1.562-2.242 2.298 2.298 0 0 0-.154-.12 1.644 1.644 0 0 0-.354-.19c-.037-.019-.082-.027-.118-.045a1.87 1.87 0 0 0-.3-.073h.036a1.111 1.111 0 0 1 .172 0 .255.255 0 0 1 .082.009c3.851.218 7.284 3.097 9.555 7.51.09.157.172.311.245.465a27.1 27.1 0 0 1 2.443 8.347v.017c.118.935.2 1.88.245 2.862a21.98 21.98 0 0 0-.036 1.335c0 .444.01.89.036 1.334.037.972.118 1.927.237 2.853v.017a26.988 26.988 0 0 0 2.443 8.357c.072.155.154.309.236.454 2.28 4.423 5.704 7.302 9.564 7.511Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M42.827 65.068c-.136.509-.281.98-.436 1.426.146-.445.29-.917.436-1.426Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M44.471 48.946v.181c-.027.382-.036.765-.036 1.146 0 .382.009.763.036 1.136v.19c0 .18 0 .354-.01.535 0 .2-.008.4-.017.59a44.461 44.461 0 0 1-.055 1.59 61.136 61.136 0 0 1-.944 8.084 33.588 33.588 0 0 1-.618 2.67c-.146.509-.29.98-.436 1.426-.081.253-.173.498-.263.725a11.63 11.63 0 0 1-.854 1.817 4.88 4.88 0 0 1-.236.372 3.208 3.208 0 0 1-.21.291 4.042 4.042 0 0 1-.3.355 2.276 2.276 0 0 1-.353.326 1.016 1.016 0 0 1-.154.12 1.648 1.648 0 0 1-.354.19c-.037.019-.082.027-.118.045a1.86 1.86 0 0 1-.3.073 1.77 1.77 0 0 1-.355 0c-1.725-.2-3.251-3.47-4.205-8.41a58.29 58.29 0 0 1-.945-8.084 73.023 73.023 0 0 1-.109-4.04c0-1.391.037-2.745.11-4.052a58.288 58.288 0 0 1 .944-8.084 28.97 28.97 0 0 1 .39-1.77 23.598 23.598 0 0 1 .664-2.326q.122-.354.245-.68c.082-.22.164-.429.254-.627.082-.21.173-.4.264-.582.072-.145.145-.282.209-.408a.698.698 0 0 0 .063-.11c.091-.163.19-.317.282-.463.1-.145.19-.271.29-.4.082-.11.173-.208.254-.299a.11.11 0 0 0 .037-.037 2.905 2.905 0 0 1 .254-.227.171.171 0 0 1 .09-.071.68.68 0 0 1 .129-.101c.036-.027.081-.055.126-.082a1.644 1.644 0 0 1 .282-.136c.018-.01.036-.01.055-.018a1.368 1.368 0 0 1 .272-.073h.045a1.77 1.77 0 0 1 .355 0 1.87 1.87 0 0 1 .3.073c.036.018.081.026.118.046a1.644 1.644 0 0 1 .354.19c.054.037.109.081.154.119a5.896 5.896 0 0 1 1.562 2.242c.055.11.1.218.154.337.092.198.173.408.255.626a20.79 20.79 0 0 1 .59 1.79c.118.39.219.79.318 1.217.136.562.272 1.153.391 1.77a61.134 61.134 0 0 1 .944 8.084q.04.777.055 1.58c.018.2.018.408.018.609.01.18.01.355.01.535ZM85.523 50.273A20.536 20.536 0 0 1 65.45 70.8c3.987-.218 7.512-3.289 9.8-7.965a27.384 27.384 0 0 0 2.444-8.374 32.401 32.401 0 0 0 0-8.384 27.227 27.227 0 0 0-2.444-8.364c-2.288-4.677-5.813-7.757-9.8-7.975a20.536 20.536 0 0 1 19.581 16.068 20.684 20.684 0 0 1 .49 4.468ZM64.507 29.737c-3.86.218-7.284 3.097-9.564 7.51-.054-.08-.127-.162-.191-.244a20.563 20.563 0 0 0-2.734-2.67 20.296 20.296 0 0 1 12.489-4.596Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M64.77 29.728c-.028.009-.064.009-.09.018a1.897 1.897 0 0 0-.655.29 1.26 1.26 0 0 0-.127.102.432.432 0 0 0-.081.053 1.721 1.721 0 0 0-.128.119.88.88 0 0 0-.127.126c-.018.02-.036.037-.054.055a.24.24 0 0 0-.064.073 3.373 3.373 0 0 0-.227.273A9.68 9.68 0 0 0 61.9 33.36q-.122.327-.245.681c-.055.146-.1.291-.145.445-.055.155-.1.309-.146.472-.127.428-.254.882-.363 1.354-.063.236-.118.48-.172.727a28.8 28.8 0 0 0-.228 1.098 58.274 58.274 0 0 0-.944 8.084 54.527 54.527 0 0 0-.09 2.78 20.203 20.203 0 0 0-.364-2.79l-.082-.407a20.432 20.432 0 0 0-3.76-8.03 5.836 5.836 0 0 0-.418-.527c2.28-4.414 5.704-7.293 9.564-7.511a.26.26 0 0 1 .081-.009 1.24 1.24 0 0 1 .182 0ZM77.967 50.273a33.182 33.182 0 0 1-.272 4.187 27.384 27.384 0 0 1-2.444 8.374c-2.288 4.676-5.813 7.747-9.8 7.965a.258.258 0 0 1-.081.009 1.24 1.24 0 0 1-.182 0h-.045a1.442 1.442 0 0 0 .327-.073 2.259 2.259 0 0 0 .335-.154.974.974 0 0 0 .128-.082 1.261 1.261 0 0 0 .127-.1 3.5 3.5 0 0 0 .7-.717c.081-.1.154-.21.226-.318a9.247 9.247 0 0 0 .481-.844c.092-.183.182-.373.273-.582.063-.146.127-.291.191-.445.045-.118.1-.245.145-.373.055-.128.1-.263.145-.4.036-.09.064-.171.091-.263a.395.395 0 0 0 .037-.09c.045-.164.1-.327.154-.5.063-.2.127-.41.181-.618.036-.118.073-.245.1-.371.064-.21.118-.428.173-.655.037-.145.073-.29.11-.445.099-.445.199-.908.29-1.38a61.111 61.111 0 0 0 .944-8.084c.064-1.307.1-2.66.1-4.04 0-1.391-.036-2.745-.1-4.052a61.11 61.11 0 0 0-.945-8.084c-.09-.472-.19-.935-.29-1.38q-.178-.777-.382-1.47c-.054-.21-.118-.42-.18-.62-.065-.216-.137-.425-.201-.626q-.123-.354-.245-.68c-.045-.11-.082-.22-.127-.318-.064-.155-.128-.3-.19-.446a5.83 5.83 0 0 0-.274-.582 9.223 9.223 0 0 0-.48-.844c-.073-.108-.155-.218-.228-.317a3.503 3.503 0 0 0-.7-.717 2.183 2.183 0 0 0-.254-.183 2.25 2.25 0 0 0-.335-.154 1.436 1.436 0 0 0-.164-.046l-.118-.027a1.24 1.24 0 0 1 .182 0 .258.258 0 0 1 .081.009c3.987.218 7.512 3.298 9.8 7.975a27.227 27.227 0 0 1 2.444 8.364 33.22 33.22 0 0 1 .272 4.197Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
        <path
            d="M70.401 50.273c0 1.38-.036 2.734-.1 4.041a61.111 61.111 0 0 1-.945 8.084c-.09.472-.19.935-.29 1.38-.036.154-.072.3-.109.445-.055.227-.109.446-.173.655a4.503 4.503 0 0 1-.1.37c-.054.21-.118.42-.18.62-.056.172-.11.335-.155.498a.395.395 0 0 1-.036.09 2.915 2.915 0 0 1-.092.265c-.045.136-.09.271-.145.4-.046.127-.1.254-.145.372-.064.154-.128.3-.19.445-.092.21-.182.399-.274.582a9.247 9.247 0 0 1-.48.844c-.073.108-.146.218-.228.318a3.5 3.5 0 0 1-.7.716 1.261 1.261 0 0 1-.126.101.974.974 0 0 1-.128.082 2.259 2.259 0 0 1-.335.154 1.442 1.442 0 0 1-.327.073 1.51 1.51 0 0 1-.328 0c-1.735-.2-3.26-3.47-4.214-8.41a58.276 58.276 0 0 1-.944-8.084 54.53 54.53 0 0 1-.09-2.78c-.02-.416-.02-.835-.02-1.26 0-.428 0-.856.02-1.273.008-.945.035-1.871.09-2.78a58.274 58.274 0 0 1 .944-8.083c.073-.381.146-.745.227-1.098.054-.247.11-.491.172-.727.11-.472.237-.926.364-1.354.045-.163.09-.317.146-.472.045-.154.09-.3.145-.445q.123-.354.245-.68a9.68 9.68 0 0 1 1.317-2.525 3.373 3.373 0 0 1 .227-.273.24.24 0 0 1 .064-.073l.054-.055a.88.88 0 0 1 .127-.126 1.721 1.721 0 0 1 .127-.119.432.432 0 0 1 .082-.053 1.26 1.26 0 0 1 .127-.101 1.897 1.897 0 0 1 .654-.291c.027-.009.063-.009.09-.018h.046a1.51 1.51 0 0 1 .327 0h.046l.118.027a1.436 1.436 0 0 1 .164.046 2.25 2.25 0 0 1 .335.154 2.183 2.183 0 0 1 .255.183 3.503 3.503 0 0 1 .7.717c.072.099.154.209.226.317a9.223 9.223 0 0 1 .481.844c.091.183.182.373.273.582.063.146.127.291.191.445.045.1.082.21.127.318q.122.327.245.681c.064.2.136.41.2.626.063.2.127.41.181.62q.205.694.382 1.47c.1.445.2.908.29 1.38a61.11 61.11 0 0 1 .945 8.084c.064 1.307.1 2.66.1 4.051Z"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.584}
        />
    </svg>
)

export default SvgComponent
