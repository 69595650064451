import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import {zero_to_one_highlights} from "../utils/dummy";

class VericalHero extends Component {
    render() {
        return (
            <div className='' style={{overflow: "hidden", width: '100vw', paddingTop: '28vh', paddingBottom: '10vh'}}>
                <div className={this.props.mobile ? 'page-h-padding w-90p' : 'page-h-padding w-50p'}
                     style={{
                         width: !this.props.mobile && this.props.width
                     }}
                >
                    <div className={`relative ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                        <FadeInOnScroll delay={200} triggerImmediately>
                            <h1 className={`heading1 mt-0 mb-0`}
                                style={{zIndex: 2, display:'none'}}>
                                {this.props.name}
                            </h1>
                            <h2 className={`heading1 mt-0 mb-0`}
                                style={{zIndex: 2}}>
                                {this.props.header}
                            </h2>
                        </FadeInOnScroll>
                        <FadeInOnScroll delay={400} triggerImmediately>
                            <div className='row-ac'>
                                <p className='title1'>
                                    {this.props.description}
                                </p>
                            </div>
                        </FadeInOnScroll>
                        {this.props.logo &&
                            <div className='absolute' style={{top: -60, right: 0}}>
                                <FadeInOnScroll delay={800} triggerImmediately>
                                    <img style={{height: 120, opacity: this.props.darkBody ? .1 : .07}}
                                         src={this.props.logo}/>
                                </FadeInOnScroll>
                            </div>
                        }
                    </div>
                </div>
                <div style={{paddingBottom: this.props.mobile ? 0 : '5vh'}} className='relative page-h-padding'>
                    <img className='absolute rotate-rev-slow' src={this.props.bgImg}
                         style={{
                             right: this.props.mobile ? -120 : 0,
                             width: this.props.mobile ? 400 : 600,
                             bottom: this.props.mobile ? 20 : -100,
                             opacity: .3,
                             zIndex: -1
                         }}/>
                </div>
                {this.props.label &&
                    <div className={'page-h-padding'}>
                        <FadeInOnScroll delay={600} triggerImmediately>
                            <p className={`${this.props.mobile ? 'body2-bold' : 'body1-bold'} base-black-50 uppercase mb-20`}>
                                {this.props.label}
                            </p>
                        </FadeInOnScroll>
                        <div className='row-ac w-80p' style={{gap: this.props.mobile ? 32 : 60, flexWrap: 'wrap'}}>
                            {this.props.highlights &&
                                this.props.highlights.map((item, i) =>
                                    <FadeInOnScroll triggerImmediately delay={620 + (i * 100)}>
                                        <div className='col-ac text-center'>
                                            <div>
                                                {this.props.mobile ? item.iconMobile : item.icon}
                                            </div>
                                            <p className={`${this.props.mobile ? 'body3' : 'body2'} base-black-70 m-0`}>
                                                {item.label1}<br/>{item.label2}
                                            </p>
                                        </div>
                                    </FadeInOnScroll>
                                )
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default VericalHero
