import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64px"
            height="64px"
            {...props}
        >
            <path d="M50.943 4.002a1 1 0 00-.691.334l-8 9A1 1 0 0043 15h4v14h-3a1 1 0 00-1 1v23h-3V38a1 1 0 00-1-1h-3V23h4a1 1 0 00.748-1.664l-8-9a1 1 0 00-1.496 0l-8 9A1 1 0 0024 23h4v14h-3a1 1 0 00-1 1v15h-3v-7a1 1 0 00-1-1h-3V31h4a1 1 0 00.748-1.664l-8-9a1 1 0 00-1.496 0l-8 9A1 1 0 005 31h4v14H6a1 1 0 00-1 1v8a1 1 0 102 0v-7h3a1 1 0 001-1V30a1 1 0 00-1-1H7.227L13 22.506 18.773 29H16a1 1 0 00-1 1v16a1 1 0 001 1h3v7a1 1 0 001 1h5a1 1 0 001-1V39h3a1 1 0 001-1V22a1 1 0 00-1-1h-2.773L32 14.506 37.773 21H35a1 1 0 00-1 1v16a1 1 0 001 1h3v15a1 1 0 001 1h5a1 1 0 001-1V31h3a1 1 0 001-1V14a1 1 0 00-1-1h-2.773L51 6.506 56.773 13H54a1 1 0 00-1 1v16a1 1 0 001 1h3v23a1 1 0 102 0V30a1 1 0 00-1-1h-3V15h4a1 1 0 00.748-1.664l-8-9a1 1 0 00-.805-.334z" />
        </svg>
    )
}

export default SvgComponent
