import React, {Component} from "react";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import ContactUsComponent from "../components/ContactUsComponent";
import {CASE_STUDY} from "../utils/dummy";
import CaseStudyStat from "../components/CaseStudyStat";
import CaseStudyContentBlock from "../components/CaseStudyContentBlock";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';


class CaseStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollPosition = this.sectionRef.getBoundingClientRect().top;
        this.setState({
            scrollPosition: scrollPosition
        });
    }


    render() {
        const {scrollPosition} = this.state
        const translateAmounts = [
            scrollPosition * 0.08 * .4,
            scrollPosition * -0.16 * .4,
            scrollPosition * 0.20 * .4,
        ];
        const delimiterIconStyles = {
            width: this.props.mobile ? '24px' : '50px',
            height: this.props.mobile ? '24px' : '50px',
            borderRadius: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${require('../img/arrows.png')})`,
        }
        const object = CASE_STUDY
        return (
            <>
                <div className='case-hero-section col-jb'
                     style={{
                         overflow: "hidden",
                         background: `linear-gradient(83.9deg, ${object.colors[0]} 8.2%, ${object.colors[1]} 90.7%)`
                     }}
                     ref={(ref) => {
                         this.sectionRef = ref;
                     }}>
                    <div className='page-h-padding'>
                        <FadeInOnScroll triggerImmediately delay={1}>
                            <div className='row-ac mt-100 mb-0'>
                                <p className=' mb-0 base-white-50 body2-bold mb-8 uppercase'>
                                    STARFISH
                                </p>
                            </div>
                            <h1 className='heading2 base-white-100 mt-0 w-80p'>
                                Website and mobile app for feature rich social platform
                            </h1>
                        </FadeInOnScroll>
                    </div>
                    <div className='row-plain relative' style={{padding: '0 5vw', marginTop: '5vh'}}>
                        {!this.props.tablet &&
                            <div style={{
                                flex: this.props.mobile ? 1 : .4,
                                transform: `translateY(${translateAmounts[0]}px)`
                            }}>
                                <FadeInOnScroll triggerImmediately delay={300}>
                                    <img alt={''} src={require('../img/cases/starfish/starfishhero2.png')}
                                         className='w-100p mt-40'/>
                                </FadeInOnScroll>
                            </div>
                        }
                        <div style={{
                            flex: this.props.mobile ? 1 : .4,
                            transform: `translateY(${translateAmounts[1]}px)`
                        }} className={this.props.mobile ? 'ml-16' : 'mh-16'}>
                            <FadeInOnScroll triggerImmediately delay={600}>
                                <img alt={''} src={require('../img/cases/starfish/starfishhero1.png')}
                                     className='w-100p'/>
                            </FadeInOnScroll>
                        </div>
                        {!this.props.mobile &&
                            <div style={{flex: 1, transform: `translateY(${translateAmounts[2]}px)`}} className='mt-20'>
                                <FadeInOnScroll triggerImmediately delay={1100}>
                                    <img alt={''} src={require('../img/cases/starfish/starfishsplash1.png')}
                                         className='w-100p'/>
                                </FadeInOnScroll>
                            </div>
                        }
                        <div className={'absolute'} style={{
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 300,
                            background: 'linear-gradient(to bottom, #00000000, #00000040)',
                            zIndex: 999
                        }}/>
                    </div>
                </div>
                <div className='section row-plain'
                     style={{
                         paddingLeft: this.props.mobile && 20,
                         paddingRight: this.props.mobile && 20,
                         paddingTop: this.props.mobile && '15vh',
                         paddingBottom: this.props.mobile && '5vh'
                     }}>
                    {!this.props.mobile &&
                        <div style={{flex: .7}}>
                            <CaseStudyStat label={'Brand'} value={object.brand}/>
                            <CaseStudyStat label={'Industry'} value={object.industry}/>
                            <CaseStudyStat label={'Location'} value={object.location}/>
                            <CaseStudyStat label={'Environment'} value={object.environment}/>
                            <CaseStudyStat label={'Release'} value={object.release} className={'mr-32'}/>
                            <CaseStudyStat label={'Live'} value={object.live}
                                           link={'https://apps.apple.com/us/app/starfish-social/id1642351040'}/>
                        </div>
                    }
                    <div style={{flex: 1}}>
                        {object.summary.map((block, i) =>
                            <div className={i !== object.summary.length - 1 && 'mb-60'}>
                                <FadeInOnScroll>
                                    <p className='body4-bold uppercase base-black-30 mb-16 mt-0'>
                                        {block.label}
                                    </p>
                                </FadeInOnScroll>
                                <div className='m-0'>
                                    <FadeInOnScroll delay={150}>
                                        <p className='body1 base-black-80 mt-0'>
                                            {block.text}
                                        </p>
                                    </FadeInOnScroll>
                                    {block.list &&
                                        <ul className='ml-16'>
                                            {block.list.map((item, i) =>
                                                <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                    <li className={`body3 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                    </li>
                                                </FadeInOnScroll>
                                            )}
                                        </ul>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100p mv-100' style={{borderTop: "1px solid #00000020"}}/>
                <div className={this.props.mobile ? 'section-base-mobile pb-32' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-60p'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[0]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile pt-0' : 'section-base'}>
                    <div className='row-ac-ja'>
                        <div className='col-ac'>
                            <div className='body3-bold base-black-30'>BEFORE</div>
                            <p className='body1-bold base-black-100 mt-0'>
                                Handoff
                            </p>
                        </div>
                        <div className='col-ac'>
                            <div className='body3-bold base-black-30'>AFTER</div>
                            <p className='body1-bold base-black-100 mt-0'>
                                v1 (4 wks)
                            </p>
                        </div>
                    </div>
                    <div className={'image-slider'}>
                        <ReactBeforeSliderComponent
                            delimiterColor={'black'}
                            delimiterIconStyles={delimiterIconStyles}
                            firstImage={{imageUrl: require('../img/cases/starfish/starfishnew.png')}}
                            secondImage={{imageUrl: require('../img/cases/starfish/starfishold.png')}}
                        />
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-60p'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[1]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}}>
                        <img alt={''} src={require('../img/cases/starfish/starfishstyles.png')} className='w-100p'/>
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[2]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 mr-80'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[3]}/>
                    </div>
                    <div style={{flex: 1.2}}>
                        <img alt={''} src={require('../img/cases/starfish/starfishprofiles.png')} className='w-100p'/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}}>
                        <img alt={''} src={require('../img/cases/starfish/starfishnpos.png')} className='w-100p'/>
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[4]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile pb-0' : 'section-base'}>
                    <div className={!this.props.mobile && 'flex-1 mh-80'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[5]}/>
                    </div>
                </div>
                <div className={`section-base ${this.props.mobile && 'ph-0'} pt-0 col-ac-jc`}>
                    <img alt={''} src={require('../img/cases/starfish/starfishposts.png')}
                         className={this.props.mobile ? 'w-100p' : 'w-70p'}
                         style={{objectFit: "contain"}}/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile pb-20' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-50p'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[6]}/>
                    </div>
                </div>
                <div className={`section-base ${this.props.mobile && 'ph-20'} pt-0 col-ac-jc`}>
                    <img alt={''} src={require('../img/cases/starfish/starfishgrid.png')} className='w-100p'
                         style={{objectFit: "contain"}}/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-70p'}>
                        <CaseStudyContentBlock content={CASE_STUDY.content[7]}/>
                    </div>
                </div>
                <div style={{paddingTop: '15vh', marginTop: '10vh', borderTop: '1px solid #00000033'}}>
                    <ContactUsComponent mobile={this.props.mobile}/>
                </div>
            </>
        )
    }
}

export default CaseStudy
