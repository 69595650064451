import * as React from "react"

const SvgComponent = (props) => (
    <svg
        width={743}
        height={650}
        viewBox={'0 0 742 650'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58 163.038C58 72.995 130.995 0 221.038 0h62.136v444H252v88h88v-31.355l344.19.048C677.216 584.31 607.141 649.999 521.723 650H0V323.923h194.473C117.065 311.238 58 244.036 58 163.038Zm626.494 314.533c-4.359-76.753-61.808-139.3-136.201-151.494H742.76V0H307.174v444H340v33.522l344.494.049Z"
            fill={props.fill || "currentColor"}
        />
    </svg>
)

export default SvgComponent
