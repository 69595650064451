import React, {Component} from "react";
import {FAQS} from "../utils/dummy";
import FAQItem from "./FAQItem";
import Shape18 from "../icons/pack1/Shape18";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import SvgArrowRight from "../icons/SvgArrowRight";

class LabCard extends Component {

    render() {
        return (
            <FadeInOnScroll triggerImmediately delay={1}>
                <div className='pb-100 page-h-padding'>
                    <div
                        className={`bg-base-white-10 ${this.props.mobile ? 'col-plain-rev' : 'row-ac-jb wrap-reverse'} lab-widget`}
                        style={{minHeight: '30vh'}}>
                        <div className={this.props.mobile ? ' p-20' : 'col-jb flex-1 pl-40 pv-32'}>
                            {/*<p className='heading4 base-white-100' style={{marginBottom: -24}}>*/}
                            {/*    Hiring / Career*/}
                            {/*</p>*/}
                            <div className=''>
                                <img src={this.props.logo}
                                     style={{width: this.props.mobile ? 180 : 320, zIndex: 2, position: 'relative'}}/>
                                <div className='row-ac mt-12'>
                                    <p className='title1 mt-0 mb-0 base-white-100 mr-12'>
                                        {this.props.title}
                                    </p>
                                </div>
                                <div className='row-ac mt-20 mr-24'>
                                    {/*<Shape18 fill={'white'} width={160} height={160}/>*/}
                                    <p className={`${this.props.mobile ? 'title3' : 'title2'} mt-0 mb-0 base-white-100`}
                                       style={{fontWeight: 400}}>
                                        {this.props.description}
                                    </p>
                                </div>
                            </div>
                            <div className='row-ac wrap mt-24' style={{gap: this.props.mobile ? 8 : 16}}>
                                <a
                                    href={this.props.button1.link}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    className='secondary-button secondary-button-dark body1-bold'>
                                    {this.props.button1.text} <SvgArrowRight width={24} height={24} className={'ml-8'}/>
                                </a>
                                {!!this.props.button2 &&
                                    <a
                                        href={this.props.button2.link}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        className='secondary-button secondary-button-dark body1-bold'>
                                        {this.props.button2.text} <SvgArrowRight width={24} height={24}
                                                                                 className={'ml-8'}/>
                                    </a>
                                }
                            </div>
                        </div>
                        <div className={this.props.mobile ? 'pt-12' : ' ml-40 p-12'}>
                            <img src={this.props.cover}
                                 style={{width: this.props.mobile ? '100%' : '40vw', zIndex: 2, position: 'relative'}}/>
                        </div>
                    </div>
                </div>
            </FadeInOnScroll>
        )
    }
}

export default LabCard
