import React, {Component} from "react";
import {TOP_CAPABILITIES, verticals} from "../utils/dummy";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import VerticalCard from "./VerticalCard";
import SvgChevronForward from "../icons/SvgChevronForward";
import LogoIcon from "../icons/custom/LogoIcon";

class TopCapabilitiesSection extends Component {
    state = {
        selectedIndex: 0
    }

    selectIndex = (selectedIndex) => {
        this.setState({selectedIndex})
    }

    render() {
        return (
            <div className='page-h-padding' style={{paddingTop: '0vh'}}>
                <FadeInOnScroll delay={10}>
                    <p className={this.props.mobile ? 'heading3 w-100p mb-0' : 'heading2 w-75p mb-0'}>
                        We provide a full-service startup ecosystem focused on product, design, and development of
                        digital platforms and experiences<LogoIcon
                        style={{width: this.props.mobile ? 8 : 12, height: this.props.mobile ? 8 : 12, marginLeft: 4}}/>
                    </p>
                </FadeInOnScroll>
                <div style={{
                    padding: '10vh 0 15vh 0',
                    gap: '20px',
                    alignItems: "stretch",
                }} className={this.props.mobile ? 'col-plain' : 'row-jb'}>
                    {verticals.map((item, index) =>
                        <FadeInOnScroll style={{
                            flex: 1,
                            alignItems: "stretch",
                            flexDirection: "column",
                            width: this.props.mobile && '100%'
                        }}
                                        delay={(index * 300) + 10}>
                            <VerticalCard
                                mobile={this.props.mobile}
                                item={item}/>
                        </FadeInOnScroll>
                    )}
                </div>
                <div className='row-jb relative'>
                    <div>
                        {TOP_CAPABILITIES.map((item, i) =>
                            <div className=''>
                                {item.img &&
                                    <div className='absolute'
                                         style={{
                                             right: item.imgPosition.x * (this.props.mobile ? .4 : 1),
                                             top: !this.props.mobile && item.imgPosition.y,
                                             bottom: this.props.mobile && -80,
                                             transition: 'opacity 300ms ease',
                                             opacity: this.state.selectedIndex === i ? .1 : 0,
                                             zIndex: -1,
                                         }}>
                                        {item.img}
                                    </div>
                                }
                                <FadeInOnScroll delay={100 * (i) + 10}>
                                    <div onMouseEnter={() => this.selectIndex(i)} className='row-ac mb-12'>
                                        <h2 className={this.props.mobile ? 'title1 m-0' : 'heading3 m-0'} style={{
                                            color: this.state.selectedIndex !== i && '#00000050',
                                            transition: 'color 300ms ease'
                                        }}>
                                            {item.title}
                                        </h2>
                                        <div
                                            style={{
                                                marginLeft: this.props.mobile ? 2 : 6,
                                                marginTop: this.props.mobile ? 4 : 6,
                                                color: this.state.selectedIndex !== i && '#00000020',
                                                transform: this.state.selectedIndex === i ? 'rotate(0deg)' : 'rotate(90deg)',
                                                transition: 'transform 300ms ease'
                                            }}>
                                            <SvgChevronForward style={{
                                                width: this.props.mobile ? 16 : this.props.tablet ? 24 : 36,
                                                height: this.props.mobile ? 16 : this.props.tablet ? 24 : 36
                                            }}/>
                                        </div>
                                    </div>
                                </FadeInOnScroll>
                                {/*<CollapseComponent isOpen={this.state.selectedIndex === i}>*/}
                                {/*    <p className='body1 mt-0' style={{width: 400}}>*/}
                                {/*        {item.description}*/}
                                {/*    </p>*/}
                                {/*</CollapseComponent>*/}
                            </div>
                        )}
                    </div>
                    <FadeInOnScroll delay={300}>
                        <div className='relative'
                             style={{width: !this.props.mobile && '35vw', marginLeft: this.props.mobile && 20}}>
                            <p className='title1 m-0'>
                                {this.state.selectedIndex !== null ?
                                    TOP_CAPABILITIES[this.state.selectedIndex].description
                                    :
                                    'From idea to design, wireframe to live deployment, collaboration is (and always has been) part of our DNA.'
                                }
                            </p>
                        </div>
                    </FadeInOnScroll>
                </div>
            </div>
        )
    }
}

export default TopCapabilitiesSection
