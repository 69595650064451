import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
            d="M28.425 79.56h96.551M35.384 54.07q41.317 17.843 82.634 0M35.384 105.05q41.317-17.843 82.634 0"
        />
        <ellipse
            cx={53.027}
            cy={79.56}
            rx={11.837}
            ry={29.688}
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <ellipse
            cx={76.701}
            cy={79.56}
            rx={11.837}
            ry={29.688}
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <ellipse
            cx={100.374}
            cy={79.56}
            rx={11.837}
            ry={29.688}
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
