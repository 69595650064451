import * as React from "react"

function SvgComponent(props) {
        return (
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width={1500}
                height={1500}
                viewBox="0 0 150 150"
                {...props}
            >
                    <circle
                        cx={76.42666}
                        cy={79.28299}
                        r={43.72202}
                        fill="none"
                        stroke={props.fill || "#000"}
                        strokeMiterlimit={10}
                        strokeWidth={2.7586}
                    />
                    <path
                        d="M33.831 89.198c-3.78 5.434-5.683 10.303-5.683 14.22 0 6.18 4.717 10 14.138 10 6.469 0 14.303-1.793 22.58-4.924M79.293 101.942A133.42 133.42 0 00110.56 79.28c9.435-9.42 14.138-17.958 14.138-24.137s-4.703-10-14.138-10a43.573 43.573 0 00-6.234.483"
                        fill="none"
                        stroke={props.fill || "#000"}
                        strokeMiterlimit={10}
                        strokeWidth={2.7586}
                    />
                    <path
                        fill="none"
                        stroke={props.fill || "#000"}
                        strokeMiterlimit={10}
                        strokeWidth={2.7586}
                        d="M89.748 101.253L79.293 101.942 85.375 93.405"
                    />
            </svg>
        )
}

export default SvgComponent
