import React, {Component} from "react";
import SvgArrowRight from "../icons/SvgArrowRight";
import SvgChevronForward from "../icons/SvgChevronForward";

class QuickLink extends Component {
    state = {
        hovering: false,
    }
    onMouseEnter = () => {
        this.setState({hovering: true})
    }

    onMouseLeave = () => {
        this.setState({hovering: false})
    }

    render() {
        return (
            <div
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                className='row-ac'>
                <div onClick={this.props.onClick} className='pointer relative' style={{zIndex: 20}}>
                    <p className={`${this.props.mobile ? 'body2-bold' : (this.props.sm ? 'body1-bold' : 'title1')} ${this.props.darkBody ? 'base-white-100' : 'base-black-100'} m-0`}>
                        {this.props.title}
                    </p>
                    <div className={`${this.props.darkBody ? 'bg-base-white-20' : 'bg-base-black-20'} mt-4`} style={{
                        height: this.props.mobile ? 1.5 : (this.props.sm ? 2 : 3),
                        width: '100%',
                        transition: "width 300ms ease-in-out",
                        opacity: this.props.hideTrack && 0
                    }}/>
                    <div className={this.props.darkBody ? 'bg-base-white-100' : `bg-base-black-100`} style={{
                        marginTop: this.props.mobile ? -1.5 : (this.props.sm ? -2 : -3),
                        height: this.props.mobile ? 1.5 : (this.props.sm ? 2 : 3),
                        width: (this.state.hovering || this.props.active) ? '100%' : '0%',
                        transition: "width 300ms ease-in-out"
                    }}/>
                </div>
                {this.props.toRight ?
                    <SvgArrowRight width={this.props.mobile ? 16 : (this.props.sm ? 24 : 40)}
                                   height={this.props.mobile ? 16 : (this.props.sm ? 24 : 40)}
                                   strokeWidth={2}
                                   fill={this.props.darkBody ? 'white' : 'black'}
                                   stroke={this.props.darkBody ? 'white' : 'black'}
                                   className={this.props.mobile ? 'ml-4' : ' ml-8'}
                                   style={{
                                       marginBottom: this.props.mobile ? 4 : 6,

                                       transform: this.props.toRight ? `scaleX(${(this.state.hovering || this.props.active) ? '1.2' : '1'})` : `rotate(${this.state.hovering ? '90deg' : '0deg'})`,
                                       transition: 'all 300ms ease-in-out'
                                   }}
                    />
                    :
                    <SvgChevronForward width={this.props.mobile ? 16 : (this.props.sm ? 24 : 28)}
                                       height={this.props.mobile ? 16 : (this.props.sm ? 24 : 28)}
                                       strokeWidth={2}
                                       fill={this.props.darkBody ? 'white' : 'black'}
                                       stroke={this.props.darkBody ? 'white' : 'black'}
                                       className={this.props.mobile ? 'ml-4' : ' ml-8'}
                                       style={{
                                           marginBottom: this.props.mobile ? 4 : 6,

                                           transform: this.props.toRight ? `scaleX(${(this.state.hovering || this.props.active) ? '1.2' : '1'})` : `rotate(${this.state.hovering ? '90deg' : '0deg'})`,
                                           transition: 'all 300ms ease-in-out'
                                       }}
                    />
                }
            </div>
        )
    }
}

export default QuickLink
