import {backendAxios} from "./firebase";

export const createRequest = async (name, email, description, url, businessName, timeline, docs) => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        }
        const body = {
            name,
            contact_info: email,
            description,
            url,
            business_name: businessName,
            timeline,
            docs
        }
        const response = await backendAxios.post("/requests", body, config)
        return response.data;
    } catch (err) {
        console.log("Error creating blank program:", err);
    }
}