import React, {Component} from "react";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import ContactUsComponent from "../components/ContactUsComponent";
import {CASE_STUDY, REA_CASE_STUDY} from "../utils/dummy";
import CaseStudyStat from "../components/CaseStudyStat";
import CaseStudyContentBlock from "../components/CaseStudyContentBlock";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import CaseHeaderInner from "../components/cases/CaseHeaderInner";
import TiltEffect from "../components/wrappers/TiltEffect";
import ParallaxImage from "../components/ParallaxImage";
import QuickLink from "../components/QuickLink";
import {withRouter} from "react-router-dom";
import MoreCases from "../components/cases/MoreCases";


class ReaCaseStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollPosition = this.sectionRef.getBoundingClientRect().top;
        this.setState({
            scrollPosition: scrollPosition
        });
    }


    render() {
        const {scrollPosition} = this.state
        const translateAmounts = [
            scrollPosition * 0.08 * .4,
            scrollPosition * -0.16 * .4,
            scrollPosition * 0.20 * .4,
        ];
        const delimiterIconStyles = {
            width: this.props.mobile ? '24px' : '50px',
            height: this.props.mobile ? '24px' : '50px',
            borderRadius: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${require('../img/arrows.png')})`,
        }
        const object = REA_CASE_STUDY
        return (
            <>
                <div className='case-hero-section col-jb'
                     style={{
                         overflow: "hidden",
                         background: `linear-gradient(83.9deg, ${object.colors[0]} 8.2%, ${object.colors[1]} 90.7%)`
                     }}
                     ref={(ref) => {
                         this.sectionRef = ref;
                     }}
                >
                    <CaseHeaderInner
                        mobile={this.props.mobile}
                        case={object}
                    />
                    <div className='row-plain relative' style={{
                        padding: '0 5vw', marginTop: '0vh',
                        transform: `translateY(${translateAmounts[0] * (this.props.mobile ? 3 : 5)}px) scale(${1 - translateAmounts[1] * .005}, ${1 - translateAmounts[1] * .005})`
                    }}>
                        {!this.props.tablet &&
                            <div style={{
                                flex: this.props.mobile ? 1 : .4,
                                transform: `translateY(${translateAmounts[0]}px)`
                            }}>
                                <FadeInOnScroll triggerImmediately delay={300}>
                                    <img alt={''} src={require('../img/cases/rea/reacasehero1.png')}
                                         className='w-100p mt-40'/>
                                </FadeInOnScroll>
                            </div>
                        }
                        <div style={{
                            flex: this.props.mobile ? 1 : .4,
                            transform: `translateY(${translateAmounts[1]}px)`
                        }} className={this.props.mobile ? 'ml-16' : 'mh-16'}>
                            <FadeInOnScroll triggerImmediately delay={600}>
                                <img alt={''} src={require('../img/cases/rea/reacasehero2.png')}
                                     className='w-100p'/>
                            </FadeInOnScroll>
                        </div>
                        {!this.props.mobile &&
                            <div style={{flex: 1, transform: `translateY(${translateAmounts[2]}px) scale(1.15)`}}
                                 className='mt-20'>
                                <FadeInOnScroll triggerImmediately delay={1100}>
                                    <img alt={''} src={require('../img/cases/rea/reacasehero3.png')}
                                         className='w-100p'/>
                                </FadeInOnScroll>
                            </div>
                        }
                    </div>
                    <div className={'absolute'} style={{
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: 300,
                        background: 'linear-gradient(to bottom, #00000000, #00000040)',
                        zIndex: 999
                    }}/>
                </div>
                <div className={this.props.mobile ? 'section col-plain' : 'section row-plain'}
                     style={{
                         paddingLeft: this.props.mobile && 20,
                         paddingRight: this.props.mobile && 20,
                         paddingTop: this.props.mobile && '15vh',
                         paddingBottom: this.props.mobile && '5vh'
                     }}>
                    {!this.props.mobile &&
                        <div style={{flex: .7, gap: this.props.mobile && 40}}
                             className={this.props.mobile && 'row-plain wrap'}

                        >
                            <CaseStudyStat label={'Brand'} value={object.brand}/>
                            <CaseStudyStat label={'Industry'} value={object.industry}/>
                            <CaseStudyStat label={'Location'} value={object.location}/>
                            <CaseStudyStat label={'Environment'} value={object.environment}/>
                            <CaseStudyStat label={'Release'} value={object.release} className={'mr-32'}/>
                        </div>
                    }
                    <div style={{flex: 1}}>
                        {object.summary.map((block, i) =>
                            <div className={i !== object.summary.length - 1 && 'mb-60'}>
                                <FadeInOnScroll>
                                    <p className='body2-bold uppercase base-black-30 mb-16 mt-0'>
                                        {block.label}
                                    </p>
                                </FadeInOnScroll>
                                <div className='m-0'>
                                    <FadeInOnScroll delay={150}>
                                        <p className='body1 base-black-80 mt-0'>
                                            {block.text}
                                        </p>
                                    </FadeInOnScroll>
                                    {block.list &&
                                        <ul className='ml-16'>
                                            {block.list.map((item, i) =>
                                                <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                    <li className={`body3 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                    </li>
                                                </FadeInOnScroll>
                                            )}
                                        </ul>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100p mv-100' style={{borderTop: "1px solid #00000020"}}/>
                <div className={this.props.mobile ? 'section-base-mobile' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-60p'}>
                        <CaseStudyContentBlock content={REA_CASE_STUDY.content[0]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}} className='col-ac'>
                        <video autoPlay loop muted className="splash-video"
                               playsInline
                               style={{
                                   objectFit: 'cover',
                                   width: "70%",
                                   height: '100%',
                                   marginBottom: -6,
                                   // position: 'absolute',
                                   top: 0,
                                   left: 0,
                                   right: 0,
                                   bottom: 0,
                                   borderRadius: 20
                               }}>
                            <source src={require('../videos/Designing-the-app (1).mp4')}
                                    type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={REA_CASE_STUDY.content[1]}/>
                    </div>
                </div>
                <ParallaxImage
                    src={require('../img/cases/rea/Hi-fi-wireframe-block-.jpg')}
                    speed={0.1} // Adjust the speed as needed
                    translateY={100} // Adjust the translation amount as needed
                    style={{
                        objectFit: "cover",
                        width: this.props.mobile ? '100vw' : '100%',
                        maxHeight: '80vh'
                    }}
                />
                <div className={this.props.mobile ? 'section-base-mobile col-plain mt-32' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 mr-80 '}>
                        <CaseStudyContentBlock content={REA_CASE_STUDY.content[2]}/>
                    </div>
                    <div style={{flex: 1.2}}>
                        <img alt={''} src={require('../img/cases/rea/reamockup.webp')} className='w-100p'/>
                    </div>
                </div>
                <div className=' page-h-padding'>
                    <FadeInOnScroll triggerImmediately delay={300}>
                        <TiltEffect
                            scale={{
                                x: .02,
                                y: .01,
                            }}
                            style={{
                                borderRadius: 20,
                                boxShadow: '0px -4px 20px 20px #00000010'
                            }}
                        >
                            <video autoPlay loop muted className="splash-video"
                                   playsInline
                                   style={{
                                       objectFit: 'cover',
                                       width: "100%",
                                       height: '100%',
                                       marginBottom: -6,
                                       // position: 'absolute',
                                       top: 0,
                                       left: 0,
                                       right: 0,
                                       bottom: 0,
                                       borderRadius: 20
                                   }}>
                                <source src={require('../videos/Rea-Branding.mp4')}
                                        type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </TiltEffect>
                    </FadeInOnScroll>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}} className='col-ac'>
                        <video autoPlay loop muted className="splash-video"
                               playsInline
                               style={{
                                   objectFit: 'cover',
                                   width: "100%",
                                   height: '100%',
                                   marginBottom: -6,
                                   // position: 'absolute',
                                   top: 0,
                                   left: 0,
                                   right: 0,
                                   bottom: 0,
                                   borderRadius: 20
                               }}>
                            <source src={require('../videos/ReaLinkFlow.mp4')}
                                    type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        {/*<img alt={''} src={require('../img/cases/starfish/starfishnpos.png')} className='w-100p'/>*/}
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={REA_CASE_STUDY.content[3]}/>
                    </div>
                </div>
                <div style={{flex: 1.2}} className='page-h-padding'>
                    <img alt={''} src={require('../img/cases/rea/reaspread.png')} className='w-100p'/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 mr-80'}>
                        <CaseStudyContentBlock content={REA_CASE_STUDY.content[4]}/>
                    </div>
                    <div style={{flex: 1.2}}>
                        {/*<video autoPlay loop muted className="splash-video"*/}
                        {/*       style={{*/}
                        {/*           objectFit: 'cover',*/}
                        {/*           width: "100%",*/}
                        {/*           height: '100%',*/}
                        {/*           marginBottom: -6,*/}
                        {/*           // position: 'absolute',*/}
                        {/*           top: 0,*/}
                        {/*           left: 0,*/}
                        {/*           right: 0,*/}
                        {/*           bottom: 0,*/}
                        {/*           // borderRadius: 20*/}
                        {/*       }}>*/}
                        {/*    <source src={require('../videos/ReaMobileSite.mp4')}*/}
                        {/*            type="video/mp4"/>*/}
                        {/*    Your browser does not support the video tag.*/}
                        {/*</video>*/}
                    </div>
                    {/*<img alt={''} src={require('../img/cases/starfish/starfishprofiles.png')} className='w-100p'/>*/}
                </div>
                <div>
                    <video autoPlay loop muted className="splash-video"
                           playsInline
                           style={{
                               objectFit: 'cover',
                               width: "100%",
                               height: '100%',
                               marginBottom: -6,
                               // position: 'absolute',
                               top: 0,
                               left: 0,
                               right: 0,
                               bottom: 0,
                               // borderRadius: 20
                           }}>
                        <source src={require('../videos/ReaWebsite.mp4')}
                                type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile pb-20' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-50p'}>
                        <CaseStudyContentBlock content={REA_CASE_STUDY.content[5]}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile pb-20' : 'section-base'}>
                    <div className={this.props.mobile ? 'w-100p' : 'w-50p'}>
                        <CaseStudyContentBlock content={REA_CASE_STUDY.content[6]}/>
                    </div>
                </div>
                <div className='col-ac-jc' style={{padding: '10vh 0'}}>
                    <QuickLink
                        title={'View next case'}
                        sm
                        toRight
                        onClick={() => this.props.history.push('/work/glossi')}
                        mobile={this.props.mobile}
                    />
                </div>
                <MoreCases history={this.props.history} case={object}/>
                <div style={{paddingTop: '15vh', marginTop: '10vh', borderTop: '1px solid #00000033'}}>
                    <ContactUsComponent mobile={this.props.mobile}/>
                </div>
            </>
        )
    }
}

export default withRouter(ReaCaseStudy)
