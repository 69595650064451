import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={48}
        viewBox="0 96 960 960"
        width={48}
        {...props}
    >
        <path
            fill={props.fill || 'currentColor'}
            d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
    </svg>
)

export default SvgComponent
