import React, {Component} from "react";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";


class CaseStudyContentBlock extends Component {
    render() {
        const content = this.props.content
        return (
            <div className={`${this.props.className}`}>
                {!!content.label &&
                    <FadeInOnScroll>
                        <p className='body1-bold uppercase base-black-50 mb-0 mt-0'>
                            {content.label}
                        </p>
                    </FadeInOnScroll>
                }
                {!!content.title &&
                    <div className='relative'>
                        <FadeInOnScroll delay={150}>
                            <h2 className='heading2 mt-0 mb-12 base-black-80'>
                                {content.title}
                            </h2>
                        </FadeInOnScroll>
                    </div>
                }
                <FadeInOnScroll delay={200}>
                    <div className='mb-20 mt-20'>
                        <p className='body1 base-black-70 mt-0'>
                            {content.text}
                        </p>
                    </div>
                </FadeInOnScroll>
                {content.list &&
                    <ul className='ml-16'>
                        {content.list.map((item, i) =>
                            <FadeInOnScroll delay={200 + ((i + 1) * 150)}>
                                <li className={`body3 base-black-70 ${i !== content.list.length - 1 && 'mb-12'}`}>
                                    <span className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                </li>
                            </FadeInOnScroll>
                        )}
                    </ul>
                }
            </div>
        )
    }
}

export default CaseStudyContentBlock
