import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64px"
            height="64px"
            {...props}
        >
            <path d="M33 10c-5.77 0-10.201 2.068-13.203 4.174a23.877 23.877 0 00-3.45 2.943c-.398.413-.709.769-.931 1.031-.222.263-.427.512-.355.432a1 1 0 00.746 1.666c1.644 0 3.361.252 5.107 1.063-2.174-.124-4.64-.067-7.55.224 1.347-4.255.714-9.18.714-9.18a1 1 0 10-1.982.264s.688 6.006-1 9.584a1 1 0 001.02 1.42c5.527-.645 8.642-.642 12.355-.008a1 1 0 00.86-1.707c-2.523-2.413-5.145-3.106-7.57-3.37.012-.012.015-.019.026-.03a21.897 21.897 0 013.158-2.694C23.697 13.883 27.706 12 33 12c11.61 0 21 9.39 21 21s-9.39 21-21 21-21-9.39-21-21c0-1.616.29-3.15.635-4.658l18.71 5.234-7.154 9.836a1 1 0 101.618 1.176l8-11a1 1 0 00-.54-1.55l-21.19-5.929a1 1 0 00-1.233.696A22.989 22.989 0 0010 33c0 12.69 10.31 23 23 23s23-10.31 23-23-10.31-23-23-23z" />
        </svg>
    )
}

export default SvgComponent
