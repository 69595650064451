import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64px"
            height="64px"
            {...props}
        >
            <path d="M10 9c-2.75 0-5 2.25-5 5v36c0 2.75 2.25 5 5 5h44c2.75 0 5-2.25 5-5V23a1 1 0 10-2 0v27c0 1.668-1.332 3-3 3H10c-1.668 0-3-1.332-3-3V14c0-1.668 1.332-3 3-3h.504A4.207 4.207 0 0010 13c0 .972.319 1.958 1.002 2.727C11.685 16.495 12.75 17 14 17c1.25 0 2.315-.505 2.998-1.273C17.681 14.957 18 13.972 18 13c0-.688-.168-1.38-.504-2h3.008A4.207 4.207 0 0020 13c0 .972.319 1.958 1.002 2.727C21.685 16.495 22.75 17 24 17c1.25 0 2.315-.505 2.998-1.273C27.681 14.957 28 13.972 28 13c0-.688-.168-1.38-.504-2H54c1.668 0 3 1.332 3 3v2H35a1 1 0 00-1 1v6a1 1 0 001 1h17v3H35a1 1 0 00-1 1v4a1 1 0 001 1h17v3H35a1 1 0 00-1 1v4a1 1 0 001 1h17v3H12V24h17v17a1 1 0 102 0V23a1 1 0 00-1-1H11a1 1 0 00-1 1v23a1 1 0 001 1h42a1 1 0 001-1v-5a1 1 0 00-1-1H36v-2h17a1 1 0 001-1v-5a1 1 0 00-1-1H36v-2h17a1 1 0 001-1v-5a1 1 0 00-1-1H36v-4h22a1 1 0 001-1v-3c0-2.75-2.25-5-5-5H10zm4 2c.75 0 1.185.245 1.502.602.317.356.498.87.498 1.398 0 .528-.181 1.042-.498 1.398-.317.357-.752.602-1.502.602s-1.185-.245-1.502-.602C12.181 14.042 12 13.528 12 13c0-.528.181-1.042.498-1.398.317-.357.752-.602 1.502-.602zm10 0c.75 0 1.185.245 1.502.602.317.356.498.87.498 1.398 0 .528-.181 1.042-.498 1.398-.317.357-.752.602-1.502.602s-1.185-.245-1.502-.602C22.181 14.042 22 13.528 22 13c0-.528.181-1.042.498-1.398.317-.357.752-.602 1.502-.602z" />
        </svg>
    )
}

export default SvgComponent
