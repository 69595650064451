import React, {Component} from 'react';
import HeroSection from "../components/HeroSection";
import ContactUsComponent from "../components/ContactUsComponent";
import {wait} from "../utils/timestamp";
import WeBuildSection from "../components/WeBuildSection";
import TopCapabilitiesSection from "../components/TopCapabilitiesSection";
import MVPSection from "../components/MVPSection";
import PastClientsSection from "../components/PastClientsSection";
import FAQSection from "../components/FAQSection";
import TheLabWidget from "../components/TheLabWidget";
import TiltingImage from "../components/TiltingImage";
import SubHeroSection from "../components/SubHeroSection";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import SvgTime from "../icons/custom/SvgTime";
import SvgProduct from "../icons/custom/SvgProduct";
import SvgWebsite from "../icons/custom/SvgWebsite";
import {BUTTON_TEXT_ZERO_TO_ONE, zero_to_one_highlights} from "../utils/dummy";
import {WORK, WORK_MVP} from "../utils/gallerydummy2";
import WorkFloatingCard from "../components/WorkFloatingCard";
import QuickLink from "../components/QuickLink";
import VericalHero from "../components/VericalHero";
import {Link} from "react-router-dom";

class ZeroToOnePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            darkBody: false,
            autoScrolling: false,
            scale: .9, // Initial scale value,
            opacity: 0,
        }
        this.sectionRef = React.createRef();
        this.observer = null;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        document.body.style.backgroundColor = 'black';
                        this.props.toggleDarkNavbar(true)
                        this.setState({darkBody: true})
                    } else {
                        document.body.style.backgroundColor = 'white';
                        this.props.toggleDarkNavbar(false)
                        this.setState({darkBody: false})
                    }
                });
            },
            {rootMargin: '-50% 0px -50% 0px'}
        );
        this.observer.observe(this.sectionRef.current);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    render() {
        return (
            <div className='relative' style={{pointerEvents: this.state.autoScrolling && 'none'}}>
                <VericalHero
                    width={'50%'}
                    header={'From ideation to creation'}
                    label={'Zero to One'}
                    name={'Zero to One'}
                    logo={require('../img/logos/zerotoone.png')}
                    highlights={zero_to_one_highlights}
                    bgImg={require('../img/Holographic 3D shape 43.png')}
                    description={'We work with motivated founders to bridge the gap between great ideas and cutting-edge execution.'}
                    mobile={this.props.mobile}/>
                <div id="services-section">
                    <SubHeroSection darkBody={this.state.darkBody}
                                    tablet={this.props.tablet}
                                    mobile={this.props.mobile}/>
                </div>
                <div ref={this.sectionRef} id="experience-section" style={{paddingBottom: '10vh'}}>
                    <MVPSection
                        darkBody={this.state.darkBody}
                        tablet={this.props.tablet}
                        mobile={this.props.mobile}
                    />
                    <div style={{paddingTop: '10vh'}}>
                        <div ref={this.sectionRef} id="experience-section" className={'col-ac page-h-padding'}
                             style={{paddingTop: '10vh', paddingBottom: '10vh'}}>
                            {WORK_MVP.map((item, i) =>
                                <WorkFloatingCard item={item} i={i} mobile={this.props.mobile}
                                                  darkBody={this.state.darkBody}/>
                            )}
                            <QuickLink
                                title={'Explore our work'}
                                sm
                                toRight
                                onClick={() => this.props.history.push('/work')}
                                mobile={this.props.mobile}
                                darkBody={this.state.darkBody}
                            />
                        </div>
                    </div>
                    <FAQSection
                        darkBody={this.state.darkBody}
                        tablet={this.props.tablet}
                        mobile={this.props.mobile}
                    />
                </div>
                <ContactUsComponent
                    darkBody={this.state.darkBody}
                    mobile={this.props.mobile}
                />
                <div style={{position: "fixed", bottom: 20, right: 20}}>
                    <FadeInOnScroll triggerImmediately delay={900}>
                        <a
                            href={'https://hello.scopelabs.com/'} target={'_blank'}
                            className={`${this.state.darkBody ? 'secondary-button' : 'primary-button'} body1-bold`}>
                            <img src={require('../img/logos/zerotoone.png')}
                                 className={`${!this.state.darkBody && 'inverseFilter'} mr-12`}
                                 style={{height: 20}}/>
                            {BUTTON_TEXT_ZERO_TO_ONE}
                        </a>
                    </FadeInOnScroll>
                </div>
            </div>
        );
    }
}

export default ZeroToOnePage;
