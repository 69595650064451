import React, {Component} from 'react';
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import QuickLink from "./QuickLink";
import Shape18 from "../icons/pack1/Shape18";
import TiltEffect from "./wrappers/TiltEffect";

class HeroSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0,
            backdropFilter: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {

        const scrollTop = window.pageYOffset;
        const viewportHeight = window.innerHeight;
        const threshold = (viewportHeight * 1.4);

        if (scrollTop <= threshold) {
            const percentage = scrollTop / threshold;
            // console.log(percentage * 100)
            this.setState({backdropFilter: percentage});
        } else {
            this.setState({backdropFilter: 50});
        }

        const scrollPosition = this.sectionRef.getBoundingClientRect().top;
        this.setState({
            scrollPosition: scrollPosition
        });
    }


    render() {
        const {scrollPosition} = this.state
        const translateAmounts = [
            scrollPosition * -0.26 * .8,
            scrollPosition * -0.24 * .8,
            scrollPosition * 0.20 * .8,
        ];
        const rotateAmounts = [
            scrollPosition * -0.16 * .3,
            scrollPosition * -0.24 * .3,
            scrollPosition * 0.20 * .3,
        ];
        return (
            <section
                ref={(ref) => {
                    this.sectionRef = ref;
                }}
                className={`${this.props.mobile ? 'hero-section-mobile' : 'hero-section'}`}
            >
                <div className='absolute'
                     style={{right: '5vw', top: '30vh'}}
                >
                    <FadeInOnScroll triggerImmediately delay={10} loading={this.props.loading}>
                        <img
                            alt={'hero-img'}
                            src={require('../img/Holographic 3D shape 74 (1).png')}
                            style={{
                                width: '20vw',
                                position:"relative",
                                zIndex: -2,
                                opacity: .08,
                                transform: !this.props.mobile && `translateY(${translateAmounts[1]}px) rotate(${rotateAmounts[1]}deg)`
                            }}/>
                    </FadeInOnScroll>
                </div>
                <div className='absolute'
                     style={{right: '40vw', top: '10vh'}}
                >
                    <FadeInOnScroll triggerImmediately delay={10} loading={this.props.loading}>
                        <img
                            alt={'hero-img'}
                            src={require('../img/Holographic 3D shape 18 (1).png')}
                            style={{
                                width: '25vw',
                                zIndex: -2,
                                opacity: .08,
                                transform: !this.props.mobile && `translateY(${translateAmounts[2]}px) rotate(${rotateAmounts[2]}deg)`
                            }}/>
                    </FadeInOnScroll>
                </div>
                <div className='col-plain relative'>
                    <div className='row-ae-jb pl-0'>
                        <div className={this.props.mobile ? 'relative page-h-padding' : 'relative'}
                             style={{zIndex: 99}}>
                            <FadeInOnScroll triggerImmediately delay={10} loading={this.props.loading}>
                                <h1 className='heading1 relative m-0 base-black-100' style={{
                                    zIndex: 9999,
                                    margin: this.props.mobile && '0 20px',
                                    width: this.props.mobile ? '100%' : '68%'
                                }}>
                                    We take our clients to the next level
                                </h1>
                            </FadeInOnScroll>
                            <FadeInOnScroll triggerImmediately delay={400} loading={this.props.loading}>
                                <div className={this.props.mobile ? 'row-ac mv-12' : 'row-ac mt-20 relative'}
                                     style={{zIndex: 99}}>
                                    <QuickLink
                                        title={'What we do'}
                                        hideTrack
                                        onClick={() => this.props.scrollToSection('what-we-do-section')}
                                        mobile={this.props.mobile}
                                    />
                                    <div style={{width: 32}}/>
                                    <QuickLink
                                        title={'Our work'}
                                        hideTrack
                                        onClick={() => this.props.scrollToSection('experience-section')}
                                        mobile={this.props.mobile}
                                    />
                                </div>
                            </FadeInOnScroll>
                        </div>
                        <div className={this.props.mobile ? 'absolute' : 'relative'}
                             style={{
                                 top: this.props.mobile && -120,
                                 right: this.props.mobile && 0,
                             }}>
                            <FadeInOnScroll triggerImmediately delay={600} loading={this.props.loading}>
                                <div className='relative' style={{
                                    marginBottom: this.props.mobile && -40,
                                    width: 'min-content'
                                }}>
                                    <img alt={'scroll-cirlce'} src={require('../img/scrollcircle.png')}
                                         className={this.props.mobile ? 'badge-outer-mobile' : 'badge-outer'}
                                         style={{
                                             width: this.props.mobile ? '32vw' : 180
                                         }}
                                    />
                                    <Shape18 width={this.props.mobile ? '28vw' : 160}
                                             height={this.props.mobile ? '28vw' : 160} stroke={'black'}
                                             style={{
                                                 transform: 'translateX(-51%) translateY(-54%) rotate(0deg)',
                                                 zIndex: 999,
                                                 top: '50%',
                                                 left: '50%'
                                             }}
                                             className={'absolute'}
                                    />
                                </div>
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <FadeInOnScroll triggerImmediately delay={800} loading={this.props.loading}>
                        <div style={{
                            flex: 1.2,
                            zIndex:10,
                            position:'relative',
                            padding: this.props.mobile && '0 20px',
                            margin:this.props.mobile ? '32px 0' : '20vh 0vw 0 0vw',
                            transform: !this.props.mobile && `translateY(${scrollPosition* (.15)}px) scale(${1 - scrollPosition * .0002})`
                        }} className='col-ac'>
                            <TiltEffect
                                scale={{
                                    x: .05,
                                    y: .01
                                }}
                            >
                                <video autoPlay loop muted className="splash-video"
                                       playsInline
                                       style={{
                                           objectFit: 'cover',
                                           marginBottom: this.props.mobile ? 20 : -120,
                                           width: "100%",
                                           // borderRadius: 12,
                                           boxShadow: '0 -4px 12px #00000005',
                                       }}>
                                    <source src={require('../videos/showreelfinal.mp4')}
                                            type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </TiltEffect>
                        </div>
                        {/*<div className='sticky-section-container'*/}
                        {/*     style={{*/}
                        {/*         height: this.props.mobile ? '60vh' : '260vh',*/}
                        {/*         marginTop: 64,*/}
                        {/*         width: '100vw',*/}
                        {/*         overflow: this.props.mobile && "hidden"*/}
                        {/*     }}>*/}
                        {/*    <div className='sticky-hero-container'>*/}
                        {/*        <div className='row-ac'>*/}
                        {/*            <img*/}
                        {/*                src={this.props.mobile ? require('../img/hero/heromobile/reahero.png') : require('../img/hero/reahero.png')}*/}
                        {/*                className='boost-saturation-2'*/}
                        {/*                style={{*/}
                        {/*                    height: this.props.mobile ? '60vh' : '80vh',*/}
                        {/*                    zIndex: 5,*/}
                        {/*                    transform: this.props.mobile ? `translateX(-${translateAmounts[1] * 0}px)` : `translateX(-${translateAmounts[1] * 2.6}px) rotate(${translateAmounts[1] * .1}deg)`*/}
                        {/*                }}/>*/}
                        {/*            <img*/}
                        {/*                src={this.props.mobile ? require('../img/hero/heromobile/evolvehero.png') : require('../img/hero/evolvehero.png')}*/}
                        {/*                className='boost-saturation'*/}
                        {/*                style={{*/}
                        {/*                    height: this.props.mobile ? '60vh' : '80vh',*/}
                        {/*                    marginLeft: '-24vh',*/}
                        {/*                    zIndex: 4,*/}
                        {/*                    transform: this.props.mobile ? `translateX(-${translateAmounts[1] * 0}px)` : `translateX(-${translateAmounts[1] * 2.2}px) rotate(${translateAmounts[2] * .1}deg)`*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*            <img*/}
                        {/*                src={this.props.mobile ? require('../img/hero/heromobile/epichero.png') : require('../img/hero/epichero.png')}*/}
                        {/*                className='boost-saturation'*/}
                        {/*                style={{*/}
                        {/*                    height: this.props.mobile ? '60vh' : '80vh',*/}
                        {/*                    marginLeft: '-24vh',*/}
                        {/*                    zIndex: 3,*/}
                        {/*                    transform: this.props.mobile ? `translateX(-${translateAmounts[1] * 0}px)` : `translateX(-${translateAmounts[1] * 1.8}px) rotate(${translateAmounts[0] * .05}deg)`*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*            <img*/}
                        {/*                src={this.props.mobile ? require('../img/hero/heromobile/tgthero.png') : require('../img/hero/tgthero.png')}*/}
                        {/*                className='boost-saturation'*/}
                        {/*                style={{*/}
                        {/*                    height: this.props.mobile ? '60vh' : '70vh',*/}
                        {/*                    marginLeft: '-24vh',*/}
                        {/*                    zIndex: 2,*/}
                        {/*                    transform: this.props.mobile ? `translateX(-${translateAmounts[1] * 0}px)` : `translateX(-${translateAmounts[1] * 1.8}px) rotate(${translateAmounts[2] * .05}deg)`*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*            {!this.props.mobile &&*/}
                        {/*                <img*/}
                        {/*                    src={this.props.mobile ? require('../img/hero/heromobile/starfishhero.png') : require('../img/hero/starfishhero.png')}*/}
                        {/*                    className='boost-saturation-2'*/}
                        {/*                    style={{*/}
                        {/*                        height: this.props.mobile ? '60vh' : '75vh',*/}
                        {/*                        marginLeft: '-24vh',*/}
                        {/*                        zIndex: 1,*/}
                        {/*                        transform: `translateX(-${translateAmounts[1] * 1.8}px) rotate(${translateAmounts[2] * -.02}deg)`*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </FadeInOnScroll>
                </div>
                {/*<div className=' absolute'*/}
                {/*     style={{right: 0}}*/}
                {/*>*/}
                {/*    <FadeInOnScroll triggerImmediately delay={10} loading={this.props.loading}>*/}
                {/*        <img*/}
                {/*            alt={'hero-img'}*/}
                {/*            src={require('../img/Sample • Holographic 3D shape.png')}*/}
                {/*            style={{*/}
                {/*                width: '50vw',*/}
                {/*                marginTop: '-20vw',*/}
                {/*                zIndex: -2,*/}
                {/*                opacity: .15,*/}
                {/*                transform: `translateY(${translateAmounts[0]}px) rotate(${rotateAmounts[0]}deg)`*/}
                {/*            }}/>*/}
                {/*    </FadeInOnScroll>*/}
                {/*</div>*/}
            </section>
        );
    }
}

export default HeroSection;
