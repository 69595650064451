import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 96 960 960"
            width={48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M120 816v-60h720v60H120zm0-210v-60h720v60H120zm0-210v-60h720v60H120z"/>
        </svg>
    )
}

export default SvgComponent
