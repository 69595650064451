import axios from "axios";
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {initializeApp} from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyDNgxEbsXY90VXnHfiLQawyJJs-a7uh0wY",
    authDomain: "scope-internal.firebaseapp.com",
    projectId: "scope-internal",
    storageBucket: "scope-internal.appspot.com",
    messagingSenderId: "144773426403",
    appId: "1:144773426403:web:2e2766ee2d47ac7f0d29d6",
    measurementId: "G-CP6QN0KWT8"
};
  

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp);

export const uploadDoc = async (folder, doc_id, doc) => {
  const docRef = ref(firebaseStorage, `${folder}/${doc_id}`);
  const snapshot = await uploadBytes(docRef, doc);

  const docURL = await getDownloadURL(snapshot.ref);
  return docURL
};


export let backendAxios;
if (process.env.NODE_ENV === 'production') {
    console.log("production");
    backendAxios = axios.create({baseURL: "https://scope-internal.uc.r.appspot.com/api"});
} else {
    console.log("development");
    backendAxios = axios.create({baseURL: "http://localhost:5001/api"});
}