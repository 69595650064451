import React, {Component} from "react";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import ContactUsComponent from "../components/ContactUsComponent";
import {STONK_MADNESS_CASE_STUDY} from "../utils/dummy";
import CaseStudyStat from "../components/CaseStudyStat";
import 'react-before-after-slider-component/dist/build.css';
import CaseHeaderInner from "../components/cases/CaseHeaderInner";
import TiltEffect from "../components/wrappers/TiltEffect";
import ParallaxImage from "../components/ParallaxImage";
import QuickLink from "../components/QuickLink";
import mobilescreens from '../img/cases/stonkmadness/mobile-screens.png'
import SM_HOME from '../img/cases/stonkmadness/SM_HOME.mp4'
import fullwidth1 from '../img/cases/stonkmadness/bg-tile.png'
import asset2 from '../img/cases/stonkmadness/phones (1).png'
import asset1 from '../img/cases/stonkmadness/sm-laptop.png'
import CaseStudyContentBlock from "../components/CaseStudyContentBlock";
import RandomCharacter from "../img/cases/stonkmadness/RandomCharacter";
import asset3 from '../img/cases/stonkmadness/emojis.png'
import asset4 from '../img/cases/stonkmadness/sharables.png'
import fullbracket from '../img/cases/stonkmadness/fullbracket.png'
import MoreCases from "../components/cases/MoreCases";

class StonkMadnessCaseStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const scrollPosition = this.sectionRef.getBoundingClientRect().top;
        this.setState({
            scrollPosition: scrollPosition
        });
    }


    render() {
        const {scrollPosition} = this.state
        const translateAmounts = [
            scrollPosition * 0.08 * .4,
            scrollPosition * -0.16 * .4,
            scrollPosition * 0.20 * .4,
        ];
        const delimiterIconStyles = {
            width: this.props.mobile ? '24px' : '50px',
            height: this.props.mobile ? '24px' : '50px',
            borderRadius: 0,
            backgroundSize: 'cover',
            backgroundImage: `url(${require('../img/arrows.png')})`,
        }
        const object = STONK_MADNESS_CASE_STUDY
        return (
            <>
                <div className='case-hero-section col-jb'
                     style={{
                         overflow: "hidden",
                         background: `linear-gradient(-120.9deg, ${object.colors[0]} 8.2%, ${object.colors[1]} 90.7%)`,
                         height:this.props.mobile && 400
                     }}
                     ref={(ref) => {
                         this.sectionRef = ref;
                     }}
                >
                    <CaseHeaderInner
                        mobile={this.props.mobile}
                        case={object}
                    />
                    <div className='row-plain relative' style={{padding: '0 5vw', marginTop: '0vh'}}>
                        <div style={{
                            flex: 1,
                            transform: `translateY(${translateAmounts[0] * 8}px) scale(${1 - translateAmounts[1] * .005}, ${1 - translateAmounts[1] * .005})`
                        }}>
                            <FadeInOnScroll triggerImmediately delay={300}>
                                <TiltEffect
                                    scale={{
                                        x: .02,
                                        y: .01,
                                    }}
                                    style={{
                                        borderRadius: 20,
                                        boxShadow: '0px -4px 20px 20px #00000010'
                                    }}
                                >
                                    <video autoPlay loop muted className="splash-video"
                                           playsInline
                                           style={{
                                               objectFit: 'cover',
                                               width: "100%",
                                               height: '100%',
                                               marginBottom: -6,
                                               // position: 'absolute',
                                               top: 0,
                                               left: 0,
                                               right: 0,
                                               bottom: 0,
                                               borderRadius: 20
                                           }}>
                                        <source src={require('../img/cases/stonkmadness/SM_HEADER.mp4')}
                                                type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </TiltEffect>
                            </FadeInOnScroll>
                        </div>
                        <div className={'absolute'} style={{
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 300,
                            background: 'linear-gradient(to bottom, #00000000, #00000040)',
                            zIndex: 999
                        }}/>
                    </div>
                </div>
                <div className='section row-plain'
                     style={{
                         paddingLeft: this.props.mobile && 20,
                         paddingRight: this.props.mobile && 20,
                         paddingTop: this.props.mobile && '15vh',
                         paddingBottom: this.props.mobile && '5vh'
                     }}>
                    {!this.props.mobile &&
                        <div style={{flex: .7}}>
                            <CaseStudyStat label={'Brand'} value={object.brand}/>
                            <CaseStudyStat label={'Industry'} value={object.industry}/>
                            <CaseStudyStat label={'Location'} value={object.location}/>
                            <CaseStudyStat label={'Environment'} value={object.environment}/>
                            <CaseStudyStat label={'Release'} value={object.release} className={'mr-32'}/>
                            <CaseStudyStat label={'Live'} value={object.live}
                                           link={'https://www.stonkmadness.com/'}/>
                        </div>
                    }
                    <div style={{flex: 1}}>
                        {object.summary.map((block, i) =>
                            <div className={i !== object.summary.length - 1 && 'mb-60'}>
                                <FadeInOnScroll>
                                    <p className='body4-bold uppercase base-black-30 mb-16 mt-0'>
                                        {block.label}
                                    </p>
                                </FadeInOnScroll>
                                <div className='m-0'>
                                    <FadeInOnScroll delay={150}>
                                        <>
                                            <p className='body1 base-black-80 mt-0'>
                                                {block.text}
                                            </p>
                                            {block.text2 &&
                                                <p className='body1 base-black-80 mt-4'>
                                                    {block.text2}
                                                </p>
                                            }
                                        </>
                                    </FadeInOnScroll>
                                    {block.list &&
                                        <ul className='ml-16'>
                                            {block.list.map((item, i) =>
                                                <FadeInOnScroll delay={150 + ((i + 1) * 150)}>
                                                    <li className={`body3 base-black-70 ${i !== block.list.length - 1 && 'mb-12'}`}>
                                                            <span
                                                                className='body3-bold mr-4'>{item.prepend}</span> {item.body}
                                                    </li>
                                                </FadeInOnScroll>
                                            )}
                                        </ul>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100p mv-100' style={{borderTop: "1px solid #00000020"}}/>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <video autoPlay
                           playsInline
                           loop muted className="splash-video"
                           style={{
                               objectFit: 'cover',
                               width: "100%",
                               height: '100%',
                               marginBottom: -6,
                               // position: 'absolute',
                               top: 0,
                               left: 0,
                               right: 0,
                               bottom: 0,
                               borderRadius: 12
                           }}>
                        <source src={SM_HOME}
                                type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <img alt={''} src={mobilescreens} className={this.props.mobile ? 'w-100p' : 'w-100p'}/>
                </div>
                <ParallaxImage
                    src={fullwidth1}
                    speed={0.1} // Adjust the speed as needed
                    translateY={100} // Adjust the translation amount as needed
                    style={{
                        objectFit: "cover",
                        width: this.props.mobile ? '100vw' : '100%',
                        maxHeight: '90vh',
                    }}
                />
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}}>
                        <RandomCharacter/>
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={{
                            title: 'Extra Touches',
                            text: 'To take the project to the next level, we worked closely with artist Cam Miller to incorporate custom company logos for each stock, giving them some personality as they battled each other day by day. We paid attention to the details, which we think really makes or breaks a project like this one.'
                        }}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={{
                            title: 'Mobile & Web Compatible',
                            text: 'We knew people would be playing on their phones. Thats why we built the entire project both web and mobile friendly. We didn\'t just reskin the app either, we completely reworked flows like bracket creation and viewing to make the experience as seamless as possible no matter where you play.'
                        }}/>
                    </div>
                    <div style={{flex: 1.2}} className='col-ac-jc'>
                        <img alt={''} src={asset2} className='w-80p'/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}>
                    <div style={{flex: 1.2}}>
                        <img alt={''} src={asset1} className='w-100p'/>
                    </div>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={{
                            title: 'Timeline',
                            text: 'The whole project took about 10 weeks start to finish. We spent about seven weeks in heavy development mode, then three weeks making sure everything ran smoothly during our maintenance phase, supporting the thousands of brackets, live.'
                        }}/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <img alt={''} src={mobilescreens} className={this.props.mobile ? 'w-100p' : 'w-100p'}/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain' : 'section-base row-ac'}
                     style={{gap: 32}}>
                    <div style={{flex: 1}}>
                        <img alt={''} src={asset3} className='w-100p br-12'/>
                    </div>
                    <div style={{flex: 1}}>
                        <img alt={''} src={asset4} className='w-100p br-12'/>
                    </div>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <img alt={''} src={fullbracket} className={`w-100p br-24`} style={{overflow: "hidden"}}/>
                </div>
                <div className={this.props.mobile ? 'section-base-mobile col-plain-rev' : 'section-base row-ac'}>
                    <div className={!this.props.mobile && 'flex-1 ml-80'}>
                        <CaseStudyContentBlock content={{
                            title: 'Impact & Results',
                            text: 'It was a hit! Thousands of brackets were created, thousands of people watched live as 64 stocks went head to head. It\'s more than just a one and done game; it\'s a new way of looking at stocks, it was an onboarding lead magnet for AfterHour, it was a founder’s dream idea that we had the pleasure of bringing to life. We experienced a high conversion rate between accounts created and brackets built, we noticed a spike in user activity as people logged in daily to watch how their picks did. We saw good email open rates for our daily email updates to users. Overall this project did what it was supposed to do and got the community rallying for their stocks.'
                        }}/>
                    </div>
                </div>
                <div className='col-ac-jc' style={{padding: '10vh 0'}}>
                    <QuickLink
                        title={'View next case'}
                        sm
                        toRight
                        onClick={() => this.props.history.push('/work/rea')}
                        mobile={this.props.mobile}
                    />
                </div>
                <MoreCases history={this.props.history} case={object}/>
                <div style={{paddingTop: '15vh', marginTop: '10vh', borderTop: '1px solid #00000033'}}>
                    <ContactUsComponent mobile={this.props.mobile}/>
                </div>
            </>
        )
    }
}

export default StonkMadnessCaseStudy
