import React, {Component} from 'react';
import '../styles/Navbar.css';
import LogoIcon from "../icons/custom/LogoIcon";
import SvgAutoMode from "../icons/SvgAutoMode";
import SvgMail from "../icons/SvgMail";
import {withRouter} from "react-router-dom";

class Footer extends Component {
    state = {
        hideCredits: false
    }

    componentDidMount() {
        if (this.props.location.pathname === '/contact') {
            this.setState({hideCredits: true})
        } else {
            this.setState({hideCredits: false})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            if (this.props.location.pathname === '/contact') {
                this.setState({hideCredits: true})
            } else {
                this.setState({hideCredits: false})
            }
        }
    }

    render() {
        return (
            <div className={`row-ac-jb bg-base-black-100 page-h-padding relative`} style={{height: 80}}>
                <div
                    className={`base-white-30 row-ac`}>
                    <LogoIcon height={35 / 1.5} width={38 / 1.5}/>
                    <p className={this.props.mobile ? 'body3 ml-8' : 'body3 ml-20'}>
                        ©2023 <span className='base-white-50'>SCOPE LABS</span>
                        {!this.props.mobile &&
                            <span>
                        – ALL
                        RIGHTS RESERVED.
                        </span>
                        }
                    </p>
                    {/*<p className='body3 pointer h-base-white-100 transition1 ml-32'>*/}
                    {/*    PRIVACY POLICY*/}
                    {/*</p>*/}
                </div>
                <div className={`${this.props.mobile ? 'col-ae' : 'row-ac-je'} text-right base-white-30`}>
                    {!this.props.mobile &&
                        <p className={`body3 ${this.props.mobile ? '' : 'mr-8'}`}
                           style={{marginBottom: this.props.mobile && -3}}>
                            CONTACT:
                        </p>
                    }
                    <a
                        style={{}}
                        href="mailto: hello@scopelabs.com"
                        className='row-ac h-base-white-100 base-white-50 h-base-white-100 pointer'>
                        <SvgMail width={20} height={20} style={{marginTop: .25}}/>
                        <p className='body3 uppercase transition1 ml-4'>
                            hello@scopelabs.com
                        </p>
                    </a>
                </div>
                {!this.state.hideCredits &&
                    <div className='col-ac absolute body2 pv-12 ph-16 base-black-30'
                         style={{bottom: 100, right: 0, left: 0}}>
                        <div className='row-ac'>
                            <SvgAutoMode width={20} height={20}/>
                            <span className='body3-bold uppercase ml-4'>Built by Scope Labs</span>
                        </div>
                        <div className='body4  mt-4'>
                            Want a site like ours? Just reach out.
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(Footer);
