import * as React from "react"

const SvgComponent = (props) => (
    <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={1500}
        height={1500}
        viewBox="0 0 150 150"
        {...props}
    >
        <path
            d="M89.11 78.645c18.57-.751 28.72-7.112 33.249-16.409"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
            d="m97.177 81.774-8.068-3.129 7.533-4.256M31.599 95.202c4.518-9.297 14.68-15.67 33.273-16.42"
        />
        <path
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
            d="m56.815 75.652 8.057 3.13-7.522 4.255M76.978 85.501a6.772 6.772 0 1 1 6.772-6.772 6.764 6.764 0 0 1-6.772 6.772Z"
        />
        <path
            d="M83.75 78.729a6.766 6.766 0 1 1-6.772-6.773 6.775 6.775 0 0 1 6.772 6.773ZM76.978 127.004V85.501M76.978 71.956V30.453M111.114 112.865 81.767 83.517M72.189 73.94 42.842 44.593M42.842 112.865l29.347-29.348M81.767 73.94l29.347-29.347"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
        <path
            d="M122.357 62.232a48.298 48.298 0 1 0 2.896 16.497 48.294 48.294 0 0 0-2.896-16.497Z"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2.759}
        />
    </svg>
)

export default SvgComponent
