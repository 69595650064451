import React, {Component} from "react";
import {MVP_STEPS, WE_BUILD, WE_KNOW} from "../utils/dummy";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import AfterWaterfall from "./AfterWaterfall";

class WeBuildSection extends Component {
    state = {
        selectedIndex: null
    }

    selectIndex = (selectedIndex) => {
        this.setState({selectedIndex})
    }

    deselectIndex = () => {
        this.setState({selectedIndex: null})
    }

    render() {
        return (
            <div className=''>
                {/*<div style={{paddingTop: '20vh', paddingBottom: '20vh'}} className='relative'>*/}
                {/*    <img className='absolute rotate-rev-slow' src={require('../img/Holographic 3D shape 43.png')}*/}
                {/*         style={{right: this.props.mobile ? -230 : 0, width: 600, bottom: -100, opacity: .3}}/>*/}
                {/*    <FadeInOnScroll triggerImmediately delay={750} loading={this.props.loading}>*/}
                {/*        <div style={{width: '80vw'}} className='mt-24'>*/}

                {/*            <p className={`${this.props.tablet ? 'heading1 base-black-80' : 'heading1 base-black-80 m-0'}`}>*/}
                {/*                We work with motivated founders to bridge the gap between*/}
                {/*                <span className='highlight'>great ideas and cutting-edge execution</span>.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </FadeInOnScroll>*/}
                {/*</div>*/}
                <div className='row-plain relative page-h-padding' style={{paddingTop: '10vh'}}>
                    <div className='sticky-section-container'
                         style={{marginRight: this.props.mobile ? 0 : '4vw', maxWidth: 600}}>
                        <div
                            className={this.props.mobile ? 'sticky-text-container-1-mobile' : 'sticky-text-container-1'}>
                            <FadeInOnScroll  delay={10} className='pv-32'>
                                <h1 className={`${this.props.mobile ? 'heading4 mr-20' : 'heading1'} mt-0 mb-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                                >
                                    Scope is here to help you go from this...
                                </h1>
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <div className='col-ac flex-1' style={{paddingTop: '0vh'}}>
                        <FadeInOnScroll triggerImmediately delay={300} className='pv-32'>
                            <img src={require('../img/before/stickies.png')}
                                 style={{width: this.props.mobile ? '54vw' : 500}}
                                 className=''/>
                        </FadeInOnScroll>
                        <FadeInOnScroll delay={300} className='pv-32'>
                            <img src={require('../img/before/sketches.png')}

                                 style={{width: this.props.mobile ? '54vw' : 500, transform: 'scale(1.1)'}}
                                 className=''/>
                        </FadeInOnScroll>
                    </div>
                </div>
                <div style={{paddingTop: '20vh'}} className='page-h-padding'>
                    <h1 className={`${this.props.mobile ? 'heading4' : 'heading1'} mt-0 mb-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}
                    >
                        To this.
                    </h1>
                </div>
                <AfterWaterfall mobile={this.props.mobile}/>
                <div className='col-ae page-h-padding' style={{marginTop: '-10vh', paddingBottom: '20vh'}}>
                    <h1 className={`${this.props.mobile ? 'heading4' : 'heading1'} mt-0 mb-0 base-black-80`}>
                        Introducing...
                    </h1>
                </div>
            </div>
        )
    }
}

export default WeBuildSection
