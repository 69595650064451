import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64px"
            height="64px"
            {...props}
        >
            <path d="M21.988 8a1 1 0 00-.529.158l-14 9a1 1 0 00-.014 1.674l6 4a1 1 0 101.11-1.664l-4.729-3.152 12.164-7.82 7.653 5.1-12.23 8.895a1 1 0 00.017 1.63l12.406 8.63-7.918 6.334L16 37.235V27a1 1 0 00-1.6-.8l-8 6a1 1 0 00.086 1.657L14 38.365V48a1 1 0 00.457.84l17 11a1 1 0 001.12-.024l17-12A1 1 0 0050 47v-7a1 1 0 10-2 0v6.482L31.977 57.793 16 47.455v-7.889l5.486 3.291a1 1 0 001.139-.076l8.924-7.138 8.88 6.177a1 1 0 001.153-.006l14-10a1 1 0 00-.027-1.646l-8.323-5.547 8.338-5.8a1 1 0 00-.056-1.678l-15-9a1 1 0 00-1.102.048l-8.045 5.852-8.812-5.875A1 1 0 0021.988 8zm18.065 2.197l13.107 7.866-7.715 5.367-12.304-8.205 6.912-5.028zm-8.637 6.282l12.266 8.177-12.213 8.496-11.744-8.17 11.691-8.503zm14.053 9.369l7.77 5.18-12.247 8.75-7.773-5.407 12.25-8.523zM14 29v7.035l-5.21-3.127L14 29z" />
        </svg>
    )
}

export default SvgComponent
