import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import SvgSubdirectoryArrowRight from "../icons/SvgSubdirectoryArrowRight";

class CapabilitySectionTopics extends Component {
    render() {
        return (
            <div className=''>
                <FadeInOnScroll delay={this.props.i * 100}>
                    <div
                        className={`${this.props.topic.subtopics ? 'mb-4' : 'mb-8'} body3 ${this.props.darkBody ? 'base-white-70' : 'base-black-70'}`}
                        key={this.props.topic.topic}>
                        {this.props.topic.topic}
                    </div>
                </FadeInOnScroll>
                {this.props.topic.subtopics &&
                    <div className={`row-ac ml-16 mb-12 ${this.props.darkBody ? 'base-white-70' : 'base-black-70'}`}
                         style={{flexWrap: 'wrap'}}>
                        <SvgSubdirectoryArrowRight style={{}} width={14} height={14} className={'mr-4'}/>
                        {this.props.topic.subtopics?.map((subtopic, i) =>
                            <FadeInOnScroll key={subtopic.title + this.props.topic} delay={i * 100}>
                                <p className={`body4-bold mt-0 mb-0 mr-8`}>
                                    {subtopic.title}
                                </p>
                            </FadeInOnScroll>
                        )}
                    </div>
                }
            </div>
        )
    }
}

export default CapabilitySectionTopics
