import React, {Component} from "react";
import VericalHero from "../components/VericalHero";
import LabCard from "../components/LabCard";
import SvgHourglass from "../icons/SvgHourglass";
import TiltEffect from "../components/wrappers/TiltEffect";


class TheLab extends Component {
    state = {
        scrollPosition: window.pageYOffset,
        darkBody: false
    }


    render() {
        return (
            <div style={{position: 'relative'}}>
                <VericalHero
                    width={'55%'}
                    header={'Welcome to The Lab'}
                    name={'The Lab'}
                    logo={require('../img/logos/inhouse-light.png')}
                    bgImg={require('../img/Holographic 3D shape 74 (1).png')}
                    description={'Our growing ecosystem of products and services built to help companies like yours thrive.'}
                    mobile={this.props.mobile}
                    darkBody={true}
                />
                <div>
                    <TiltEffect
                        scale={{
                            x: .015,
                            y: .015
                        }}
                    >
                        <LabCard
                            logo={require('../img/lab/10fold/logofinal (7).png')}
                            title={'Engineer Sourcing'}
                            description={'Source your next developer with confidence or get yourself hired at an exciting early stage venture. 10Fold uses hackathons to bring talented builders and early stage companies together.'}
                            button1={{
                                text:'Need engineers?',
                                link:'https://10foldhiring.com/companies'
                            }}
                            button2={{
                                text:'Get hired',
                                link:'https://10foldhiring.com/'
                            }}
                            cover={require('../img/lab/10fold/labcover.png')}
                            mobile={this.props.mobile}
                        />
                    </TiltEffect>
                    <TiltEffect
                        scale={{
                            x: .015,
                            y: .015
                        }}
                    >
                        <LabCard
                            logo={require('../img/lab/revive/revive_logo.png')}
                            title={'Web3 Community Engagement'}
                            description={'Stay connected with your favorite Web3 communities. We\'re bringing back engagement through traditional email communication - all done safely and securely through our encrypted proxy email service.'}
                            button1={{
                                text:'Learn more',
                                link:'https://revivenft.com'
                            }}
                            cover={require('../img/lab/revive/labcover-revive.png')}
                            mobile={this.props.mobile}
                        />
                    </TiltEffect>
                    <TiltEffect
                        scale={{
                            x: .015,
                            y: .015
                        }}
                    >
                        <LabCard
                            logo={require('../img/lab/leadstream/Logo (4).png')}
                            title={'Automated Lead Gen / Recruiting'}
                            description={'Save time and get high-quality leads on autopilot without compromising your domain\'s reputation. LeadStream provides the leads you need with the domain protection you deserve.'}
                            button1={{
                                text:'For marketing',
                                link:'https://www.tryleadstream.com/'
                            }}
                            button2={{
                                text:'For recruiting',
                                link:'https://www.tryleadstream.com/recruiting'
                            }}
                            cover={require('../img/lab/leadstream/labcover-leadstream.png')}
                            mobile={this.props.mobile}
                        />
                    </TiltEffect>
                    <div className='row-ac w-100p mt-40' style={{marginBottom: '30vh'}}>
                        <div className='page-h-padding w-100p row-ac'>
                            <div style={{backgroundColor: '#FFFFFF40', height: 1}}
                                 className='flex-1'/>
                            <div
                                className={`mh-16 row-ac transition1 ${'base-white-100'}`}>
                                <SvgHourglass width={32} height={32} className='mr-8'/>
                                <p className={`body2-bold`}>
                                    More to come...
                                </p>
                            </div>
                            <div style={{backgroundColor: '#FFFFFF40', height: 1}}
                                 className='flex-1'/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TheLab
