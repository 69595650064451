import {PureComponent} from "react";
import {createPortal} from "react-dom";

class Portal extends PureComponent {
    componentWillUnmount() {
        if (this.defaultNode) {
            document.body.removeChild(this.defaultNode)
        }
        this.defaultNode = null;
    }

    render() {
        if (!this.props.node && !this.defaultNode) {
            this.defaultNode = document.createElement('div');
            document.body.appendChild(this.defaultNode);
        }

        return createPortal(this.props.children, this.props.node || this.defaultNode);
    }
}

export default Portal;
