import React, {Component} from 'react';
import FeatureTile from "./FeatureTile";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";

class ExperiencesSection extends Component {
    render() {
        return (
            <div>
                {/*<div className={this.props.mobile ? 'col-ac mt-40 mb-32' : 'col-ac mt-100 mb-32'}>*/}
                {/*    <p className={`${this.props.mobile ? 'title1 ph-20 text-center' : 'title2'} mt-0 ${this.props.darkBody ? 'base-white-100' : 'base-black-100'}`}>*/}
                {/*        If you can interact with it digitally, we can develop it.*/}
                {/*    </p>*/}
                {/*</div>*/}
                <section className={`section pt-40 col-ac pb-40 ${this.props.mobile && 'p-0 ph-12 pb-40'}`}
                         style={{minHeight: 0, alignItems: 'stretch', marginBottom: !this.props.mobile && 120}}>
                    <div className=''>
                        <p className='heading4 mt-32 mb-0 text-center'>
                            What our clients have accomplished
                        </p>
                    </div>
                    <div className='row-jb' style={{alignItems: 'stretch'}}>
                        <FadeInOnScroll style={{flex: .7}}>
                            <FeatureTile
                                stat={20}
                                append={'M+'}
                                label={'Total Dollars Raised'}
                                darkBody={this.props.darkBody}
                                style={{flex: .7}}
                                duration={1}
                                tablet={this.props.tablet}
                                mobile={this.props.mobile}
                            />
                        </FadeInOnScroll>
                        <FadeInOnScroll style={{flex: .7}} delay={100}>
                            <FeatureTile
                                stat={200}
                                append={'M+'}
                                label={'Combined Valuation'}
                                darkBody={this.props.darkBody}
                                style={{flex: .7, margin: '0 20px 0 20px'}}
                                duration={2}
                                tablet={this.props.tablet}
                                mobile={this.props.mobile}
                            />
                        </FadeInOnScroll>
                        <FadeInOnScroll style={{flex: .7}} delay={300}>
                            <FeatureTile
                                stat={200}
                                append={'K+'}
                                label={'Users Served'}
                                darkBody={this.props.darkBody}
                                style={{flex: 1}}
                                duration={4}
                                tablet={this.props.tablet}
                                mobile={this.props.mobile}
                            />
                        </FadeInOnScroll>
                    </div>
                </section>
            </div>
        );
    }
}

export default ExperiencesSection;
