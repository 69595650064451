import React, {Component} from "react";
import {WORK} from "../../utils/gallerydummy2";
import SvgArrowRight from "../../icons/SvgArrowRight";


class MoreCases extends Component {
    render() {
        return (
            <div className='section-base col-plain'>
                <p className='title2'>
                    More Case Studies:
                </p>
                <div className='row-ac-jb wrap' style={{gap: 8}}>
                    {WORK.map(item => {
                        if (item.case.title === 'EVOLVE') return null
                        if ((this.props.case.title !== item.case.title)) {
                            return (
                                <div onClick={() => this.props.history.push(item.case.route)}
                                     className='capabilities-card pointer relative p-0 flex-1 case-footer-card'>
                                    <img src={item.case.src}
                                         style={{objectFit: 'cover', height: 200, marginBottom: -8}}
                                         className='w-100p'/>
                                    <div className='p-4 absolute row-ac-jc cover-parent' style={{zIndex: 3}}>
                                        <p className={`body1-bold mr-4  m-0`}>{item.case.title}</p>
                                        <SvgArrowRight width={20} height={20}/>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
}

export default MoreCases
