import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            {...props}
        >
            <path fill={'currentColor'} d="M321-80l-71-71 329-329-329-329 71-71 400 400L321-80z"/>
        </svg>
    )
}

export default SvgComponent
