import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || 48}
            viewBox="0 96 960 960"
            width={props.width || 48}
            {...props}
        >
            <path
                fill={props.fill || 'currentColor'}
                d="M480 896l-42-43 247-247H160v-60h525L438 299l42-43 320 320-320 320z"/>
        </svg>
    )
}

export default SvgComponent
