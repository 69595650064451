import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import {testimonials} from "../utils/dummy";

class TestimonialsSeciton extends Component {
    state = {
        selectedIndex: null
    }

    selectIndex = (selectedIndex) => {
        this.setState({selectedIndex})
    }

    deselectIndex = () => {
        this.setState({selectedIndex: null})
    }

    render() {
        return (
            <div className='page-h-padding'>
                <div className='row-jb relative' style={{paddingTop: this.props.mobile ? '10vh' : '20vh'}}>
                    <div className='sticky-section-container'
                         style={{marginRight: '10vw', maxWidth: !this.props.mobile && 700}}>
                        <div className='sticky-text-container-1'>
                            <FadeInOnScroll>
                                <h1 className={`${this.props.mobile ? 'title1' : 'heading2'} mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                                    Hear From<br/>
                                    Our Clients
                                </h1>
                            </FadeInOnScroll>
                        </div>
                    </div>
                    <div style={{maxWidth: !this.props.mobile && '35vw'}} className=''>
                        {testimonials.map((item, index) =>
                            <div className={`capabilities-card ${index !== 3 && 'mb-80'}`}>
                                <p className='title1 mt-0'>
                                    <span className='base-black-30'>"</span>{item.quote}<span
                                    className='base-black-30'>"</span>
                                </p>
                                <div className='col-plain'>
                                    <p className='body1-bold m-0'>
                                        {item.person}
                                    </p>
                                    <p className='body2 base-black-50 m-0'>
                                      {item.company} • {item.title}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default TestimonialsSeciton
