import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
        <path
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={1.584}
            d="M54.712 55.333h-3.587l1.522-3.587"
        />
        <path
            d="M51.125 55.333c12.76-8.958 18.924-23.48 13.769-32.438s-19.678-8.958-32.438 0-18.925 23.48-13.77 32.438c4.925 8.557 18.4 8.94 30.718 1.147M58.805 60.614h-3.587l1.523-3.587"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={1.584}
        />
        <path
            d="M55.218 60.614c12.76-8.957 18.925-23.48 13.77-32.438s-19.679-8.957-32.439 0-18.924 23.48-13.769 32.438c4.925 8.557 18.399 8.94 30.718 1.148M62.899 65.896h-3.587l1.522-3.587"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={1.584}
        />
        <path
            d="M59.312 65.896c12.76-8.958 18.924-23.481 13.769-32.439s-19.679-8.957-32.438 0-18.925 23.481-13.77 32.439c4.925 8.557 18.4 8.94 30.718 1.147M66.992 71.177h-3.587l1.523-3.587"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={1.584}
        />
        <path
            d="M63.405 71.177c12.76-8.958 18.925-23.48 13.77-32.438s-19.679-8.958-32.439 0-18.924 23.48-13.769 32.438c4.925 8.557 18.399 8.94 30.717 1.148M71.086 76.458h-3.587l1.522-3.587"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={1.584}
        />
        <path
            d="M67.499 76.458c12.76-8.957 18.924-23.48 13.769-32.438s-19.679-8.957-32.438 0S29.905 67.5 35.06 76.458c4.925 8.558 18.4 8.94 30.718 1.148"
            fill="none"
            stroke={'currentColor' || 'black'}
            strokeMiterlimit={10}
            strokeWidth={1.584}
        />
    </svg>
)

export default SvgComponent
