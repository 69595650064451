import React, {Component} from "react";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import {PROCESS} from "../utils/dummy";
import SvgHandshake from "../icons/SvgHandshake";
import SvgCompass from "../icons/SvgCompass";
import SvgDesign from "../icons/SvgDesign";
import SvgSparkler from "../icons/SvgSparkler";
import SvgImplementation from "../icons/SvgImplementation";


class OurProcess extends Component {
    switchIcon = (title) => {
        switch (title) {
            case 'Kickoff':
                return <SvgHandshake width={'12vw'} height={'12vw'}/>
            case 'Discovery / Strategy':
                return <SvgCompass width={'12vw'} height={'12vw'}/>
            case 'Design / Mockup':
                return <SvgDesign width={'12vw'} height={'12vw'}/>
            case 'Implementation':
                return <SvgImplementation width={'12vw'} height={'12vw'}/>
            case 'Care':
                return <SvgSparkler width={'12vw'} height={'12vw'}/>
            default:
                return <SvgHandshake width={'12vw'} height={'12vw'}/>
        }
    }

    render() {
        return (
            <section ref={(ref) => {
                this.sectionRef = ref;
            }} className='section' style={{paddingTop: '15vh', paddingLeft: 0, paddingRight: 0}}>
                <div className='page-h-padding'>
                    <FadeInOnScroll delay={10}>
                        <p className='body4-bold uppercase base-black-50 mb-0 mt-0'>
                            WORKING WITH US
                        </p>
                    </FadeInOnScroll>
                    <FadeInOnScroll delay={350}>
                        <h2 className='heading3 mt-0 mb-12 base-black-80'>
                            Our Process
                        </h2>
                    </FadeInOnScroll>
                    <FadeInOnScroll delay={500}>
                        <p className='body1 base-black-70 w-80p mb-40'>
                            You will receive a tried-and-tested approach that offers sufficient adaptability to deliver
                            your world-class digital experience within your timeframe and budget. Our committed
                            team of experts will assist you throughout the entire process, every step of the way.
                        </p>
                    </FadeInOnScroll>
                </div>
                <div style={{overflow: "hidden"}} className='relative'>
                    <div className='row-ac mt-40 hide-scrollbar' style={{
                        paddingLeft: '15vw',
                        paddingRight: '15vw',
                        overflowX: "scroll",
                    }}>
                        {PROCESS.map((item, i) =>
                            <FadeInOnScroll delay={350 * i}>
                                <div className='row-ac'>
                                    <div className={'col-ac process-item text-center'}>
                                        {this.switchIcon(item.title)}
                                        <p className='body1-bold mt-32 mb-0'>
                                            {item.title}
                                        </p>
                                    </div>
                                    {i !== PROCESS.length - 1 &&
                                        <div className='bg-base-black-20 mb-32 mh-12' style={{width: 60, height: 2}}/>
                                    }
                                </div>
                            </FadeInOnScroll>
                        )}
                    </div>
                </div>
            </section>
        )
    }
}

export default OurProcess
