import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            {...props}
        >
            <path fill={'currentColor'} d="M700-300l-57-56 84-84H120v-80h607l-83-84 57-56 179 180-180 180z" />
        </svg>
    )
}

export default SvgComponent
