import React, {Component} from 'react'
import FadeInOnScroll from "../wrappers/FadeInOnScroll";

class CaseHeaderInner extends Component {
    render() {
        const caseObj = this.props.case
        return (
            <div className={`page-h-padding relative ${this.props.mobile ? "" : "row-ae-jb "} mb-80`}>
                <div>
                    <FadeInOnScroll triggerImmediately delay={1}>
                        <div className='row-ac mb-0 absolute' style={{top: -32}}>
                            <p className=' m-0 base-white-50 body2-bold uppercase'>
                                {caseObj.brand}
                            </p>
                        </div>
                        <h1 className='heading1 base-white-100 mt-0 mb-0'>
                            {caseObj.tagline}
                        </h1>
                    </FadeInOnScroll>
                </div>
                {/*<div className={this.props.mobile ? 'row-ac base-white-100 mt-12' : 'col-plain base-white-100 ml-52'}>*/}
                {/*    <div className='col-plain'>*/}
                {/*        <div className='row-ac bg-transparent body1-bold'>*/}
                {/*            <img src={require('../../img/logos/zerotoone.png')} className='inverseFilter mr-12'*/}
                {/*                 style={{height: 20}}/>*/}
                {/*            <div>*/}
                {/*                <p className={'body4-bold base-white-50'} style={{marginBottom:this.props.mobile ? -16 : -20}}>*/}
                {/*                    Jun - Sep '23*/}
                {/*                </p>*/}
                {/*                <p className='base-white-100' style={{whiteSpace: "nowrap"}}>*/}
                {/*                    Zero To One*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className='col-plain' style={{marginLeft:this.props.mobile && 32}}>*/}
                {/*        <div className='row-ac bg-transparent body1-bold'>*/}
                {/*            <img src={require('../../img/logos/studio.png')} className='inverseFilter mr-12'*/}
                {/*                 style={{height: 20}}/>*/}
                {/*            <div>*/}
                {/*                <p className={'body4-bold base-white-50'} style={{marginBottom:this.props.mobile ? -16 : -20}}>*/}
                {/*                    Oct '23 - Current*/}
                {/*                </p>*/}
                {/*                <p className='base-white-100' style={{whiteSpace: "nowrap"}}>*/}
                {/*                    Scope Studio*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default CaseHeaderInner
