import * as React from "react"

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
            <path
                d="M84.031 52.897l-.21-.35c-2.56-4.304-3.847-6.464-6.164-11.135a2.299 2.299 0 00-1.884-1.27 65.176 65.176 0 01-4.3-.445 2.294 2.294 0 00-2.402 3.2c1.195 2.724 2.06 4.533 3.084 6.487C54.235 47.64 37.392 42.108 20.8 32.53a2.296 2.296 0 00-3.445 1.99v7.939a2.299 2.299 0 001.381 2.109 163.038 163.038 0 0053.577 13.564 367.561 367.561 0 00-3.445 6.044 2.295 2.295 0 001.919 3.417c1.846.068 2.798.094 4.645.13h.044a2.293 2.293 0 001.995-1.162 688.602 688.602 0 014.925-8.515c.499-.854 1.038-1.775 1.645-2.817a2.293 2.293 0 00-.009-2.332z"
                fill="none"
                stroke={'currentColor' || "#000"}
                strokeMiterlimit={10}
                strokeWidth={1.58364}
            />
        </svg>
    )
}

export default SvgComponent
