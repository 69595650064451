import React, {Component} from 'react';
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import ContactUsLink from "./ContactUsLink";
import Shape1 from "../icons/pack1/Shape1";

class WereHiringSection extends Component {
    render() {
        return (
            <section className='page-h-padding mb-100' style={{paddingTop: !this.props.mobile && '5vh'}}>
                <FadeInOnScroll>
                    <div
                        className={`mt-40 p-0 ${this.props.mobile ? 'col-plain' : 'row-ac-jb'} bg-base-white-8 transition1 p-20 pv-32`}
                        style={{
                            border: `0px solid ${this.props.darkBody ? '#ffffff20' : '#00000020'}`,
                            backdropFilter: 'blur(6px)',
                            boxShadow: '0 8px 20px #00000010',
                        }}>
                        <div className='mr-40 row-ac'>
                            <Shape1 className={this.props.darkBody ? 'base-white-30' : 'base-black-30'} width={120}
                                    height={120}/>
                            <div className='ml-8'>
                                <p className={`title1 ${this.props.darkBody ? 'base-white-100' : 'base-black-80'} m-0 mb-8`}>
                                    We're growing!
                                </p>
                                <p className={`body1 ${this.props.darkBody ? 'base-white-50' : 'base-black-50'} m-0 mr-40 mb-24`}
                                >
                                    We're looking for talented designers, technical developers, and creative minds to
                                    join the team at Scope.
                                </p>
                                <ContactUsLink mobile={this.props.mobile} email={'careers'} darkbody={this.props.darkBody}
                                               tablet={this.props.tablet}/>
                            </div>
                        </div>


                    </div>
                </FadeInOnScroll>
            </section>
        );
    }
}

export default WereHiringSection;
