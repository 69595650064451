import React, {Component} from "react";
import SvgClose from "../icons/SvgClose";
import {Link, withRouter} from "react-router-dom";
import FadeInOnScroll from "./wrappers/FadeInOnScroll";
import LogoMark from "../icons/custom/LogoMark";
import SvgArrowRight from "../icons/SvgArrowRight";


class NavMenu extends Component {
    render() {
        const { location } = this.props;

        const isActive = (path) => {
            if (path === '/') {
                return location.pathname === '/';
            }
            return location.pathname.startsWith(`${path}/`) || location.pathname === path;
        };
        return (
            <div className='col-ac-jc bg-base-black-100'
                 style={{
                     top: 0,
                     left: 0,
                     position: 'fixed',
                     right: 0,
                     height: this.props.navOpen ? (this.props.mobile ? '100dvh' : '100vh') : '0vh',
                     // opacity: this.props.navOpen ? 1 : 0,
                     zIndex: 9999,
                     transition: "600ms ease-in-out",
                     overflow: "hidden",
                 }}>
                <div className='absolute col-ac-jc' style={{top: 0, height: 80, right: 20}}
                     onClick={this.props.toggleNavMenu}>
                    <div className='base-white-30 h-base-white-100 pointer mt-8 close-modal-icon' style={{
                        transform: (this.props.navOpen) ? 'rotate(0deg)' : 'rotate(-45deg)',
                        opacity: (this.props.navOpen) ? 1 : 0,
                        transition: '300ms ease-in-out',
                    }}>
                        <SvgClose fill={'white'} width={this.props.mobile ? 24 : this.props.tablet ? 24 : 36}
                                  height={this.props.mobile ? 24 : this.props.tablet ? 24 : 36}/>
                    </div>
                </div>
                <FadeInOnScroll triggerImmediately delay={300}>
                    <Link to={'/'}>
                        <LogoMark fill={'white'} height={40} className={'mb-40'}/>
                    </Link>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={300}>
                    <Link to={'/'}>
                        <div className={`base-white-100 ${isActive('/') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            Home
                        </div>
                    </Link>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={500}>
                    <Link to={'/zero-to-one'}>
                        <div className={`base-white-100 ${isActive('/zero-to-one') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            Zero To One
                        </div>
                    </Link>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={700}>
                    <Link to={'/studio'}>
                        <div className={`base-white-100 ${isActive('/studio') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            Scope Studio
                        </div>
                    </Link>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={900}>
                    <Link to={'/lab'}>
                        <div className={`base-white-100 ${isActive('/lab') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            The Lab
                        </div>
                    </Link>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={1100}>
                    <Link to={'/work'}>
                        <div className={`base-white-100 ${isActive('/work') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            Work
                        </div>
                    </Link>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={1100}>
                    <Link to={'/about'}>
                        <div className={`base-white-100 ${isActive('/about') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            About
                        </div>
                    </Link>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={1300}>
                    <a
                        href={'https://blog.scopelabs.com/'}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        <div className={`base-white-100 ${isActive('/blog') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            Blog <SvgArrowRight fill={'currentColor'}
                                                width={this.props.mobile ? 20 : this.props.tablet ? 28 : 32}
                                                height={this.props.mobile ? 20 : this.props.tablet ? 28 : 32}
                                                style={{transform: "rotate(-45deg)"}}/>
                        </div>
                    </a>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={1500}>
                    <Link to={'/contact'}>
                        <div className={`base-white-100 ${isActive('/contact') ? 'active-route' :'inactive-route'} heading3 uppercase pv-12`}>
                            Contact Us
                        </div>
                    </Link>
                </FadeInOnScroll>
            </div>
        )
    }
}

export default withRouter(NavMenu);
