import React, {Component} from 'react';
import ContactUsComponent from "../components/ContactUsComponent";
import {WORK} from "../utils/gallerydummy2";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import WorkFloatingCard from "../components/WorkFloatingCard";
import TestimonialsSection from "../components/TestimonialsSection";

class Work extends Component {
    state = {
        darkBody: false
    }

    render() {
        return (
            <>
                <div className='relative page-h-padding' style={{pointerEvents: this.state.autoScrolling && 'none'}}>
                    <div className='row-ae-jb mb-80' style={{paddingTop: '28vh'}}>
                        <FadeInOnScroll delay={10} triggerImmediately>
                            <h1 className={`heading1 mt-0 mb-0  ${this.props.darkBody ? 'base-white-100' : 'base-black-80'}`}>
                                Featured Work
                            </h1>
                        </FadeInOnScroll>
                        {/*<p className='title1 mb-8' style={{width: '35vw'}}>*/}
                        {/*    Some of our past client work,*/}
                        {/*</p>*/}
                    </div>
                    {/*<TopCapabilitiesSection*/}
                    {/*    darkBody={this.state.darkBody}*/}
                    {/*    tablet={this.props.tablet}*/}
                    {/*    mobile={this.props.mobile}*/}
                    {/*/>*/}
                    <div ref={this.sectionRef} id="experience-section" className='col-ac'
                         style={{padding: '0vh 0 10vh 0'}}>
                        {WORK.map((item, i) =>
                            <WorkFloatingCard item={item} i={i} mobile={this.props.mobile}/>
                        )}
                    </div>
                </div>
                {/*<div style={{paddingBottom: '20vh'}}>*/}
                {/*    <TestimonialsSection*/}
                {/*        darkBody={this.state.darkBody}*/}
                {/*        tablet={this.props.tablet}*/}
                {/*        mobile={this.props.mobile}*/}
                {/*    />*/}
                {/*</div>*/}
                <ContactUsComponent
                    darkBody={this.state.darkBody}
                    mobile={this.props.mobile}
                />
            </>
        );
    }
}

export default Work;
